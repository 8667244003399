export const chanelOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az értékesítési csatornát',
  },
  { value: 'DIREKT_ONLINE', label: 'Direkt-online' },
  { value: 'DIREKT_HAGYOMANYOS', label: 'Direkt-hagyományos' },
  { value: 'KOZVETITO_ONLINE', label: 'Közvetítő-online' },
  { value: 'KOZVETITO_HAGYOMANYOS', label: 'Közvetítő-hagyományos' },
];

export const paymentOptions: any = [
  {
    selected: true,
    label: 'Fizetési mód',
    value: null,
  },
  { value: 'ATUTALAS', label: 'Átutalás' },
  { value: 'BANKKARTYA', label: 'Bankkártya' },
  { value: 'KESZPENZ', label: 'Készpénz' },
  { value: 'SZEP_KARTYA', label: 'Szépkártya' },
  { value: 'UTALVANY', label: 'Utalvány' },
  { value: 'EGYEB', label: 'Egyéb' },
];

export const invoiceTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Számla típusa',
  },
  { value: true, label: 'Céges' },
  { value: false, label: 'Magánszemély' },
];

export const taxObjectsOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Adóalany',
  },
  { value: 'INDIVIDUAL', label: 'Magánszemély' },
  { value: 'HU', label: 'Magyarország' },
  { value: 'EU', label: 'EU' },
  { value: 'INT', label: 'Nemzetközi' },
];

export const taxZoneOptions: any = [
  {
    selected: true,
    disabled: true,
    label: 'Adózási zóna',
    value: null,
  },
  { value: 'HU', label: 'Magyarország' },
  { value: 'EU', label: 'EU' },
  { value: 'INT', label: 'Nemzetközi' },
];

export const sourceOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a platformot',
  },
  { value: 'aribnb', label: 'Airbnb' },
  { value: 'booking.com', label: 'booking.com' },
  { value: 'hotels.com', label: 'hotels.com' },
  { value: 'szallas.hu', label: 'szallas.hu' },
  { value: 'other', label: 'Egyéb' },
];

export const helpSubjectOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a tárgyat',
  },
  { value: 'bug', label: 'Hibabejelentés' },
  { value: 'support', label: 'Kérdés' },
  { value: 'feature', label: 'Javaslat' },
];

export const helpPriorityOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a prioritást',
  },
  { value: 'high', label: 'Sürgős' },
  { value: 'medium', label: 'Normál' },
  { value: 'low', label: 'Alacsony' },
];

export const freeHelpPriorityOptions = [
  { label: 'Alacsony', selected: true, disabled: true },
];

export const communicationLangOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a preferált Kommunikációs nyelvet',
  },
  { value: 'EN', label: 'angol' },
  { value: 'FR', label: 'francia' },
  { value: 'CN', label: 'kínai' },
  { value: 'PL', label: 'lengyel' },
  { value: 'HU', label: 'magyar' },
  { value: 'DE', label: 'német' },
  { value: 'RU', label: 'orosz' },
  { value: 'ES', label: 'spanyol' },
];

export const nationalityOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a nemzetiségét',
  },
  { value: 'HU', label: 'Magyarország' },
  // Europe
  { value: 'AL', label: 'Albánia' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AT', label: 'Ausztria' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BA', label: 'Bosznia-Hercegovina' },
  { value: 'BG', label: 'Bulgária' },
  { value: 'CY', label: 'Ciprus' },
  { value: 'CZ', label: 'Csehország' },
  { value: 'DK', label: 'Dánia' },
  { value: 'GB', label: 'Egyesült Királyság' },
  { value: 'EE', label: 'Észtország' },
  { value: 'BY', label: 'Fehéroroszország' },
  { value: 'FI', label: 'Finnország' },
  { value: 'FR', label: 'Franciaország' },
  { value: 'GR', label: 'Görögország' },
  { value: 'GL', label: 'Grönland' },
  { value: 'NL', label: 'Hollandia' },
  { value: 'HR', label: 'Horvátország' },
  { value: 'IE', label: 'Írország' },
  { value: 'IS', label: 'Izland' },
  { value: 'PL', label: 'Lengyelország' },
  { value: 'LV', label: 'Lettország' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Litvánia' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'MK', label: 'Macedónia' },
  { value: 'MT', label: 'Málta' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'DE', label: 'Németország' },
  { value: 'NO', label: 'Norvégia' },
  { value: 'IT', label: 'Olaszország' },
  { value: 'RU', label: 'Oroszország' },
  { value: 'AM', label: 'Örményország' },
  { value: 'PT', label: 'Portugália' },
  { value: 'RO', label: 'Románia' },
  { value: 'ES', label: 'Spanyolország' },
  { value: 'CH', label: 'Svájc' },
  { value: 'SE', label: 'Svédország' },
  { value: 'SK', label: 'Szlovákia' },
  { value: 'SI', label: 'Szlovénia' },
  { value: 'UA', label: 'Ukrajna' },
  { value: 'VA', label: 'Vatikán' },

  // Rest
  { value: 'AF', label: 'Afganisztán' },
  { value: 'AX', label: 'Aland' },
  { value: 'DZ', label: 'Algéria' },
  { value: 'UM', label: 'USA Amerikai Csendes-óceáni szigetek' },
  { value: 'US', label: 'Amerikai Egyesült Államok' },
  { value: 'AS', label: 'Amerikai Szamoa' },
  { value: 'VI', label: 'Amerikai Virgin-szigetek' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarktisz' },
  { value: 'AG', label: 'Antigua és Barbuda' },
  { value: 'AR', label: 'Argentína' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Ausztrália' },
  { value: 'AZ', label: 'Azerbajdzsán' },
  { value: 'BS', label: 'Bahama-szigetek' },
  { value: 'BH', label: 'Bahrein' },
  { value: 'BD', label: 'Banglades' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhután' },
  { value: 'GW', label: 'Bissau-Guinea' },
  { value: 'BO', label: 'Bolívia' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Norvégia Bouvet-sziget' },
  { value: 'BR', label: 'Brazília' },
  { value: 'IO', label: 'Brit Indiai-óceáni Terület' },
  { value: 'VG', label: 'Brit Virgin-szigetek' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CL', label: 'Chile' },
  { value: 'KM', label: 'Comore-szigetek' },
  { value: 'CK', label: 'Cook-szigetek' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'TD', label: 'Csád' },
  { value: 'ZA', label: 'Dél-afrikai Köztársaság' },
  { value: 'GS', label: 'Déli-Georgia és Déli-Sandwich-szigetek' },
  { value: 'SS', label: 'Dél-Szudán' },
  { value: 'KR', label: 'Dél-Korea (Koreai Köztársaság)' },
  { value: 'DM', label: 'Dominikai Közösség' },
  { value: 'DO', label: 'Dominikai Köztársaság' },
  { value: 'DJ', label: 'Dzsibuti' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'GQ', label: 'Egyenlítői-Guinea' },
  { value: 'AE', label: 'Egyesült Arab Emírségek' },
  { value: 'EG', label: 'Egyiptom' },
  { value: 'CI', label: 'Elefántcsontpart' },
  { value: 'SV', label: 'Salvador' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'MP', label: 'Északi-Mariana-szigetek' },
  { value: 'KP', label: 'Észak-Korea (Koreai NDK)' },
  { value: 'ET', label: 'Etiópia' },
  { value: 'FK', label: 'Falkland-szigetek' },
  { value: 'FO', label: 'Feröer' },
  { value: 'FJ', label: 'Fidzsi' },
  { value: 'TF', label: 'Francia déli és antarktiszi területek' },
  { value: 'GF', label: 'Francia Guyana' },
  { value: 'PF', label: 'Francia Polinézia' },
  { value: 'PH', label: 'Fülöp-szigetek' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GH', label: 'Ghána' },
  { value: 'GI', label: 'Gibraltár' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GE', label: 'Grúzia' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey Bailiffség' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard-sziget és McDonald-szigetek' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hongkong' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonézia' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IR', label: 'Irán' },
  { value: 'IL', label: 'Izrael' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japán' },
  { value: 'YE', label: 'Jemen' },
  { value: 'JE', label: 'Jersey Bailiffség' },
  { value: 'JO', label: 'Jordánia' },
  { value: 'KY', label: 'Kajmán-szigetek' },
  { value: 'KH', label: 'Kambodzsa' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CA', label: 'Kanada' },
  { value: 'CX', label: 'Karácsony-sziget' },
  { value: 'BQ', label: 'Karibi Hollandia (Bonaire, Saba, Sint Eustatius)' },
  { value: 'QA', label: 'Katar' },
  { value: 'KZ', label: 'Kazahsztán' },
  { value: 'TL', label: 'Kelet-Timor' },
  { value: 'KE', label: 'Kenya' },
  { value: 'CN', label: 'Kína' },
  { value: 'KG', label: 'Kirgizisztán' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'CC', label: 'Kókusz (Keeling)-szigetek' },
  { value: 'CO', label: 'Kolumbia' },
  { value: 'CD', label: 'Kongói Demokratikus Köztársaság (Zaire)' },
  { value: 'CG', label: 'Kongói Köztársaság (Kongó)' },
  { value: 'XK', label: 'Koszovó' },
  { value: 'CF', label: 'Közép-Afrika' },
  { value: 'CU', label: 'Kuba' },
  { value: 'KW', label: 'Kuvait' },
  { value: 'LA', label: 'Laosz' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LB', label: 'Libanon' },
  { value: 'LR', label: 'Libéria' },
  { value: 'LY', label: 'Líbia' },
  { value: 'MG', label: 'Madagaszkár' },
  { value: 'MO', label: 'Makaó' },
  { value: 'MY', label: 'Malajzia' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MV', label: 'Maldív-szigetek' },
  { value: 'ML', label: 'Mali' },
  { value: 'IM', label: 'Man' },
  { value: 'MA', label: 'Marokkó' },
  { value: 'MH', label: 'Marshall-szigetek' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritánia' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexikó' },
  { value: 'MM', label: 'Mianmar' },
  { value: 'FM', label: 'Mikronézia' },
  { value: 'MN', label: 'Mongólia' },
  { value: 'ME', label: 'Montenegró' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MZ', label: 'Mozambik' },
  { value: 'NA', label: 'Namíbia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepál' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigéria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk-sziget' },
  { value: 'EH', label: 'Nyugat-Szahara' },
  { value: 'OM', label: 'Omán' },
  { value: 'PK', label: 'Pakisztán' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palesztina' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Pápua Új-Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PN', label: 'Pitcairn-szigetek' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'BL', label: 'Saint-Barthélemy' },
  { value: 'KN', label: 'Saint Kitts és Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint-Martin' },
  { value: 'PM', label: 'Saint-Pierre és Miquelon' },
  { value: 'VC', label: 'Saint Vincent és a Grenadine-szigetek' },
  { value: 'SB', label: 'Salamon-szigetek' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tomé és Príncipe' },
  { value: 'SC', label: 'Seychelle-szigetek' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SX', label: 'Sint Maarten' },
  { value: 'SJ', label: 'Norvégia Spitzbergák és Jan Mayen-sziget' },
  { value: 'LK', label: 'Srí Lanka' },
  { value: 'SR', label: 'Suriname' },
  { value: 'WS', label: 'Szamoa' },
  { value: 'SA', label: 'Szaúd-Arábia' },
  { value: 'SN', label: 'Szenegál' },
  { value: 'SH', label: 'Szent Ilona' },
  { value: 'RS', label: 'Szerbia' },
  { value: 'SG', label: 'Szingapúr' },
  { value: 'SY', label: 'Szíria' },
  { value: 'SO', label: 'Szomália' },
  { value: 'SD', label: 'Szudán' },
  { value: 'SZ', label: 'Szváziföld' },
  { value: 'TJ', label: 'Tádzsikisztán' },
  { value: 'TW', label: 'Tajvan' },
  { value: 'TZ', label: 'Tanzánia' },
  { value: 'TH', label: 'Thaiföld' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau-szigetek' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TR', label: 'Törökország' },
  { value: 'TT', label: 'Trinidad és Tobago' },
  { value: 'TN', label: 'Tunézia' },
  { value: 'TC', label: 'Turks- és Caicos-szigetek' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'TM', label: 'Türkmenisztán' },
  { value: 'UG', label: 'Uganda' },
  { value: 'NC', label: 'Új-Kaledónia' },
  { value: 'NZ', label: 'Új-Zéland' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Üzbegisztán' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnám' },
  { value: 'WF', label: 'Wallis és Futuna' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
  { value: 'CV', label: 'Zöld-foki Köztársaság' },
];

export const roomTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a szoba típusát',
  },
  { value: 'APARTMAN', label: 'Apartman' },
  { value: 'ECONOMY', label: 'Economy szoba' },
  { value: 'STANDARD', label: 'Standard szoba' },
  { value: 'SUPERIOR', label: 'Superior szoba' },
  { value: 'JUNIOR_SUITE', label: 'Junior Suite (Kislakosztály)' },
  { value: 'SUITE', label: 'Suite (Lakosztály)' },
  { value: 'MOBILHAZ', label: 'Mobilház' },
  { value: 'UDULOHAZ', label: 'Üdülőház' },
  { value: 'PARCELLA', label: 'Parcella' },
  { value: 'SATORHELY_KEMPINGHELY', label: 'Sátorhely/ Kempinghely' },
  { value: 'EGYEDI', label: 'Egyedi (pl: jurta)' },
  {
    value: 'PRIVAT_SZOBA_KOZOS_FURDOVEL',
    label: 'Privát szoba közös fürdővel (ifjúsági szálláshely)',
  },
  {
    value: 'PRIVAT_SZOBA_SAJAT_FURDOVEL',
    label: 'Privát szoba saját fürdővel (ifjúsági szálláshely)',
  },
  { value: 'HALOTERMI_AGY', label: 'Hálótermi ágy' },
  { value: 'EGYEB', label: 'Egyéb' },
];

export const floorOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az emeletet',
  },
  { value: -1, label: 'Alagsor' },
  { value: 0, label: 'Földszint' },
  { value: 1, label: '1. emelet' },
  { value: 2, label: '2. emelet' },
  { value: 3, label: '3. emelet' },
  { value: 4, label: '4. emelet' },
  { value: 5, label: '5. emelet' },
  { value: 6, label: '6. emelet' },
  { value: 7, label: '7. emelet' },
  { value: 8, label: '8. emelet' },
  { value: 9, label: '9. emelet' },
  { value: 10, label: '10. emelet' },
];

export const genderOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a nemét',
  },

  { value: 'FERFI', label: 'Férfi' },
  { value: 'NO', label: 'Nő' },
  { value: 'EGYEB_VAGY_NEM_ISMERT', label: 'Egyéb' },
];

export const documentTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az okmány típusát',
  },

  { value: 'SZEM_IG', label: 'Személyi' },
  { value: 'UTLEVEL', label: 'Útlevél' },
  { value: 'VEZ_ENG', label: 'Jogosítvány' },
  { value: 'EGYEB', label: 'EGYEB' },
  { value: 'U14', label: '14 év alatti' },
];

export const countryOfResidenceOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a lakóhelyet',
  },
  { value: 'HU', label: 'Magyarország' },
  // Europe
  { value: 'AL', label: 'Albánia' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AT', label: 'Ausztria' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BA', label: 'Bosznia-Hercegovina' },
  { value: 'BG', label: 'Bulgária' },
  { value: 'CY', label: 'Ciprus' },
  { value: 'CZ', label: 'Csehország' },
  { value: 'DK', label: 'Dánia' },
  { value: 'GB', label: 'Egyesült Királyság' },
  { value: 'EE', label: 'Észtország' },
  { value: 'BY', label: 'Fehéroroszország' },
  { value: 'FI', label: 'Finnország' },
  { value: 'FR', label: 'Franciaország' },
  { value: 'GR', label: 'Görögország' },
  { value: 'GL', label: 'Grönland' },
  { value: 'NL', label: 'Hollandia' },
  { value: 'HR', label: 'Horvátország' },
  { value: 'IE', label: 'Írország' },
  { value: 'IS', label: 'Izland' },
  { value: 'PL', label: 'Lengyelország' },
  { value: 'LV', label: 'Lettország' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Litvánia' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'MK', label: 'Macedónia' },
  { value: 'MT', label: 'Málta' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'DE', label: 'Németország' },
  { value: 'NO', label: 'Norvégia' },
  { value: 'IT', label: 'Olaszország' },
  { value: 'RU', label: 'Oroszország' },
  { value: 'AM', label: 'Örményország' },
  { value: 'PT', label: 'Portugália' },
  { value: 'RO', label: 'Románia' },
  { value: 'ES', label: 'Spanyolország' },
  { value: 'CH', label: 'Svájc' },
  { value: 'SE', label: 'Svédország' },
  { value: 'SK', label: 'Szlovákia' },
  { value: 'SI', label: 'Szlovénia' },
  { value: 'UA', label: 'Ukrajna' },
  { value: 'VA', label: 'Vatikán' },

  // Rest
  { value: 'AF', label: 'Afganisztán' },
  { value: 'AX', label: 'Aland' },
  { value: 'DZ', label: 'Algéria' },
  { value: 'UM', label: 'USA Amerikai Csendes-óceáni szigetek' },
  { value: 'US', label: 'Amerikai Egyesült Államok' },
  { value: 'AS', label: 'Amerikai Szamoa' },
  { value: 'VI', label: 'Amerikai Virgin-szigetek' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarktisz' },
  { value: 'AG', label: 'Antigua és Barbuda' },
  { value: 'AR', label: 'Argentína' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Ausztrália' },
  { value: 'AZ', label: 'Azerbajdzsán' },
  { value: 'BS', label: 'Bahama-szigetek' },
  { value: 'BH', label: 'Bahrein' },
  { value: 'BD', label: 'Banglades' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhután' },
  { value: 'GW', label: 'Bissau-Guinea' },
  { value: 'BO', label: 'Bolívia' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Norvégia Bouvet-sziget' },
  { value: 'BR', label: 'Brazília' },
  { value: 'IO', label: 'Brit Indiai-óceáni Terület' },
  { value: 'VG', label: 'Brit Virgin-szigetek' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CL', label: 'Chile' },
  { value: 'KM', label: 'Comore-szigetek' },
  { value: 'CK', label: 'Cook-szigetek' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'TD', label: 'Csád' },
  { value: 'ZA', label: 'Dél-afrikai Köztársaság' },
  { value: 'GS', label: 'Déli-Georgia és Déli-Sandwich-szigetek' },
  { value: 'SS', label: 'Dél-Szudán' },
  { value: 'KR', label: 'Dél-Korea (Koreai Köztársaság)' },
  { value: 'DM', label: 'Dominikai Közösség' },
  { value: 'DO', label: 'Dominikai Köztársaság' },
  { value: 'DJ', label: 'Dzsibuti' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'GQ', label: 'Egyenlítői-Guinea' },
  { value: 'AE', label: 'Egyesült Arab Emírségek' },
  { value: 'EG', label: 'Egyiptom' },
  { value: 'CI', label: 'Elefántcsontpart' },
  { value: 'SV', label: 'Salvador' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'MP', label: 'Északi-Mariana-szigetek' },
  { value: 'KP', label: 'Észak-Korea (Koreai NDK)' },
  { value: 'ET', label: 'Etiópia' },
  { value: 'FK', label: 'Falkland-szigetek' },
  { value: 'FO', label: 'Feröer' },
  { value: 'FJ', label: 'Fidzsi' },
  { value: 'TF', label: 'Francia déli és antarktiszi területek' },
  { value: 'GF', label: 'Francia Guyana' },
  { value: 'PF', label: 'Francia Polinézia' },
  { value: 'PH', label: 'Fülöp-szigetek' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GH', label: 'Ghána' },
  { value: 'GI', label: 'Gibraltár' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GE', label: 'Grúzia' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey Bailiffség' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard-sziget és McDonald-szigetek' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hongkong' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonézia' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IR', label: 'Irán' },
  { value: 'IL', label: 'Izrael' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japán' },
  { value: 'YE', label: 'Jemen' },
  { value: 'JE', label: 'Jersey Bailiffség' },
  { value: 'JO', label: 'Jordánia' },
  { value: 'KY', label: 'Kajmán-szigetek' },
  { value: 'KH', label: 'Kambodzsa' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CA', label: 'Kanada' },
  { value: 'CX', label: 'Karácsony-sziget' },
  { value: 'BQ', label: 'Karibi Hollandia (Bonaire, Saba, Sint Eustatius)' },
  { value: 'QA', label: 'Katar' },
  { value: 'KZ', label: 'Kazahsztán' },
  { value: 'TL', label: 'Kelet-Timor' },
  { value: 'KE', label: 'Kenya' },
  { value: 'CN', label: 'Kína' },
  { value: 'KG', label: 'Kirgizisztán' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'CC', label: 'Kókusz (Keeling)-szigetek' },
  { value: 'CO', label: 'Kolumbia' },
  { value: 'CD', label: 'Kongói Demokratikus Köztársaság (Zaire)' },
  { value: 'CG', label: 'Kongói Köztársaság (Kongó)' },
  { value: 'XK', label: 'Koszovó' },
  { value: 'CF', label: 'Közép-Afrika' },
  { value: 'CU', label: 'Kuba' },
  { value: 'KW', label: 'Kuvait' },
  { value: 'LA', label: 'Laosz' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LB', label: 'Libanon' },
  { value: 'LR', label: 'Libéria' },
  { value: 'LY', label: 'Líbia' },
  { value: 'MG', label: 'Madagaszkár' },
  { value: 'MO', label: 'Makaó' },
  { value: 'MY', label: 'Malajzia' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MV', label: 'Maldív-szigetek' },
  { value: 'ML', label: 'Mali' },
  { value: 'IM', label: 'Man' },
  { value: 'MA', label: 'Marokkó' },
  { value: 'MH', label: 'Marshall-szigetek' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritánia' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexikó' },
  { value: 'MM', label: 'Mianmar' },
  { value: 'FM', label: 'Mikronézia' },
  { value: 'MN', label: 'Mongólia' },
  { value: 'ME', label: 'Montenegró' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MZ', label: 'Mozambik' },
  { value: 'NA', label: 'Namíbia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepál' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigéria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk-sziget' },
  { value: 'EH', label: 'Nyugat-Szahara' },
  { value: 'OM', label: 'Omán' },
  { value: 'PK', label: 'Pakisztán' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palesztina' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Pápua Új-Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PN', label: 'Pitcairn-szigetek' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'BL', label: 'Saint-Barthélemy' },
  { value: 'KN', label: 'Saint Kitts és Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint-Martin' },
  { value: 'PM', label: 'Saint-Pierre és Miquelon' },
  { value: 'VC', label: 'Saint Vincent és a Grenadine-szigetek' },
  { value: 'SB', label: 'Salamon-szigetek' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tomé és Príncipe' },
  { value: 'SC', label: 'Seychelle-szigetek' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SX', label: 'Sint Maarten' },
  { value: 'SJ', label: 'Norvégia Spitzbergák és Jan Mayen-sziget' },
  { value: 'LK', label: 'Srí Lanka' },
  { value: 'SR', label: 'Suriname' },
  { value: 'WS', label: 'Szamoa' },
  { value: 'SA', label: 'Szaúd-Arábia' },
  { value: 'SN', label: 'Szenegál' },
  { value: 'SH', label: 'Szent Ilona' },
  { value: 'RS', label: 'Szerbia' },
  { value: 'SG', label: 'Szingapúr' },
  { value: 'SY', label: 'Szíria' },
  { value: 'SO', label: 'Szomália' },
  { value: 'SD', label: 'Szudán' },
  { value: 'SZ', label: 'Szváziföld' },
  { value: 'TJ', label: 'Tádzsikisztán' },
  { value: 'TW', label: 'Tajvan' },
  { value: 'TZ', label: 'Tanzánia' },
  { value: 'TH', label: 'Thaiföld' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau-szigetek' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TR', label: 'Törökország' },
  { value: 'TT', label: 'Trinidad és Tobago' },
  { value: 'TN', label: 'Tunézia' },
  { value: 'TC', label: 'Turks- és Caicos-szigetek' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'TM', label: 'Türkmenisztán' },
  { value: 'UG', label: 'Uganda' },
  { value: 'NC', label: 'Új-Kaledónia' },
  { value: 'NZ', label: 'Új-Zéland' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Üzbegisztán' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnám' },
  { value: 'WF', label: 'Wallis és Futuna' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
  { value: 'CV', label: 'Zöld-foki Köztársaság' },
];
export const taxExemptionReasonOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a mentesség okát',
  },

  { value: 'im1', label: '18 év alatti' },
  { value: 'im2', label: 'Gyógyintézetben' },
  { value: 'im3', label: 'Közép-és felsőfokú oktatási' },
  { value: 'im4', label: 'Szolgálati kötelezettséget' },
  { value: 'im5', label: 'Hatósági, bírósági intézkedés folytán' },
  { value: 'im6', label: 'A településen székhellyel, telephellyel rendelkező' },
  {
    value: 'im7',
    label: 'Honvédelmi vagy rendvédelmi feladatokat ellátó szervek ',
  },
  { value: 'im8', label: 'A településen üdülőtulajdonnal' },
  { value: 'im9', label: 'Az egyház tulajdonában lévő épületben' },
  { value: 'im10', label: 'A településen lakóhellyel' },
  { value: 'im11', label: 'Önkormányzati rendeleti mentesség (Egyéb)' },
];

export const vatRateOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az ÁFÁ-t',
  },
  { value: 0, label: '0%' },
  { value: 5, label: '5%' },
  { value: 18, label: '18%' },
  { value: 27, label: '27%' },
];

export const serviceCategoryOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a kategóriát',
  },
  { value: 'FOOD', label: 'Étel' },
  { value: 'DRINK', label: 'Ital' },
  { value: 'WELLNESS', label: 'Wellness' },
  { value: 'OTHER', label: 'Egyéb' },
];
