import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  constructor(private dateService: DateService) {}

  public GetOfflineResponse(url: string, method: string): any {
    switch (true) {
      case url.endsWith('/api/auth') && method === 'POST':
        return this.login();

      case url.endsWith('/api/auth/refresh') && method === 'POST':
        return this.tokenRefresh();

      case url.endsWith('/api/hotels') && method === 'GET':
        return this.getHotels();

      case this.regexExpressions.booking.test(url) && method === 'GET':
        return this.getAllBookings();

      case (this.regexExpressions.booking.test(url) && method === 'DELETE') ||
        method === 'PUT' ||
        method === 'POST':
        return this.bookingUpdate();

      case (this.regexExpressions.getRooms.test(url) && method === 'GET') ||
        method === 'PUT' ||
        method === 'POST':
        return this.getRooms();

      case this.regexExpressions.bookingRoom.test(url) && method === 'PUT':
        return this.bookingUpdate();

      default:
        console.log('default', url);
        return { success: false };
    }
  }

  private regexExpressions = {
    booking: new RegExp('^api/hotels/[^/]+/bookings(/[^/]+)?(\\?.*)?$'),
    bookingRoom: new RegExp('/^api/hotels/[^/]+/bookings/[^/]+/rooms/[^/]+$/'),
    getRooms: new RegExp('^api/hotels/[^/]+/rooms(/.*)?(\\?.*)?$'),
  };

  private login(): any {
    const authPost = new HttpResponse({
      status: 200,
      body: { success: true, token: 'reee' },
    });
    return authPost;
  }

  private tokenRefresh(): any {
    const refreshPost = new HttpResponse({
      status: 200,
      body: { success: true, token: 'reee' },
    });
    return refreshPost;
  }

  private getHotels(): any {
    const getHotel = new HttpResponse({
      status: 200,
      body: [
        {
          hotel_id: 1,
          name: 'Károlyi Panzió',
          last_concluded_day: '2023-10-22',
          salt: 'zDaBMMumxc/1rLNjHHg55O',
          room_limit: 10,
        },
      ],
    });
    return getHotel;
  }

  private getAllBookings(): any {
    const bookings = [
      {
        booking_id: 1,
        hotel_id: 1,
        contact_name: 'Valaki',
        contact_email: 'test@test.com',
        contact_phone: '+36301234567',
        source: 'booking.com',
        external_id: '123456',
        channel: 'DIREKT_ONLINE',
        nationality: 'HU',
        work_related: false,
        group_booking: false,
        language: 'HU',
        room_id: 1,
        arrival_date: this.dateService.formatDate(new Date()),
        departure_date: this.dateService.formatDate(
          new Date(new Date().getTime() + 259200000)
        ),
        adults: 2,
        children: 0,
        arrived: true,
        paid: true,
        departed: false,
      },
      {
        booking_id: 1,
        hotel_id: 1,
        contact_name: 'Valaki',
        contact_email: 'test@test.com',
        contact_phone: '+36301234567',
        source: 'booking.com',
        external_id: '123456',
        channel: 'DIREKT_ONLINE',
        nationality: 'HU',
        work_related: false,
        group_booking: false,
        language: 'HU',
        room_id: 2,
        arrival_date: this.dateService.formatDate(
          new Date(new Date().getTime() + 259200000)
        ),
        departure_date: this.dateService.formatDate(
          new Date(new Date().getTime() + 518400000)
        ),
        adults: 2,
        children: 0,
        arrived: true,
        paid: true,
        departed: true,
      },
      {
        booking_id: 2,
        hotel_id: 1,
        contact_name: 'AsdAsd',
        contact_email: 'anon',
        contact_phone: '000000000',
        source: null,
        external_id: null,
        channel: 'DIREKT_HAGYOMANYOS',
        nationality: 'HU',
        work_related: true,
        group_booking: true,
        language: 'HU',
        room_id: 1,
        arrival_date: this.dateService.formatDate(
          new Date(new Date().getTime() + 518400000)
        ),
        departure_date: this.dateService.formatDate(
          new Date(new Date().getTime() + 518400000 + 259200000)
        ),
        adults: 0,
        children: 2,
        arrived: true,
        paid: true,
        departed: true,
      },
    ];
    const bookingGet = new HttpResponse({
      status: 200,
      body: bookings,
    });
    console.log('bookings found');

    return bookingGet;
  }

  private bookingUpdate(): any {
    const bookingDel = new HttpResponse({
      status: 200,
      body: { status: 'success', booking_id: 1 },
    });
    return bookingDel;
  }

  private getRooms(): any {
    const rooms = [
      {
        room_id: 1,
        hotel_id: 1,
        name: 'First room new',
        type: 'ECONOMY',
        building: 'Főépület',
        single_beds: 2,
        double_beds: 0,
        extra_beds: 0,
        max_adults: 2,
        max_family_adults: 2,
        max_family_children: 1,
        air_conditioning: false,
        wheelchair_accessible: false,
        default_price_huf: 0,
        ifa_included: true,
        floor: 0,
        couch_beds: 0,
      },
      {
        room_id: 2,
        hotel_id: 1,
        name: 'Second room new',
        type: 'ECONOMY',
        building: 'Főépület',
        single_beds: 1,
        double_beds: 2,
        extra_beds: 3,
        max_adults: 5,
        max_family_adults: 1,
        max_family_children: 0,
        air_conditioning: true,
        wheelchair_accessible: true,
        default_price_huf: 0,
        ifa_included: false,
        floor: 0,
        couch_beds: 4,
      },
      {
        room_id: 3,
        hotel_id: 1,
        name: 'Third room new',
        type: 'ECONOMY',
        building: 'Főépület',
        single_beds: 1,
        double_beds: 2,
        extra_beds: 3,
        max_adults: 5,
        max_family_adults: 4,
        max_family_children: 2,
        air_conditioning: true,
        wheelchair_accessible: true,
        default_price_huf: 0,
        ifa_included: false,
        floor: 0,
        couch_beds: 2,
      },
    ];
    const roomsGet = new HttpResponse({
      status: 200,
      body: rooms,
    });
    return roomsGet;
  }
}
