import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceInterface } from '../@Interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../@dialogs/confirmation/confirmation.dialog';
import { environment } from '../@environments/environtment';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  constructor(private http: HttpClient, private matDialog: MatDialog) {}
  public services: ServiceInterface[];
  public selectedService: ServiceInterface;

  private selectedHotelId: string;

  public getServices(hotelId: string): void {
    const hotel_id = hotelId ?? this.selectedHotelId;
    this.selectedHotelId = hotel_id;
    this.http
      .get<ServiceInterface[]>(
        `${environment.url}api/hotels/${hotel_id}/services`
      )
      .subscribe({
        next: (services: ServiceInterface[]) => {
          this.services = services;
        },
        error: () => {},
      });
  }

  public editService(service: any, serviceId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<ServiceInterface[]>(
          `${environment.url}api/hotels/${this.selectedHotelId}/services/${service.service_id}`,
          service
        )
        .subscribe({
          next: (services: ServiceInterface[]) => {
            this.services = services;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public newService(service: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<ServiceInterface[]>(
          `${environment.url}api/hotels/${this.selectedHotelId}/services`,
          service
        )
        .subscribe({
          next: (services) => {
            this.services = services;
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public deleteService(service: any): void {
    this.matDialog
      .open(ConfirmationDialog)
      .afterClosed()
      .subscribe((choice) => {
        if (!choice) return;

        this.http
          .delete<ServiceInterface[]>(
            `${environment.url}api/hotels/${this.selectedHotelId}/services/` +
              service.service_id
          )
          .subscribe({
            next: (services) => {
              this.services = services;
            },
            error: () => {},
          });
      });
  }

  public selectService(serviceId: string): void {
    this.selectedService = this.services.find(
      (service: ServiceInterface) => service.service_id === Number(serviceId)
    );
  }
}
