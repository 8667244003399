import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'error',
  templateUrl: './error.dialog.html',
  styleUrls: ['./error.dialog.scss'],
})
export class ErrorDialog {
  constructor(/* private matDialogRef: MatDialogRef<ConfirmationDialog> */) {}

  /*  public continue(): void {
    this.matDialogRef.close(true);
  }

  public cancel(): void {
    this.matDialogRef.close(false);
  } */
}
