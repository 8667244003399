import { Injectable } from '@angular/core';
import { HotelInterface } from '../@Interfaces/hotel.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../@environments/environtment';
import { DateService } from './date.service';
import { RouterService } from './router.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class HotelService {
  constructor(
    private http: HttpClient,
    private dateService: DateService,
    private routerService: RouterService,
    private configService: ConfigService
  ) {}

  public selectedHotel: HotelInterface;
  public userName: string = '';
  public selectedHotelId: number = 1;
  public selectedHotelRoomLimit: number = 5;
  public hotels: HotelInterface[];
  public isAutoConclude: boolean = false;
  public isAutoArrive: boolean = false;
  public isAutoDepart: boolean = false;

  public selectHotel(hotel: HotelInterface): void {
    this.selectedHotel = hotel;
    this.selectedHotelId = hotel.hotel_id;
    this.selectedHotelRoomLimit = hotel.room_limit;
    this.isAutoConclude = hotel.auto_conclude;
    this.isAutoArrive = hotel.auto_arrive;
    this.isAutoDepart = hotel.auto_depart;
    this.routerService.addQueryParams({ hotel: hotel.hotel_id });
  }

  public getHotels(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .get<HotelInterface[]>(`${this.configService.server}api/hotels`)
        .subscribe({
          next: (hotels) => {
            if (!hotels) {
              reject();
            }

            this.hotels = hotels;
            this.userName = hotels[0].user_name;
            if (!this.selectedHotel) {
              this.hotels.forEach((hotel: HotelInterface) => {
                hotel.last_concluded_day = this.dateService.addDayToStringDate(
                  hotel.last_concluded_day,
                  1
                );
              });
            }
            resolve(true);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public concludeDay(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<{ success: boolean; concluded_day: string }>(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/conclude-day`,
          {
            date: this.selectedHotel.last_concluded_day,
          }
        )
        .subscribe({
          next: (response: { success: boolean; concluded_day: string }) => {
            this.selectedHotel.last_concluded_day =
              this.dateService.addDayToStringDate(response.concluded_day, 1);
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async autoConclude(status: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (status) {
        this.http
          .put<{ success: boolean; concluded_day: string }>(
            `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/auto-conclude`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      } else {
        this.http
          .delete<{ success: boolean; concluded_day: string }>(
            `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/auto-conclude`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      }
    });
  }
  public async autoArrive(status: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (status) {
        this.http
          .put<{ success: boolean; concluded_day: string }>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-arrive`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      } else {
        this.http
          .delete<{ success: boolean; concluded_day: string }>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-arrive`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      }
    });
  }
  public async autoDepart(status: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (status) {
        this.http
          .put<{ success: boolean; concluded_day: string }>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-depart`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      } else {
        this.http
          .delete<{ success: boolean; concluded_day: string }>(
            `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/auto-depart`,
            {}
          )
          .subscribe({
            next: () => {
              resolve();
            },
            error: () => {
              reject();
            },
          });
      }
    });
  }

  public async addCommunicationLanguage(lang: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/languages/${lang}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.languages.push(lang);
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async removeCommunicationLanguage(lang: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/languages/${lang}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.languages = this.selectedHotel.languages.filter(
              (item) => item !== lang
            );
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async addCountry(country: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/countries/${country}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.countries.push(country);
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async removeCountry(country: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete(
          `${this.configService.server}api/hotels/${this.selectedHotel.hotel_id}/countries/${country}`,
          {}
        )
        .subscribe({
          next: () => {
            this.selectedHotel.countries = this.selectedHotel.countries.filter(
              (item) => item !== country
            );
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
}
