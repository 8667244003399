import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { serviceCategoryOptions, vatRateOptions } from 'src/app/@consts';
import { ServiceInterface } from 'src/app/@Interfaces';
import { ServiceService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';

@Component({
  selector: 'services-add',
  templateUrl: './services-add.component.html',
  styleUrls: ['./services-add.component.scss'],
})
export class ServicesAddComponent {
  constructor(
    public hotelService: HotelService,
    public serviceService: ServiceService,
    public focusService: FocusService
  ) {}

  public form: FormGroup;
  public readonly vatRateOptions = vatRateOptions;
  public readonly serviceCategoryOptions = serviceCategoryOptions;
  public dynamicThe: string = 'a(z)';
  public serviceGroup: any;
  public service: ServiceInterface = {
    name: '',
    category: null,
    vat_rate: 0,
    price_huf: 0,
  };

  public newService(): void {
    delete this.service.service_id;
    if (!this.service.price_huf) {
      delete this.service.price_huf;
    }
    this.serviceService
      .newService(this.service)
      .then(() => {
        this.service = {
          name: '',
          category: null,
          vat_rate: 0,
          price_huf: 0,
        };
      })
      .catch(() => {});
  }
}
