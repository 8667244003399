import { ChangeDetectorRef, Component } from '@angular/core';
import { AuthService, RouterService } from 'src/app/@services';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  constructor(
    private authService: AuthService,
    private routerService: RouterService,
    private cdr: ChangeDetectorRef
  ) {}

  public loginGroup: any = {
    username: null,
    password: null,
  };
  protected passwordCheck: string = null;
  public isLoading: boolean;
  protected pwRequirements = {
    atLeastOneCapital: false,
    atLeastOneNumber: false,
    atLeastEighthDigits: false,
    doubleChecked: false,
  };

  public register(): void {
    this.authService
      .register(this.loginGroup)
      .then(() => {
        console.log('successful register');
        this.routerService.absoluteNavigation('hu/login');
      })
      .catch(() => {
        console.log('register error');
      });
  }

  public checkPassword(): boolean {
    const hasFalseValue = Object.values(this.pwRequirements).some(
      (value) => value === false
    );

    return !hasFalseValue;
  }

  protected checkRequirements(): void {
    this.pwRequirements.atLeastEighthDigits =
      this.loginGroup?.password?.length > 7;
    this.pwRequirements.atLeastOneCapital = /[A-Z]/.test(
      this.loginGroup?.password
    );
    this.pwRequirements.atLeastOneNumber = /\d/.test(this.loginGroup?.password);
    this.pwRequirements.doubleChecked =
      this.passwordCheck === this.loginGroup?.password &&
      this.passwordCheck &&
      this.loginGroup?.password;
    this.cdr.detectChanges();
  }
}
