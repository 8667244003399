export * from './@cards/arrival-card/arrival-card.component';
export * from './@pages/admin/admin.component';
export * from './@pages/calendar/calendar.component';
export * from './@pages/calendar/table/table.component';
export * from './@pages/home/home.component';
export * from './@pages/login/login.component';
export * from './@pages/page-not-found/page-not-found.component';
export * from './@pages/profile/profile.component';
export * from './@pages/register/register.component';
export * from './@pages/self-check-in/self-check-in.component';
export * from './@pages/support/support.component';
export * from './admin-hotel/admin-hotel.component';
export * from './booking-panels/booking-arrival/booking-arrival.component';
export * from './booking-panels/booking-edit-details/booking-edit-details.component';
export * from './booking-panels/booking-edit-rooms/booking-edit-rooms.component';
export * from './booking-panels/booking-new-details/booking-new-details.component';
export * from './booking-panels/booking-new-rooms/booking-new-rooms.component';
export * from './booking-panels/booking-rooms-depart/booking-rooms-depart.component';
export * from './booking-panels/booking-rooms-payment/booking-rooms-payment.component';
export * from './loading-spinner/loading-spinner.component';
export * from './nav-bar/nav-bar.component';
export * from './qr-scanner/qr-scanner.component';
export * from './room-panel/room-panel.component';
export * from './rooms-submenus/rooms-add/rooms-add.component';
export * from './rooms-submenus/rooms-edit/rooms-edit.component';
export * from './rooms-submenus/rooms-ooo/rooms-ooo.component';
export * from './rooms-submenus/rooms-oos/rooms-oos.component';
export * from './rooms-submenus/rooms-overview/rooms-overview.component';
export * from './rooms/rooms.component';
export * from './scan/scanner.component';
export * from './services-submenus/services-add/services-add.component';
export * from './services-submenus/services-edit/services-edit.component';
export * from './services-submenus/services-overview/services-overview.component';
export * from './services/services.component';
