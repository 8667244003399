import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingFormInterface, BookingInterface } from '../@Interfaces';
import { ExtendedBookingRoom } from '../@Interfaces/room.interface';
import { environment } from '../@environments/environtment';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private http: HttpClient) {}

  public bookings: BookingInterface[] = [];
  public removedBookings: ExtendedBookingRoom[] = [];
  public lastLoadedBookings: BookingInterface[] = [];
  public selectedBooking: BookingInterface;
  public isBookingOpen: boolean;
  public sidePanelRooms: {
    [key: string]: ExtendedBookingRoom;
  } = {};
  public queryDates = {
    start_date: '',
    end_date: '',
  };
  public isStepOne: boolean;
  public isValidBooking: boolean;
  public bookingData: BookingFormInterface = {
    channel: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    group_booking: false,
    language: '',
    nationality: '',
    source: '',
    work_related: false,
  };

  private selectedHotelId: string;
  private selectedRoomId: string;

  /* <-- API CALLS --> */

  public getBookings(hotelId: string = null): Promise<void> {
    const hotel_id = hotelId ?? this.selectedHotelId;
    this.selectedHotelId = hotel_id;
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<BookingInterface[]>(
          `${
            environment.url
          }api/hotels/${hotel_id}/bookings?${this.getQueryParams()}`
        )
        .subscribe({
          next: (bookings: BookingInterface[]) => {
            this.bookings = bookings ?? [];

            if (this.selectedBooking) {
              this.selectBooking(
                this.selectedBooking.booking_id.toString(),
                this.selectedBooking.room_id.toString()
              );
              this.setSelectedBookingsRooms();
            }
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public getBooking(
    hotelId: string,
    bookingId: string
  ): Promise<BookingInterface[]> {
    const hotel_id = hotelId ?? this.selectedHotelId;
    return new Promise<BookingInterface[]>((resolve, reject) => {
      this.http
        .get<BookingInterface[]>(
          `${environment.url}api/hotels/${hotel_id}/bookings?booking_id=${bookingId}`
        )
        .subscribe({
          next: (bookings: BookingInterface[]) => {
            resolve(bookings);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  public postNewBooking(selectedRooms: {
    [key: string]: ExtendedBookingRoom;
  }): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const rooms = Object.entries(selectedRooms).map(([key, value]) => ({
        room_id: Number(key),
        arrival_date: value.arrival_date,
        departure_date: value.departure_date,
        adults: value.adults,
        children: value.children,
        gross_price: value.gross_price,
        ifa_included: !value.ifa_included ?? false,
        prepayment: value.prepayment ?? 0,
      }));
      const booking: BookingFormInterface = this.bookingData;
      booking.rooms = rooms;

      this.http
        .post<BookingInterface[]>(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings`,
          booking
        )
        .subscribe({
          next: (res: any) => {
            this.getBookings().catch(() => {});
            resolve(res.booking_id);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public putBookingDetails(
    bookingId: number,
    body: BookingFormInterface
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings/${bookingId}`,
          body
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async putBookingRoom(bookingId: number, room: any): Promise<void> {
    const body = {
      room_id: Number(room.key),
      arrival_date: room.value.arrival_date,
      departure_date: room.value.departure_date,
      adults: room.value.adults,
      children: room.value.children,
      gross_price: room.value.gross_price,
      ifa_included: false,
      prepayment: 0,
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/rooms/${room.key}`,
          body
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async deleteBookingRooms(
    bookingId: number,
    selectedRoom: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/rooms/${selectedRoom}`
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async deleteBooking(bookingId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<BookingInterface[]>(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings/${bookingId}`
        )
        .subscribe({
          next: async () => {
            await this.getBookings();
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async arriveGuestsInRoom(
    bookingId: number,
    roomId: number,
    guests: any[]
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/guests`,
          guests
        )
        .subscribe({
          next: (res: any) => {
            if (!res.success) {
              reject();
            }
            this.updateArrivedStatus(bookingId, roomId);
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public async payRoom(bookingId: number, rooms: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/charge`,
          rooms
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public async departRooms(
    bookingId: number,
    roomIdsStr: string[]
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const roomIds = roomIdsStr.map((roomId) => Number(roomId));

      this.http
        .post(
          `${environment.url}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/depart`,
          { rooms: roomIds }
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: (error: HttpErrorResponse) => {
            reject();
          },
        });
    });
  }

  /* <-- API CALLS --> */

  /* <-- HELPER FUNCTIONS --> */

  public resetSelectedRooms(): void {
    this.isValidBooking = false;
    this.sidePanelRooms = {};
  }

  public saveBookingData(booking: BookingFormInterface): void {
    this.bookingData = booking;
  }

  public setSelectedBookingsRooms(): {
    [key: string]: ExtendedBookingRoom;
  } {
    const selectedBookings = this.bookings.filter(
      (booking) => booking.booking_id === this.selectedBooking.booking_id
    );

    let roomArray: {
      [key: string]: ExtendedBookingRoom;
    } = {};

    for (let booking of selectedBookings) {
      roomArray[booking.room_id] = {
        adults: booking.adults,
        children: booking.children,
        gross_price: booking.gross_price,
        arrival_date: booking.arrival_date,
        departure_date: booking.departure_date,
        arrived: booking.arrived,
        paid: booking.paid,
        departed: booking.departed,
        room_id: booking.room_id,
        contact_name: booking.contact_name,
        booking_id: booking.booking_id,
      };
    }
    this.sidePanelRooms = roomArray;
    return roomArray;
  }

  public updateBookingRoomStatuses(
    bookingId: number,
    roomIds: number[],
    key: string,
    status: boolean
  ): void {
    this.bookings = this.bookings.map((booking) => {
      if (
        booking.booking_id === bookingId &&
        roomIds.includes(Number(booking.room_id))
      ) {
        return { ...booking, [key]: status };
      }
      //TODO check if booking status needs to be updated or not
      return booking;
    });
  }

  public checkBookingStatus(statusToCheck: string, bookingId: number): boolean {
    if (!bookingId) return false;
    const status = this.bookings.some(
      (booking) =>
        Object.keys(booking).includes(statusToCheck) &&
        booking.booking_id === bookingId &&
        !(booking as any)[statusToCheck]
    );
    return status;
  }

  public canBookingBeDeleted(bookingId: number): boolean {
    const anyRoomArrived = this.bookings.some(
      (booking: BookingInterface) =>
        booking.booking_id === bookingId && booking.arrived
    );
    return !anyRoomArrived;
  }

  public checkBookingCondStatuses(
    statusToCheck: string,
    falseStatus: string,
    bookingId: number
  ): boolean {
    const status = this.bookings.some(
      (booking) =>
        Object.keys(booking).includes(statusToCheck) &&
        booking.booking_id === bookingId &&
        (booking as any)[statusToCheck] &&
        !(booking as any)[falseStatus]
    );
    return status;
  }

  private updateArrivedStatus(bookingId: number, roomId: number): void {
    const index = this.bookings.findIndex(
      (booking) =>
        booking.booking_id === bookingId && booking.room_id === roomId
    );
    this.bookings[index].arrived = true;
  }

  private getQueryParams(): string {
    return new HttpParams()
      .set('start_date', this.queryDates.start_date)
      .set('end_date', this.queryDates.end_date)
      .toString();
  }

  public selectBooking(bookingId: string, roomId: string): void {
    this.selectedBooking = this.bookings.find(
      (booking: BookingInterface) =>
        booking.booking_id === Number(bookingId) &&
        booking.room_id === Number(roomId)
    );
    console.log('select booking ', this.selectedBooking);
  }

  /* <-- HELPER FUNCTIONS --> */
}
