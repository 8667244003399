export const errorCodes = {
  40000: 'hibás request ',
  40100: 'hiányzó token',
  40300: 'hibás token',
  40301: 'nincs felhatalmazása a végrehajtáshoz',
  40302: 'szoba limit túllépés ',
  40303: 'szervíz limit túllépés ',
  40401: 'foglalás nem található ',
  40402: 'szoba nem található',
  40403: 'tétel nem található ',
  40404: 'ooo nem található',
  40405: 'oos nem található',
  40406: 'felhasználó nem található ',
  40407: 'hotel nem található',
  40408: 'szervíz nem található',
  40901: 'aktív foglalást nem lehet törölni',
  40902: 'hibás szobabeosztás ',
  40903: 'szobán nem lehet hozzáadni, mert már benne van a foglalásban',
  40904: 'érkezett szobát nem lehet törölni ',
  40905: 'már lezárt nap',
  40906: 'szoba nem elérhető',
  40907: 'szoba OOO',
  40908: 'megérkezett szobához nem lehet előleget adni',
  40909: 'érvénytelen előleg tétel',
  40910: 'érvénytelen tétetel típus',
  40911: 'érvénytelen IFA tétel',
  40912: 'érvénytelen szoba tétel',
  40913: 'tétel törlése tiltott',
  40914: 'tétel módosítása tiltott',
  40915: 'jövőbeli napot nem lehet lezárni',
  40916: 'lezárandó napra vannak nem megérkezett vendégek',
  40917: 'lezárandó napra vannak nem távozott vendégek',
  40918: 'szoba már megérkezett',
  40919: 'ooo átfedés',
  40920: 'foglalás átfedés',
  40921: 'oos átfedés',
  40922: 'felhasználó már regisztrálva van',
  40923: 'szoba már megérkezett',
  40924: 'szoba már eltávozott',
  40925: 'vendégek már megérkeztek',
  40926: 'vendégek még nem érkeztek meg',
  40927: 'hónap még nincs lezárva',
  50000: 'szerver hiba',
  50001: 'hiányzó NTAK adatok',
  50002: 'aláíró szerver hiba',
  50003: 'NTAK szerver hiba',
};
