import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'support-feedback',
  templateUrl: './support-feedback.dialog.html',
  styleUrls: ['./support-feedback.dialog.scss'],
})
export class SupportFeedbackDialog {
  constructor(private matDialogRef: MatDialogRef<SupportFeedbackDialog>) {}

  public cancel(): void {
    this.matDialogRef.close(false);
  }
}
