import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChargeInterface, ServiceInterface } from 'src/app/@Interfaces';
import { ExtendedBookingRoom } from 'src/app/@Interfaces/room.interface';
import {
  BookingService,
  CalendarService,
  ChargeService,
  DateService,
  FocusService,
  RoomService,
  RouterService,
  ServiceService,
} from 'src/app/@services';
import { ChargeTypes } from 'src/app/enums';
import { paymentOptions, vatRateOptions } from '../../../@consts';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ServiceSelectDialog } from 'src/app/@dialogs/service-select/service-select';

@Component({
  selector: 'booking-rooms-payment',
  templateUrl: './booking-rooms-payment.component.html',
  styleUrls: ['./booking-rooms-payment.component.scss'],
})
export class BookingRoomsPaymentComponent implements OnInit, OnDestroy {
  constructor(
    private chargeService: ChargeService,
    private matDialog: MatDialog,
    private routerService: RouterService,
    private serviceService: ServiceService,
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public dateService: DateService,
    public focusService: FocusService,
    public roomService: RoomService
  ) {}

  public readonly paymentOptions: any = paymentOptions;
  public readonly vatRateOptions: any = vatRateOptions;
  public isLoading: boolean;

  public charges: ChargeInterface[];
  public newCharges: ChargeInterface[] = [];
  private originalCharges: ChargeInterface[];
  public totalPrice: number;
  public paidAmount: number;
  public remainingAmount: number;
  public selectedRoom: number;
  public areAllRoomsSelected: boolean;
  public chargeTypes = ChargeTypes;
  private paramSub: Subscription;
  private params: any = {};

  @ViewChildren('chargeForms') chargeForms: QueryList<NgForm>;

  ngOnInit(): void {
    this.paramSub = this.routerService.queryParams$.subscribe((params) => {
      if (!params) return;
      this.params = params;
    });
    setTimeout(() => {
      this.getCharges(this.params.hotel, this.params.booking);
      this.selectRoom(this.params.room);
      //TODO: maybe move to routing service
    }, 10);
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public addCharge(): void {
    this.matDialog
      .open(ServiceSelectDialog, { data: this.serviceService.services })
      .afterClosed()
      .subscribe((service: ServiceInterface) => {
        if (!service) return;

        this.newCharges.push({
          booking_id: this.bookingService.selectedBooking.booking_id,
          room_id: this.bookingService.selectedBooking.room_id,
          charge_type: service.category,
          gross_price: service.price_huf,
          qty: 1,
          unit: 'db',
          totalPrice: service.price_huf,
          vat_rate: service.vat_rate,
          payment_date: '',
          payment_method: '',
          paid: false,
          paid_by: '',
          company_payment: 0,
          name: service.name,
          comment: '',
        });
        /*  this.charges.push({
        booking_id: this.bookingService.selectedBooking.booking_id,
        room_id: this.bookingService.selectedBooking.room_id,
        charge_type: null,
        gross_price: 0,
        qty: 0,
        unit: '',
        totalPrice: 0,
        vat_rate: 0,
        payment_date: '',
        payment_method: '',
        paid: false,
        paid_by: '',
        company_payment: 0,
        name: '',
        comment: '',
      }); */
      });
  }

  public getCharges(
    hotelId: string | number = null,
    bookingId: string | number = null
  ): void {
    this.chargeService
      .getCharges(hotelId, bookingId)
      .then((charges: ChargeInterface[]) => {
        this.charges = charges;
        this.originalCharges = JSON.parse(JSON.stringify(charges));
        this.setChargeSummary();
        this.remainingAmountForRoom();
      })
      .catch((err) => {
        console.log('catch', err);
      });
  }

  public selectRoom(roomId: string): void {
    this.selectedRoom = Number(roomId);
    this.routerService.addQueryParams({ room: roomId });
    this.setChargeSummary();
  }

  public async payRoom(roomId: string): Promise<void> {
    this.isLoading = true;
    this.bookingService
      .payRoom(this.bookingService.selectedBooking.booking_id, {
        rooms: [
          {
            room_id: Number(roomId),
            gross_price: this.bookingService.sidePanelRooms[roomId].gross_price,
          },
        ],
      })
      .then(async () => {
        this.isLoading = false;
        this.bookingService.getBookings().catch(() => {});
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public formatDate(charge: ChargeInterface, date: Date): void {
    charge.payment_date = this.dateService.formatDate(date);
  }

  public postCharge(charge: ChargeInterface): void {
    charge.room_id = this.selectedRoom;
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .postCharge(charge)
      .then(() => {
        this.getCharges();
        this.newCharges = [];
      })
      .catch(() => {});
  }

  public editCharge(charge: ChargeInterface): void {
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .editCharge(charge)
      .then(() => {
        this.getCharges();
      })
      .catch(() => {});
  }

  public payCharge(payingCharge: ChargeInterface): void {
    const requestBody = {
      charges: [
        {
          charge_id: payingCharge.charge_id,
          payment_method: payingCharge.payment_method,
          payment_date: payingCharge.payment_date,
        },
      ],
    };
    this.chargeService
      .payCharge(requestBody)
      .then((paidCharges: number[]) => {
        this.charges.map((charge) => {
          if (paidCharges.includes(charge.charge_id)) {
            charge.paid = true;
          }
          return charge;
        });
        this.bookingService.getBookings().catch(() => {});
      })
      .catch(() => {});
  }

  public deleteCharge(charge: ChargeInterface): void {
    this.chargeService
      .deleteCharge(charge.charge_id)
      .then(() => {
        this.getCharges();
      })
      .catch(() => {});
  }

  public setVatIfKnown(charge: ChargeInterface): void {
    if (charge.charge_type === this.chargeTypes.prepayment) {
      charge.vat_rate = 5;
    }
  }

  public payAllCharges(form: {
    group_payment_method: string;
    group_payment_date: Date;
  }): void {
    const requestBody: { charges: any[] } = { charges: [] };
    this.charges.forEach((charge) => {
      if (
        (this.areAllRoomsSelected || charge.room_id === this.selectedRoom) &&
        !charge.paid
      ) {
        requestBody.charges.push({
          charge_id: charge.charge_id,
          payment_method: form.group_payment_method,
          payment_date: this.dateService.formatDate(
            new Date(form.group_payment_date)
          ),
        });
      }
    });
    this.chargeService
      .payCharge(requestBody)
      .then(() => {
        this.getCharges();
        this.bookingService.getBookings().catch(() => {});
      })
      .catch(() => {});
  }

  public isModified(index: number): boolean {
    return (
      JSON.stringify(this.charges[index]) ===
      JSON.stringify(this.chargeForms.toArray()[index].form.value)
    );
  }

  public selectAllRooms(): void {
    this.areAllRoomsSelected = true;
    this.setChargeSummary();
  }

  public deselectAllRooms(): void {
    this.areAllRoomsSelected = false;
    this.setChargeSummary();
  }

  public resetChargeForm(index: number): void {
    this.charges[index] = JSON.parse(
      JSON.stringify(this.originalCharges[index])
    );
  }

  public remainingAmountForRoom(): void {
    let remainingAmounts: { [key: string]: number } = {};

    this.charges.forEach((charge: ChargeInterface) => {
      if (charge.paid) return;
      if (!remainingAmounts[charge.room_id.toString()])
        remainingAmounts[charge.room_id.toString()] = 0;
      remainingAmounts[charge.room_id.toString()] +=
        charge.gross_price * charge.qty;
    });

    for (const key in this.bookingService.sidePanelRooms) {
      this.bookingService.sidePanelRooms[key].remainingAmount =
        remainingAmounts[key];
    }
  }

  public setAllPaymentMethod(paymentMethod: any): void {
    if (!this.charges) return;

    this.charges = this.charges.map((charge: ChargeInterface) => {
      if (!charge.paid) {
        charge.payment_method = paymentMethod;
      }
      return charge;
    });
  }

  public setAllPaymentDate(paymentDate: any): void {
    this.charges = this.charges.map((charge: ChargeInterface) => {
      if (!charge.paid) {
        charge.payment_date = paymentDate;
      }
      return charge;
    });
  }

  private setChargeSummary(): void {
    this.totalPrice = 0;
    this.paidAmount = 0;
    this.remainingAmount = 0;
    if (!this.charges) return;
    this.charges.forEach((charge: ChargeInterface) => {
      if (this.selectedRoom !== charge.room_id && !this.areAllRoomsSelected) {
        return;
      }
      this.totalPrice += charge.gross_price * charge.qty;
      if (charge.paid) {
        this.paidAmount += charge.gross_price * charge.qty;
      } else {
        this.remainingAmount += charge.gross_price * charge.qty;
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSub.unsubscribe();
  }
}
