import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SidePanelTypeEnum } from 'src/app/enums';
import { BookingStatusPipe } from 'src/app/@pipes/status.pipe';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { DateService } from 'src/app/@services/date.service';
import { ExtendedBookingRoom } from 'src/app/@Interfaces/room.interface';
import { HolidayService, RouterService } from 'src/app/@services';

@Component({
  selector: 'calendar-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarTableComponent {
  constructor(
    public calendarService: CalendarService,
    public bookingService: BookingService,
    public roomService: RoomService,
    private statusPipe: BookingStatusPipe,
    private tableService: TableService,
    public dateService: DateService,
    public holidayService: HolidayService,
    private routerService: RouterService
  ) {
    this.holidayService.getHolidays();
    const compactMode = localStorage.getItem('compactMode');
    this.compactMode = JSON.parse(compactMode);
  }

  public isRoomSelect: boolean;
  @Input() set sidePanelType(sidePanelType: SidePanelTypeEnum) {
    this.isRoomSelect = sidePanelType === 'room';
  }
  @Output() openEditRooms = new EventEmitter<Event>();
  public today = new Date();

  public compactMode = false;

  public toggleCompactMode(): void {
    this.compactMode = !this.compactMode;
    localStorage.setItem('compactMode', JSON.stringify(this.compactMode));
  }

  public leftClickEvent(
    roomId: number | string,
    date: string,
    event: Event
  ): void {
    if (!this.isRoomSelect) {
      return;
    }

    event.stopPropagation();
    this.tableService.handleTableClick(Number(roomId), date);
  }

  public rightClickEvent(): void {}

  public mouseUp(event: Event): void {
    event.stopPropagation();
  }

  public isAnyBookingArrivedOnSpecificDay(
    specificDay: string,
    roomId: number
  ): boolean {
    const isRemoved = this.bookingService.removedBookings.some(
      (booking) =>
        booking.arrival_date === specificDay &&
        Number(roomId) === booking?.room_id
    );
    if (isRemoved) {
      return false;
    }
    let isTempBooked: boolean;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];

      if (
        booking.arrival_date === specificDay &&
        roomId === Number(booking.room_id)
      ) {
        isTempBooked = true;
      }
    });
    if (isTempBooked) {
      return isTempBooked;
    }

    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking.arrival_date === specificDay && roomId === booking.room_id
    );

    if (!booking) {
      return false;
    }
    let insertBooking: boolean = true;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const tempBooking = this.bookingService.sidePanelRooms[key];
      if (
        tempBooking.booking_id === booking.booking_id &&
        tempBooking.room_id === booking.room_id
      ) {
        insertBooking = false;
      }
    });
    return insertBooking;
  }

  public insertEndDateBooking(
    specificDay: string,
    roomId: number | string
  ): boolean {
    let isTempBooked: boolean;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];
      if (
        booking.departure_date === specificDay &&
        Number(roomId) == booking.room_id &&
        new Date(booking.arrival_date).getTime() <
          new Date(this.bookingService.queryDates.start_date).getTime()
      ) {
        isTempBooked = true;
      }
    });

    if (isTempBooked) {
      return isTempBooked;
    }
    return this.bookingService.bookings.some(
      (booking) =>
        booking.departure_date === specificDay &&
        Number(roomId) === booking.room_id &&
        new Date(booking.arrival_date).getTime() <
          new Date(this.bookingService.queryDates.start_date).getTime()
    );
  }

  public getBookingWidth(roomId: number, arrival_date: string): string {
    let selectedBookings: ExtendedBookingRoom;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];
      if (booking.arrival_date === arrival_date && Number(key) === roomId) {
        selectedBookings = booking;
      }
    });
    if (!selectedBookings) {
      const booking = this.bookingService.bookings.find(
        (booking) =>
          booking.arrival_date === arrival_date &&
          booking.room_id === Number(roomId)
      );
      if (!booking) {
        return '';
      }
      const nights =
        this.calendarService.calculateDaysDifference(
          booking?.arrival_date,
          booking?.departure_date
        ) - 1;
      const width = nights * 70 + 60 + 'px';
      return width;
    }

    const nights =
      this.calendarService.calculateDaysDifference(
        selectedBookings?.arrival_date,
        selectedBookings?.departure_date
      ) - 1;
    const width = nights * 70 + 60 + 'px';
    return width;
  }

  public getEndDateBookingWidth(
    roomId: number,
    departure_date: string,
    offSet: number
  ): string {
    let selectedBookings: ExtendedBookingRoom;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];
      if (booking.departure_date === departure_date && Number(key) === roomId) {
        selectedBookings = booking;
      }
    });
    if (selectedBookings) {
      const tempNights =
        this.calendarService.calculateDaysDifference(
          this.bookingService.queryDates.start_date,
          selectedBookings?.departure_date
        ) - 1;
      const tempWidth = tempNights * 70 + 120 + offSet + 'px';
      return tempWidth;
    }

    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking.departure_date === departure_date && booking.room_id === roomId
    );
    const nights =
      this.calendarService.calculateDaysDifference(
        this.bookingService.queryDates.start_date,
        booking?.departure_date
      ) - 1;
    const width = nights * 70 + 120 + offSet + 'px';
    return width;
  }

  public getBookingData(
    specificDay: string,
    roomId: number | string,
    key: string,
    eventDate: 'arrival_date' | 'departure_date'
  ): any {
    let isTempBooked: ExtendedBookingRoom;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];
      if (booking[eventDate] === specificDay && key == roomId) {
        isTempBooked = booking;
      }
    });
    if (isTempBooked && Object.keys(isTempBooked).includes(key)) {
      return (isTempBooked as any)[key];
    }

    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking[eventDate] === specificDay && Number(roomId) === booking.room_id
    );
    if (booking && Object.keys(booking).includes(key)) {
      return (booking as any)[key];
    }
    return null;
  }
  //TODO fix type here as well... ==
  //TODO might even not needed since the switch from tempBooking variable

  public selectBooking(
    roomId: number | string,
    date: string,
    event: Event,
    eventDate: 'arrival_date' | 'departure_date'
  ): void {
    event.stopPropagation();
    console.log('click');

    const booking = this.bookingService.bookings.find(
      (booking) => booking[eventDate] === date && booking.room_id === roomId
    );

    if (!booking) {
      return;
    }

    this.tableService.clearRoomBookings();

    //this.calendarService.selectBooking(booking.booking_id, roomId);

    this.routerService.addQueryParams({
      booking: booking.booking_id,
      room: booking.room_id,
    });
    /*  this.tableService.loadRoomBookings(
      this.bookingService.selectedBooking.booking_id
    ); */

    this.openEditRooms.emit(event);
  }

  public isFaded(
    roomId: number | string,
    date: string,
    eventDate: 'arrival_date' | 'departure_date'
  ): boolean {
    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking[eventDate] === date && booking.room_id === Number(roomId)
    );
    let tempBooking: any;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];
      if (booking[eventDate] === date && key == roomId) {
        tempBooking = booking;
      }
    });
    return (
      booking?.booking_id !== this.bookingService.selectedBooking?.booking_id &&
      !tempBooking
    );
  }
  //TODO fix types... ==

  public getBookingStatus(
    roomId: number | string,
    date: string,
    eventDate: 'arrival_date' | 'departure_date'
  ): string {
    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking[eventDate] === date && booking.room_id === Number(roomId)
    );
    if (!booking) {
      return '';
    }
    const status = this.statusPipe.transform(booking, 'en');
    return status;
  }

  public getFormatedDate(date: Date): string {
    return this.dateService.formatDate(date);
  }

  public isDayOOO(day: Date, roomId: number): boolean {
    return this.roomService.isDateInOOO(day, roomId);
  }

  public isDayOOS(day: Date, roomId: number): boolean {
    return this.roomService.isDateInOOS(day, roomId);
  }
}
