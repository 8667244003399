import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceInterface } from 'src/app/@Interfaces';

@Component({
  selector: 'service-select',
  templateUrl: './service-select.html',
  styleUrls: ['./service-select.scss'],
})
export class ServiceSelectDialog {
  constructor(
    private matDialogRef: MatDialogRef<ServiceSelectDialog>,
    @Inject(MAT_DIALOG_DATA) public services: ServiceInterface[]
  ) {}

  public chooseService(service: ServiceInterface): void {
    this.matDialogRef.close(service);
  }

  public cancel(): void {
    this.matDialogRef.close();
  }
}
