<div class="scanner-wrapper">
  <div class="buttons-wrapper">
    <button
      class="camera-button secondary-button"
      (click)="switchNextVideo()"
      *ngIf="videoDevices.length > 1"
    >
      <mat-icon>camera_enhance</mat-icon>
    </button>
    <button class="doc-type-button secondary-button" (click)="switchIdType()">
      {{ documentType | documentType : "hu" }}
    </button>
  </div>
  <div class="progress">
    <span>Beolvasva</span><br /><span class="scanned"
      >{{ peopleScanned }}/{{ guestService.readQrCode?.totalGuests }}</span
    >
  </div>

  <video #video autoplay (playing)="drawOverlay()"></video>

  <canvas #canvas></canvas>

  <!-- <img [src]="src" alt="'no src'" /> -->

  <ngx-scanner-text
    #scanner="scanner"
    [configs]="scannerConfig"
    (event)="onImageRead($event)"
  ></ngx-scanner-text>

  <button (click)="captureFrame()" class="capture-button primary-button">
    Scan
  </button>
  <div class="loading-screen" *ngIf="isLoading">
    <app-loading-spinner [loading]="isLoading"></app-loading-spinner>
  </div>
  <div
    class="scans-finished"
    *ngIf="peopleScanned === guestService.readQrCode?.totalGuests"
  >
    <div>
      <span>Minden vendég sikeresen scannelve.</span><br />
      <span>A weboldalon töltse újra az oldalt a folytatáshoz.</span>
    </div>

    <button (click)="navToQrScanner()">Vissza a Qr kód olvasóra</button>
  </div>
</div>
