<div class="checkbox-component">
  <input
    #customInput="ngModel"
    type="checkbox"
    id="{{ name }}"
    [name]="name"
    [(ngModel)]="_value"
    (ngModelChange)="sendChange()"
    (input)="handleUserInput($event.target)"
  />
  <label class="checkbox-label" for="{{ name }}">{{ label }}</label>
</div>
