<table
  class="calendar-table"
  [ngClass]="{
    compact: compactMode
  }"
>
  <thead>
    <tr>
      <th>
        <button (click)="toggleCompactMode()">
          <i class="plus-minus-icon"></i>
        </button>
      </th>
      <th
        *ngFor="let day of calendarService.dateList; let i = index"
        [ngClass]="{
          today: dateService.isDateSameAsToday(day),
          holiday: holidayService.isHoliday(day),
          weekend: dateService.isWeekend(day)
        }"
        (click)="holidayService.toggleHoliday(day)"
      >
        <div class="date-wrapper">
          <span class="month">{{
            day.toLocaleDateString("hu-HU", { month: "short" })
          }}</span>
          <span class="day">{{ day | date : "dd" }}</span>
          <span class="day-name">{{
            day.toLocaleDateString("hu-HU", { weekday: "short" })
          }}</span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let room of roomService.rooms" (mouseup)="mouseUp($event)">
      <th [id]="room.room_id">{{ room.name }}</th>

      <td
        *ngFor="let day of calendarService.dateList; let i = index"
        [id]="room.room_id!.toString() + '_' + getFormatedDate(day)"
        (click)="leftClickEvent(room.room_id!, getFormatedDate(day), $event)"
        (contextmenu)="rightClickEvent()"
        [ngClass]="{
          today: dateService.isDateSameAsToday(day),
          ooo: isDayOOO(day, room.room_id!),
          oos: isDayOOS(day, room.room_id!),
          holiday: holidayService.isHoliday(day),
          weekend: dateService.isWeekend(day)
        }"
      >
        <i *ngIf="isDayOOO(day, room.room_id!)" class="ooo-icon"></i>
        <i *ngIf="isDayOOS(day, room.room_id!)" class="oos-icon"></i>
        <div
          class="room-booking reversed"
          *ngIf="insertEndDateBooking(getFormatedDate(day), room.room_id!)"
          [ngStyle]="{
            width: getEndDateBookingWidth(
              room.room_id!,
              getFormatedDate(day),
              0
            ),
            left:
              '-' +
              getEndDateBookingWidth(room.room_id!, getFormatedDate(day), -30),
            'pointer-events':
              isRoomSelect &&
              !isFaded(room.room_id, getFormatedDate(day), 'arrival_date')
                ? 'none'
                : 'auto'
          }"
          [ngClass]="{
            faded:
              bookingService.selectedBooking &&
              isFaded(room.room_id, getFormatedDate(day), 'departure_date')
          }"
          (click)="
            selectBooking(
              room.room_id!,
              getFormatedDate(day),
              $event,
              'departure_date'
            )
          "
        >
          <div class="name">
            {{
              getBookingData(
                getFormatedDate(day),
                room.room_id!,
                "contact_name",
                "departure_date"
              )
            }}
          </div>
          <div
            class="status"
            [ngClass]="
              getBookingStatus(
                room.room_id!,
                getFormatedDate(day),
                'departure_date'
              )
            "
          ></div>
          <div class="occupancy">
            <span class="emph">{{
              getBookingData(
                getFormatedDate(day),
                room.room_id!,
                "adults",
                "arrival_date"
              )
            }}</span
            ><span class="sub">f</span
            ><span class="emph">{{
              getBookingData(
                getFormatedDate(day),
                room.room_id!,
                "children",
                "arrival_date"
              )
            }}</span>
            <span class="sub">gy</span>
          </div>
        </div>
        <div
          class="room-booking"
          *ngIf="
            isAnyBookingArrivedOnSpecificDay(
              getFormatedDate(day),
              room.room_id!
            )
          "
          [ngStyle]="{
            width: getBookingWidth(room.room_id!, getFormatedDate(day)),
            'pointer-events':
              isRoomSelect &&
              !isFaded(room.room_id, getFormatedDate(day), 'arrival_date')
                ? 'none'
                : 'auto'
          }"
          [ngClass]="{
            faded:
              bookingService.selectedBooking &&
              isFaded(room.room_id, getFormatedDate(day), 'arrival_date')
          }"
          (click)="
            selectBooking(
              room.room_id!,
              getFormatedDate(day),
              $event,
              'arrival_date'
            )
          "
        >
          <div class="name">
            {{
              getBookingData(
                getFormatedDate(day),
                room.room_id!,
                "contact_name",
                "arrival_date"
              )
            }}
          </div>
          <div
            class="status"
            [ngClass]="
              getBookingStatus(
                room.room_id!,
                getFormatedDate(day),
                'arrival_date'
              )
            "
          ></div>
          <div class="occupancy">
            <span class="emph">{{
              getBookingData(
                getFormatedDate(day),
                room.room_id!,
                "adults",
                "arrival_date"
              )
            }}</span
            ><span class="sub">f</span
            ><span class="emph">{{
              getBookingData(
                getFormatedDate(day),
                room.room_id!,
                "children",
                "arrival_date"
              )
            }}</span>
            <span class="sub">gy</span>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
  <div *ngIf="!roomService.rooms.length">
    <span>Kérem vegyen fel szobát az admin -> szoba menüpontban.</span>
  </div>
</table>
