import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { environment } from '../@environments/environtment';
import { LoginResInterface } from '../@Interfaces';
import { PermissionInterface } from '../@Interfaces/auth.interface';
import { Capacitor } from '@capacitor/core';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private RouterService: RouterService) {}

  public IsAuthenticated: boolean;
  public isPremium: boolean;
  public roles: { [key: number]: PermissionInterface } = {};
  private tokenSubject = new BehaviorSubject<string | null>(null);

  public async signIn(body: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<LoginResInterface>(`${environment.url}api/auth`, body)
        .subscribe({
          next: (res: LoginResInterface) => {
            this.IsAuthenticated = true;
            if (Capacitor.isNativePlatform()) {
              this.RouterService.absoluteNavigation('hu/qr-scanner');
            } else this.RouterService.absoluteNavigation('hu/calendar');

            res.permissions.forEach((group) => {
              this.roles[group.hotel_id] = group;
            });
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public setToken(token: string | null): void {
    this.tokenSubject.next(token);
  }

  public getToken(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }

  public isAuthenticatedAsync(): Observable<boolean> {
    return this.http.post<any>(`${environment.url}api/auth/refresh`, {}).pipe(
      map(() => true),
      catchError(() => {
        return of(false);
      })
    );
  }

  public signOut(): void {
    this.http.post<any>(`${environment.url}api/auth/sign-out`, {}).subscribe({
      next: () => {
        this.RouterService.absoluteNavigationNoParamPreserve('hu/login');
      },
      error: () => {
        console.log('sign out error');
      },
    });
  }
}
