import { Injectable } from '@angular/core';
import { BookingService } from './booking.service';
import { RoomService } from './room.service';
import { ServiceService } from './service.service';
import { CalendarService } from './calendar.service';
import { TableService } from './table.service';

@Injectable({
  providedIn: 'root',
})
export class FunctionGroupService {
  constructor(
    private bookingService: BookingService,
    private roomService: RoomService,
    private serviceService: ServiceService,
    private calendarService: CalendarService,
    private tableService: TableService
  ) {}

  public getBookingsGroup(): void {
    this.bookingService.getBookings().then(() => {
      this.calendarService.setCalendarDays();
      //this.calendarService.autoSelectBooking();
      this.tableService.loadRoomBookings(
        this.bookingService.selectedBooking.booking_id
      );
    });
  }
}
