import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RoomInterface } from 'src/app/@Interfaces';
import { RouterService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { RoomMenu } from 'src/app/enums';

@Component({
  selector: 'rooms-overview',
  templateUrl: './rooms-overview.component.html',
  styleUrls: ['./rooms-overview.component.scss'],
})
export class RoomsOverviewComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  public roomLimit: number;
  public dynamicThe: string = 'a(z)';
  public floors = [-1, 0, 1, 2];
  public roomMenu = RoomMenu;

  @Input()
  public selectedMenu: string;

  public selectRoom(room: RoomInterface): void {
    this.roomService.roomOOO = [];
    this.roomService.roomOOS = [];
    if (this.selectedMenu === this.roomMenu.Add) {
      return;
    }
    if (this.selectedMenu === this.roomMenu.Overview) {
      this.routerService.absoluteNavigation('/hu/admin/rooms/edit');
    }
    this.routerService.addQueryParams({ room: room.room_id });

    if (this.selectedMenu === this.roomMenu.OutOfOrder) {
      this.roomService.getRoomOOO(room.room_id).catch(() => {});
    }
    if (this.selectedMenu === this.roomMenu.OutOfService) {
      this.roomService.getRoomOOS(room.room_id).catch(() => {});
    }
  }

  public deselectRoom(): void {
    this.routerService.removeQueryParam(['room']);
  }
}
