import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/@services/auth.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Capacitor } from '@capacitor/core';
import { RouterService } from 'src/app/@services';
import { MatDialog } from '@angular/material/dialog';
import { ServerSelectorDialog } from 'src/app/@dialogs/server-select/server-select.dialog';
import { CheckInCodeDialog } from 'src/app/@dialogs/check-in-code/check-in-code.dialog';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private hotelService: HotelService,
    private routerService: RouterService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.authService.IsAuthenticated) {
      this.routerService.absoluteNavigation('hu/calendar');
    }
    if (Capacitor.isNativePlatform()) this.loadCredentials();
    this.isMobile = Capacitor.isNativePlatform();
  }

  // TODO: dynamic
  public isMobile: boolean;

  public loginGroup: any = {
    username: null,
    password: null,
  };
  public rememberMe: boolean;
  public isLoading: boolean;
  protected counter: number = 0;

  public signIn(): void {
    this.isLoading = true;
    this.authService
      .signIn(this.loginGroup)
      .then(() => {
        this.isLoading = false;
        this.authService.IsAuthenticated = true;
        if (Capacitor.isNativePlatform() && this.rememberMe) {
          this.saveCredentials(
            this.loginGroup.username,
            this.loginGroup.password
          );
        }

        this.hotelService
          .getHotels()
          .then(() => {
            this.hotelService.selectHotel(this.hotelService.hotels[0]);
          })
          .catch(() => {});
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  protected openServerSelector(): void {
    if (this.counter++ < 5) return;

    this.counter = 0;
    this.matDialog.open(ServerSelectorDialog);
  }

  protected openCheckInCodeDialog(): void {
    this.matDialog.open(CheckInCodeDialog);
  }

  private saveCredentials(email: string, pw: string): void {
    SecureStoragePlugin.set({ key: 'email', value: email });
    SecureStoragePlugin.set({ key: 'password', value: pw });
  }

  private async loadCredentials(): Promise<void> {
    this.loginGroup.username = (
      await SecureStoragePlugin.get({ key: 'email' }).catch(() => {
        return null;
      })
    ).value;
    this.loginGroup.password = (
      await SecureStoragePlugin.get({ key: 'password' }).catch(() => {
        return null;
      })
    ).value;
  }
}
