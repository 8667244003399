import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Injectable({
  providedIn: 'root', // or 'any' or specify a module where the pipe is declared
})
@Pipe({
  name: 'bookingStatus',
})
export class BookingStatusPipe implements PipeTransform {
  transform(booking: any, lang: string): string {
    if (!booking) {
      return 'Unknown status';
    }

    // Check the values of 'departed', 'paid', and 'arrived' keys
    const departed = booking.departed === true;
    const paid = booking.paid === true;
    const arrived = booking.arrived === true;

    // Determine the status based on the values
    if (departed) {
      return lang === 'en' ? 'departed' : 'Eltávozott';
    } else if (paid) {
      return lang === 'en' ? 'paid' : 'Fizetett';
    } else if (arrived) {
      return lang === 'en' ? 'arrived' : 'Megérkezett';
    } else {
      return lang === 'en' ? 'Booked' : 'Lefoglalt';
    }
  }
}
