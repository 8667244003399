<div class="services-wrapper">
  <div
    class="main-content"
    [ngClass]="
      routerService.tertiaryMenu === serviceMenu.Overview ? 'overview-mode' : ''
    "
  >
    <div class="selection-wrapper">
      <div class="header">
        <services-overview></services-overview>
      </div>
    </div>
    <div class="action-wrapper">
      <div class="action-panel">
        <router-outlet></router-outlet>
        <!-- <services-add *ngIf="selectedMenu === serviceMenu.Add"></services-add>
        <services-edit
          *ngIf="selectedMenu === serviceMenu.Edit"
          [selectService]="selectedService"
        ></services-edit> -->
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="button-wrapper">
      <button
        class="secondary-button"
        (click)="selectMenu(serviceMenu.Overview)"
        [ngClass]="
          routerService.tertiaryMenu === serviceMenu.Overview ? 'active' : ''
        "
      >
        Áttekintés
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(serviceMenu.Add)"
        [ngClass]="
          routerService.tertiaryMenu === serviceMenu.Add ? 'active' : ''
        "
      >
        Hozzáad
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(serviceMenu.Edit)"
        [ngClass]="
          routerService.tertiaryMenu === serviceMenu.Edit ? 'active' : ''
        "
      >
        Módosít
      </button>
    </div>
  </div>
</div>
