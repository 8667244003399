import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialog } from './@dialogs/confirmation/confirmation.dialog';
import { OnlynumberDirective } from './@directives/numberOnly.directive';
import { OptionToTextPipe } from './@pipes/room-type.pype';
import { GroupByPipe } from './@pipes/grouping.pipe';
import { ErrorDialog } from './@dialogs/error/error.dialog';
import { SuccessfulSnackBar } from './@snackbars/successful/successful.snackbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BookingDeleteDialog } from './@dialogs/booking-delete/booking-delete.dialog';
import { CloseDayDialog } from './@dialogs/close-day/close-day.dialog';
import { BookingStatusPipe } from './@pipes/status.pipe';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { UnsuccessfulSnackBar } from './@snackbars/unsuccessful/unsuccessful.snackbar';
import { MatIconModule } from '@angular/material/icon';
import {
  AuthInterceptor,
  AuthService,
  BookingService,
  CalendarService,
  DateService,
  FocusService,
  HotelService,
  RoomService,
  ServiceService,
  SnackbarService,
  TableService,
  ChargeService,
  OfflineService,
  HolidayService,
  RouterService,
  ScannerService,
  FunctionGroupService,
} from './@services';
import {
  AdminComponent,
  AdminHotelComponent,
  BookingArrivalComponent,
  BookingEditDetailsComponent,
  BookingEditRoomsComponent,
  BookingNewDetailsComponent,
  BookingNewRoomsComponent,
  BookingRoomsDepartComponent,
  BookingRoomsPaymentComponent,
  CalendarComponent,
  CalendarTableComponent,
  HomeComponent,
  LoadingSpinnerComponent,
  LoginComponent,
  NavBarComponent,
  RoomsAddComponent,
  RoomsComponent,
  RoomsEditComponent,
  RoomsOooComponent,
  RoomsOosComponent,
  RoomsOverviewComponent,
  ServicesComponent,
  ServicesOverviewComponent,
  ServicesAddComponent,
  ServicesEditComponent,
  RoomPanelComponent,
  ArrivalCardComponent,
} from './@components';
import { PremiumRequiredSnackBar } from './@snackbars/premium-required/premium-required.snackbar.';
import { DatePickerComponent } from './@components/@common/date-input/date-input.component';
import { InputComponent } from './@components/@common/text-input/text-input.component';
import { CheckboxComponent } from './@components/@common/checkbox-input/checkbox-input.component';
import { SelectComponent } from './@components/@common/select-input/select-input.component';
import { ScannerComponent } from './@components/scan/scanner.component';
import { NgxScannerTextModule } from 'ngx-scanner-text';
import { QRCodeModule } from 'angularx-qrcode';
import { QrScannerComponent } from './@components/qr-scanner/qr-scanner.component';
import { ProfileComponent } from './@components/@pages/profile/profile.component';
import { DocumentTypePipe } from './@pipes/document-type.pipe';
import { ServiceSelectDialog } from './@dialogs/service-select/service-select';

registerLocaleData(localeHu);

@NgModule({
  declarations: [
    AdminComponent,
    AppComponent,
    HomeComponent,
    NavBarComponent,
    LoginComponent,
    AdminComponent,
    CalendarComponent,
    RoomsComponent,
    ConfirmationDialog,
    ServicesComponent,
    OnlynumberDirective,
    OptionToTextPipe,
    GroupByPipe,
    ErrorDialog,
    SuccessfulSnackBar,
    UnsuccessfulSnackBar,
    CalendarTableComponent,
    PremiumRequiredSnackBar,
    BookingDeleteDialog,
    CloseDayDialog,
    BookingNewRoomsComponent,
    BookingNewDetailsComponent,
    BookingRoomsDepartComponent,
    BookingRoomsPaymentComponent,
    BookingArrivalComponent,
    BookingStatusPipe,
    LoadingSpinnerComponent,
    BookingEditDetailsComponent,
    BookingEditRoomsComponent,
    RoomsAddComponent,
    RoomsEditComponent,
    RoomsOooComponent,
    RoomsOosComponent,
    RoomsOverviewComponent,
    ServicesOverviewComponent,
    ServicesAddComponent,
    ServicesEditComponent,
    RoomPanelComponent,
    ArrivalCardComponent,
    DatePickerComponent,
    InputComponent,
    CheckboxComponent,
    SelectComponent,
    ScannerComponent,
    QrScannerComponent,
    AdminHotelComponent,
    ProfileComponent,
    DocumentTypePipe,
    ServiceSelectDialog,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    NgxScannerTextModule,
    QRCodeModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    { provide: LOCALE_ID, useValue: 'hu' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthService,
    BookingService,
    HotelService,
    RoomService,
    ServiceService,
    CalendarService,
    FocusService,
    TableService,
    DateService,
    SnackbarService,
    ChargeService,
    OfflineService,
    HolidayService,
    RouterService,
    ScannerService,
    FunctionGroupService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
