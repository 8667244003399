import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { FocusService } from 'src/app/@services';

@Component({
  selector: 'checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  constructor(public focusService: FocusService) {}
  @Input() _value: any;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() label: string;
  @Input() name: string;
  @Output() clicked = new EventEmitter<void>();
  @Output() userInput = new EventEmitter<boolean>();
  @ViewChild('customInput', { static: false }) customInput: NgModel;

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    this.value = value;
    if (value === null && this.customInput)
      this?.customInput?.control?.markAsUntouched();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onInput(event: any): void {
    this.value = event.target.value;
    this.onChange(this.value);
  }

  public sendChange(): void {
    this.onChange(this._value);
    this.onTouched();
  }

  public emitClick(): void {
    this.clicked.emit();
  }

  public handleUserInput(event: any): void {
    this.userInput.emit(event.checked);
  }
}
