<div class="room-panel">
  <div class="room-name">
    <span *ngIf="!bookingService.sidePanelRooms[room.key].newRoom">{{
      roomService.getRoomName(room.key)
    }}</span>
    <select
      class="form-dropdown-select"
      *ngIf="bookingService.sidePanelRooms[room.key].newRoom"
      (change)="selectRoom($event, room.key)"
    >
      <option style="display: none"></option>
      <option
        *ngFor="let roomId of getSelectableRoomIds(room.key)"
        [value]="roomId"
        [selected]="roomId === room.key"
      >
        {{ roomService.getRoomName(roomId) }}
      </option>
    </select>
  </div>

  <div class="status">
    <span>{{
      bookingService.sidePanelRooms[room.key] | bookingStatus : "hu"
    }}</span>
  </div>

  <div class="delete">
    <button type="button" (click)="deleteRoom()">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
  <div class="modify-wrapper">
    <button class="secondary-button" *ngIf="isEditing" (click)="editRoom()">
      Módosít
    </button>
  </div>

  <div class="date-wrapper">
    <date-input
      name="inputStartDate"
      [(ngModel)]="bookingService.sidePanelRooms[room.key].arrival_date"
      (ngModelChange)="
        tableService.handleDatePickerV2(
          room.key,
          bookingService.sidePanelRooms[room.key].arrival_date,
          true
        )
      "
      [disabled]="
        room.key.split('_')[0] === 'newRoom' ||
        bookingService.sidePanelRooms[room.key].arrived
      "
      [filter]="tableService.getBookedDayFilter"
      (clicked)="setRoomIdForCalendar(room.key)"
    ></date-input>
    <span class="num-of-nights"
      >{{
        calendarService.calculateDaysDifference(
          bookingService.sidePanelRooms[room.key].arrival_date,
          bookingService.sidePanelRooms[room.key].departure_date
        )
      }}
      éj</span
    >

    <date-input
      name="inputDepartureDate"
      [(ngModel)]="bookingService.sidePanelRooms[room.key].departure_date"
      (ngModelChange)="
        tableService.handleDatePickerV2(
          room.key,
          bookingService.sidePanelRooms[room.key].departure_date,
          false
        )
      "
      [disabled]="
        room.key.split('_')[0] === 'newRoom' ||
        bookingService.sidePanelRooms[room.key].departed
      "
      [filter]="tableService.getBookedDayFilter"
      (clicked)="setRoomIdForCalendar(room.key)"
    ></date-input>
  </div>

  <div class="occupancy-wrapper">
    <div class="occupancy-block">
      <input
        [disabled]="
          room.key.split('_')[0] === 'newRoom' ||
          bookingService.sidePanelRooms[room.key].arrived
        "
        type="number"
        [(ngModel)]="bookingService.sidePanelRooms[room.key].adults"
      />
      <div>
        <img class="adult-img" src="assets/adult.svg" />
      </div>
    </div>
    <div class="occupancy-block">
      <input
        [disabled]="
          room.key.split('_')[0] === 'newRoom' ||
          bookingService.sidePanelRooms[room.key].arrived
        "
        type="number"
        [(ngModel)]="bookingService.sidePanelRooms[room.key].children"
      />
      <div>
        <img class="child-img" src="assets/child.svg" />
      </div>
    </div>
  </div>
  <div class="amount-block">
    <input
      [disabled]="
        room.key.split('_')[0] === 'newRoom' ||
        bookingService.sidePanelRooms[room.key].paid
      "
      class="amount"
      type="number"
      [(ngModel)]="bookingService.sidePanelRooms[room.key].gross_price"
    />
    <input type="text" class="huf-label" value="HUF" disabled />
  </div>
  <div class="ifa-wrapper">
    <checkbox-input
      type="checkbox"
      name="ifa"
      [label]="'+ IFA'"
      [(ngModel)]="bookingService.sidePanelRooms[room.key].ifa_included"
    />
  </div>
  <!-- <div class="pre-payment-wrapper">
    <input
      type="checkbox"
      [(ngModel)]="bookingService.sidePanelRooms[room.key].ifa_included"
    />
    <input
      type="number"
      [(ngModel)]="bookingService.sidePanelRooms[room.key].prepayment"
    />
  </div> -->
</div>
