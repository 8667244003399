import { Component } from '@angular/core';
import { AuthService } from './@services/auth.service';
import { HotelService } from './@services/hotel.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { FocusService } from './@services/focus.service';
import { environment } from './@environments/environtment';
import { RouterService } from './@services';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public authService: AuthService,
    private router: Router,
    private focusService: FocusService,
    private hotelService: HotelService,
    private routerService: RouterService
  ) {
    console.log('Env ', environment.env);

    this.hotelService
      .getHotels()
      .then(() => {
        this.hotelService.selectHotel(this.hotelService.hotels[0]);
        this.authService.IsAuthenticated = true;
        if (this.router.url.split('?')[0] === '/hu/login') {
          this.routerService.absoluteNavigation('hu/calendar');
        }
      })
      .catch(() => {
        if (this.router.url.split('?')[0] !== '/hu/register') {
          console.log('url ', this.router.url);

          this.routerService.absoluteNavigation('hu/login');
        }
      });

    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.focusService.selectedMenu = event.url.split('/')[2];
        this.focusService.selectedSubMenu = event.url.split('/')[3];
        this.routerSub.unsubscribe();
      });
  }
  title = 'KisPortas';
  private routerSub: Subscription;
}
