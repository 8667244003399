import { Component, OnInit } from '@angular/core';
import { ServiceInterface } from 'src/app/@Interfaces';
import { RouterService, ServiceService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { ServiceMenu } from 'src/app/enums';

@Component({
  selector: 'services-overview',
  templateUrl: './services-overview.component.html',
  styleUrls: ['./services-overview.component.scss'],
})
export class ServicesOverviewComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public serviceService: ServiceService,
    public focusService: FocusService,
    public routerService: RouterService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  // public roomLimit: number;
  public dynamicThe: string = 'a(z)';
  public serviceMenu = ServiceMenu;

  public selectService(service: ServiceInterface): void {
    if (
      this.routerService.tertiaryMenu === this.serviceMenu.Add ||
      this.routerService.tertiaryMenu === this.serviceMenu.Overview
    ) {
      return;
    }
    this.routerService.addQueryParams({ service: service.service_id });
  }

  public deleteService(service: any): void {
    this.serviceService.deleteService(service);
  }
}
