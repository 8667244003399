import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { GuestInterface } from 'src/app/@Interfaces/guest.interface';
import {
  BookingService,
  CalendarService,
  DateService,
  FocusService,
  GuestService,
  HotelService,
  RoomService,
  RouterService,
} from 'src/app/@services';
import {
  countryOfResidenceOptions,
  documentTypeOptions,
  genderOptions,
  nationalityOptions,
  taxExemptionReasonOptions,
} from 'src/app/@consts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arrival-card',
  templateUrl: './arrival-card.component.html',
  styleUrls: ['./arrival-card.component.scss'],
})
export class ArrivalCardComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private dateService: DateService,
    private guestService: GuestService,
    private hotelService: HotelService,
    private cdr: ChangeDetectorRef,
    private routerService: RouterService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Input() guest: GuestInterface;
  @Input() totalGuests: number;
  @Input() roomId: number;
  @ViewChild('guestForm', { static: false }) guestForm: NgForm;
  @Output('isValid') isValid = new EventEmitter<void>();
  @Output('addGuest') addGuestEmmiter = new EventEmitter<GuestInterface>();
  public isLoading: boolean;
  public isScannerOpen: boolean;
  public needsScanning: boolean = true;
  public documentType: 'id' | 'passport' = 'id';
  public readonly genderOptions = genderOptions;
  public readonly documentTypeOptions = documentTypeOptions;
  public readonly countryOfResidenceOptions = countryOfResidenceOptions;
  public readonly nationalityOptions = nationalityOptions;
  public readonly taxExemptionReasonOptions = taxExemptionReasonOptions;

  private originalFormVal: string;
  private paramSub: Subscription;
  public qrData: any;

  ngOnInit(): void {
    this.paramSub = this.routerService.queryParams$.subscribe((params) => {
      this.qrData = JSON.stringify({
        hotelId: this.hotelService.selectedHotelId,
        bookingId:
          params.booking ?? this.bookingService?.selectedBooking?.booking_id,
        roomId: this.roomId,
        totalGuests: this.totalGuests,
      });
    });
  }

  ngAfterViewInit(): void {
    this.guestForm.valueChanges.subscribe(() => {
      this.isValid.emit();
    });

    this.originalFormVal = JSON.stringify(this.guest);

    this.needsScanning = this.guest.guest_id === '';
    this.cdr.detectChanges();
  }

  public addGuest(): void {
    if (!this.guest.first_name_og) this.attachEmptyOgFields();
    this.addGuestEmmiter.emit(this.guest);
  }

  public editGuest(): void {
    this.guestService
      .editGuest(this.guest)
      .then((editedGuest: GuestInterface) => {
        this.guest = editedGuest;
        this.originalFormVal = JSON.stringify(editedGuest);
        this.guestForm.form.markAsPristine();
      })
      .catch(() => {});
  }

  public resetGuest(): void {
    this.guest = JSON.parse(this.originalFormVal);
    this.guestForm.form.markAsPristine();
  }

  public formatDate(charge: GuestInterface, date: Date): void {
    charge.birth_date = this.dateService.formatDate(date);
  }

  public closeScanner(): void {
    this.isScannerOpen = false;
  }

  public skipScanning(): void {
    this.needsScanning = false;
  }

  private attachEmptyOgFields(): void {
    this.guest.birth_date_og = null;
    this.guest.birth_first_name_og = null;
    this.guest.birth_last_name_og = null;
    this.guest.birth_place_og = null;
    this.guest.document_number_og = null;
    this.guest.document_type_og = null;
    this.guest.first_name_og;
    this.guest.gender_og = null;
    this.guest.last_name_og = null;
    this.guest.mothers_first_name_og = null;
    this.guest.mothers_last_name_og = null;
    this.guest.nationality_og = null;
  }

  ngOnDestroy(): void {
    this.paramSub.unsubscribe();
  }
}
