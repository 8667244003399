import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { SnackbarService } from './snackbar.service';
import { Router } from '@angular/router';
import { OfflineService } from './offline.service';
import { environment } from '../@environments/environtment';
import { RouterService } from './router.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private snackBarService: SnackbarService,
    private http: HttpClient,
    private router: Router,
    private offlineService: OfflineService,
    private routerService: RouterService,
    private authService: AuthService
  ) {}

  private expireTime = 3300000;
  private expireTimer: any;
  private authRegex: RegExp = new RegExp(`\\bapi/auth/refresh\\b`, 'i');

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;
    if (false) {
      return of(this.offlineService.GetOfflineResponse(url, method));
    }
    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (
            event instanceof HttpResponse &&
            request.method !== 'GET' &&
            !this.authRegex.test(request.url)
          ) {
            this.handleResponse();
          }
          if (event instanceof HttpResponse) {
            if (this.expireTimer) {
              clearTimeout(this.expireTimer);
            }
            this.expireTimer = setTimeout(() => {
              this.http
                .post(`${environment.url}api/auth/refresh`, {})
                .subscribe({ next: () => {}, error: () => {} });
            }, this.expireTime);
          }
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse && request.method !== 'GET') {
            this.handleError();
          }
          if (error?.status === 401) {
            console.log('401 err');
            this.authService.IsAuthenticated = false;
            this.routerService.absoluteNavigation('hu/login');
          }
        },
      })
    );
  }

  private handleResponse(): void {
    // Call your service function for successful response handling
    this.snackBarService.openOkSnackBar();
  }

  private handleError(): void {
    // Call your service function for error handling
    this.snackBarService.openFailedSnackBar();
  }
}
