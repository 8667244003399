import { Component, OnInit } from '@angular/core';
import { FocusService, HotelService } from 'src/app/@services';

@Component({
  selector: 'admin-hotel',
  templateUrl: './admin-hotel.component.html',
  styleUrls: ['./admin-hotel.component.scss'],
})
export class AdminHotelComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public focusService: FocusService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
      this.name = this.hotelService.selectedHotel.name;
    }
  }

  public dynamicThe: string = 'a(z)';
  public name: string = 'Hotel';

  public autoConcludeChanged(status: boolean): void {
    this.hotelService.autoConclude(status);
  }
}
