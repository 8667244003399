<div class="close-day-dialog-wrapper">
  <h1>Biztosan lezárja a napot?</h1>
  <div class="date-wrapper">
    <h2>{{hotelService.selectedHotel.last_concluded_day}}</h2>
    <h3>{{hotelService.selectedHotel.last_concluded_day | date: 'EEEE' }}</h3>
  </div>
  <div class="button-wrapper">
    <button class="secondary-button" (click)="cancel()">Nem</button>
    <button class="primary-button" (click)="continue()">Igen</button>
  </div>
</div>
