import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'premium-required',
  templateUrl: 'premium-required.snackbar.html',
  styleUrls: ['premium-required.snackbar.scss'],
})
export class PremiumRequiredSnackBar {
  constructor(public snackBarRef: MatSnackBarRef<PremiumRequiredSnackBar>) {}

  public closeSnackBar(): void {
    this.snackBarRef.dismiss();
  }
}
