import { Injectable } from '@angular/core';
import { RoomService } from './room.service';
import { BookingService } from './booking.service';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(
    private roomService: RoomService,
    private bookingService: BookingService,
    private dateService: DateService
  ) {}

  public dateList: Date[];
  public inputStartDate: Date = new Date();
  public bookingAction: boolean = false;

  /* <-- HELPER FUNCTIONS --> */

  public setCalendarDays(): void {
    const dayArray: Date[] = [];
    let day: Date = this.inputStartDate;
    day = new Date(day.getTime() - 259200000); //259200000 = 3 days
    const EndDate: Date = new Date(day.getTime() + 2678400000); //2678400000 = 30days

    this.bookingService.queryDates.start_date =
      this.dateService.formatDate(day);
    this.bookingService.queryDates.end_date =
      this.dateService.formatDate(EndDate);

    const maximumsDays = 60;
    let i = 0;
    while (!this.checkDateDayAccuretly(day, EndDate) && i < maximumsDays) {
      i++;
      dayArray.push(day);
      const tempDay = new Date(day);
      day = new Date(tempDay.setDate(tempDay.getDate() + 1));
    }

    this.dateList = [];

    setTimeout(() => {
      this.dateList = dayArray;
    }, 50);
  }

  /*  public autoSelectBooking(): void {
    if (
      this.routerService?.queryParams?.['booking'] &&
      this.routerService?.queryParams?.['room']
    ) {
      const bookingId: number = Number(
        this.routerService.queryParams['booking']
      );
      const roomId: number = Number(this.routerService.queryParams['room']);
      this.selectBooking(bookingId, roomId);
    }
  } */

  public selectBooking(
    selectedBookingId: number,
    roomId: number | string
  ): void {
    const selectedbooking = this.bookingService.bookings.find(
      (booking) =>
        booking.booking_id === selectedBookingId &&
        booking.room_id === Number(roomId)
    );

    if (selectedbooking) {
      this.bookingService.selectedBooking = selectedbooking;
    }
    this.bookingAction = true;
    this.bookingService.bookingData.contact_name = selectedbooking.contact_name;
  }

  public closeAction(): void {
    this.bookingAction = false;
    this.bookingService.selectedBooking = null;
  }

  public setAvailableDayClasses(): void {
    for (const day of this.dateList) {
      for (const room of this.roomService.rooms) {
        const cell = document.getElementById(
          room.room_id!.toString() +
            '_' +
            day.getFullYear().toString() +
            '-' +
            (day.getMonth() + 1).toString() +
            '-' +
            day.getDate().toString().padStart(2, '0')
        );
        if (cell && !cell.classList.contains('booked')) {
          cell.classList.add('free');
        }
      }
    }
  }

  public removeAvailableDayClasses(): void {
    for (const day of this.dateList) {
      for (const room of this.roomService.rooms) {
        const cell = document.getElementById(
          room.room_id!.toString() +
            '_' +
            day.getFullYear().toString() +
            '-' +
            (day.getMonth() + 1).toString() +
            '-' +
            day.getDate().toString().padStart(2, '0')
        );
        if (cell && cell.classList.contains('free')) {
          cell.classList.remove('free');
        }
        if (cell && cell.classList.contains('selected')) {
          cell.classList.remove('selected');
        }
      }
    }
  }

  public insertBookingDiv(
    roomID: number,
    arrivalDate: string,
    departureDate: string
  ): void {
    const room = roomID + '_' + arrivalDate;
    const cell = document.getElementById(room);

    if (cell) {
      const div = document.createElement('div');
      div.classList.add('room-booking');
      const nights =
        this.calculateDaysDifference(arrivalDate, departureDate) - 1;
      const width = nights * 70 + 60 + 'px';
      div.style.width = width;
      div.innerHTML = arrivalDate;

      cell.appendChild(div);
    }
  }

  public calculateDaysDifference(startDate: string, endDate: string): number {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const timeDifference = endDateObj.getTime() - startDateObj.getTime();

    let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (isNaN(daysDifference)) {
      daysDifference = 0;
    }
    return daysDifference;
  }

  /* public navigateToCalendar(): void {
    this.routerService.absoluteNavigationNoParamPreserve('hu/calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
  } */

  private checkDateDayAccuretly(day1: Date, day2: Date): boolean {
    return (
      day1.getFullYear() === day2.getFullYear() &&
      day1.getMonth() === day2.getMonth() &&
      day1.getDate() === day2.getDate()
    );
  }

  /* <-- HELPER FUNCTIONS --> */
}
