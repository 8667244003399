import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { HotelInterface } from 'src/app/@Interfaces';
import {
  BookingService,
  CalendarService,
  RouterService,
  TableService,
} from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  constructor(
    public hotelService: HotelService,
    public focusService: FocusService,
    private routerService: RouterService,
    private bookingService: BookingService,
    private calendarService: CalendarService,
    private tableService: TableService
  ) {
    this.isNative = Capacitor.isNativePlatform();
  }

  protected isNative: boolean;

  public navigate(path: string): void {
    this.routerService.absoluteNavigationNoParamPreserve('hu/' + path, {
      hotel: this.hotelService.selectedHotelId,
    });
    this.focusService.selectedMenu = path;
    this.bookingService.selectedBooking = null;
  }

  public hotelSelected(hotel: HotelInterface): void {
    this.hotelService.selectHotel(hotel);
    this.bookingService
      .getBookings()
      .then(() => {
        this.calendarService.setCalendarDays();
        // this.calendarService.autoSelectBooking();
        this.tableService.loadRoomBookings(
          this.bookingService.selectedBooking.booking_id
        );
      })
      .catch(() => {
        this.calendarService.setCalendarDays();
      });
    // this.roomService.getRooms();
    //this.serviceService.getServices();
  }
}
