import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Configs, NgxScannerTextComponent, Page } from 'ngx-scanner-text';
import * as MRZ from 'mrz';
import { GuestService, RouterService, ScannerService } from 'src/app/@services';

@Component({
  selector: 'scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements AfterViewInit {
  constructor(
    private cdr: ChangeDetectorRef,
    public guestService: GuestService,
    private scannerService: ScannerService,
    private routerService: RouterService
  ) {}

  @ViewChild('video', { static: false }) video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('scanner', { static: false })
  scanner: NgxScannerTextComponent;
  @Input() documentType: 'id' | 'passport' = 'id';

  private videoIndex: number = 0;
  private readonly acceptedLengths: number[] = [9, 30, 36, 44];
  private mediaStream: MediaStream;

  protected isLoading: boolean;
  protected peopleScanned: number = 0;
  public src: string;
  public videoDevices: MediaDeviceInfo[] = [];
  public scannerConfig: Configs = {
    src: '',
    languages: ['eng'],
    color: 'red',
    isAuto: true,
    isImage: false,
  };

  async ngAfterViewInit(): Promise<void> {
    if (!Capacitor.isNativePlatform()) return;

    await this.checkPermission();
    let videoDevices: MediaDeviceInfo[] = [];

    while (videoDevices.length === 0) {
      videoDevices = await this.getVideoDevices();

      if (videoDevices.length === 0) {
        console.log('No video devices found. Retrying...');
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    }

    this.startVideo();
  }

  public captureFrame(): void {
    this.isLoading = true;
    const video = this.video.nativeElement;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
    }

    // Define the ROI (e.g., center of the image)
    const roiWidth = video.videoWidth * 0.9;
    let roiHeight =
      this.documentType === 'id'
        ? (roiWidth / 321) * 204
        : (roiWidth / 295.5) * 207.8;
    const roiX = (video.videoWidth - roiWidth) / 2;
    let roiY = (video.videoHeight - roiHeight) / 2;

    if (this.documentType === 'id') {
      roiY += roiHeight * 0.6;
      roiHeight = roiHeight * 0.4;
    } else {
      roiY += roiHeight * 0.7;
      roiHeight = roiHeight * 0.3;
    }

    const imageData = context.getImageData(roiX, roiY, roiWidth, roiHeight);

    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = roiWidth;
    croppedCanvas.height = roiHeight;
    const croppedContext = croppedCanvas.getContext('2d');
    croppedContext.putImageData(imageData, 0, 0);

    //Convert canvas to base64 image URL
    const imgUrl = croppedCanvas.toDataURL('image/png');
    this.scannerConfig.src = imgUrl;
    this.src = imgUrl;
    this.onScanOCR(this.scanner);
  }

  public onImageRead(scan: Page): void {
    const mrz = this.getLastThreeLines(scan.text);
    try {
      const scanFields = MRZ.parse(mrz, { autocorrect: true }).fields;
      const guestDetails = {
        birth_date: scanFields.birthDate,
        document_number: scanFields.documentNumber,
        document_type: this.documentType === 'id' ? 'I' : 'P',
        first_name: scanFields.firstName ?? scanFields.lastName,
        last_name: scanFields.lastName ?? scanFields.firstName,
        gender: scanFields.sex,
        nationality: scanFields.nationality,
      };
      this.guestService
        .sendScan(this.guestService.readQrCode, guestDetails)
        .then(() => {
          this.isLoading = false;
          this.peopleScanned++;
          console.log('person scanned ', this.peopleScanned);
        })
        .catch(() => {
          this.isLoading = false;
          console.log('send scan failed');
        });
    } catch (error) {
      this.isLoading = false;
      console.log('mrz err');
      // TODO: Error snackbar
    }

    this.cdr.detectChanges();
  }

  protected drawOverlay(): void {
    const video = this.video.nativeElement;
    const overlayCanvas = this.canvas.nativeElement;
    const overlayContext = overlayCanvas.getContext('2d');
    overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

    overlayCanvas.width = video.clientWidth;
    overlayCanvas.height = video.clientHeight;

    const roiWidth = overlayCanvas.width * 0.9;
    const roiHeight =
      this.documentType === 'id'
        ? (roiWidth / 321) * 204
        : (roiWidth / 295.5) * 207.8;
    const roiX = (video.clientWidth - roiWidth) / 2;
    const roiY = (video.clientHeight - roiHeight) / 2;

    overlayContext.strokeStyle = 'red';
    overlayContext.lineWidth = 2;
    overlayContext.strokeRect(roiX, roiY, roiWidth, roiHeight);
  }

  public switchNextVideo(): void {
    if (this.videoDevices.length < 2) return;

    this.stopVideoStream();

    this.videoIndex =
      this.videoDevices.length - 1 !== this.videoIndex
        ? this.videoIndex + 1
        : 0;
    this.startVideo(this.videoDevices[this.videoIndex].deviceId);
  }

  public navToQrScanner(): void {
    this.routerService.absoluteNavigation('hu/qr-scanner');
  }

  private onScanOCR(scanner: NgxScannerTextComponent): void {
    try {
      scanner.scanOCR(this.scannerConfig);
    } catch (error) {
      console.log('scanner error ', error);
    }
  }

  private async checkPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!Capacitor.isNativePlatform()) resolve();
      const permission = await Camera.checkPermissions().catch(() => {
        return { camera: 'denied' };
      });
      console.log('permission ', permission);

      if (permission.camera !== 'denied') resolve();
      Camera.requestPermissions();
      resolve();
    });
  }

  private async startVideo(
    deviceId: string = this.videoDevices[0]?.deviceId
  ): Promise<void> {
    console.log('start vid', deviceId);

    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: { exact: deviceId },
        },
      })
      .then((stream: MediaStream) => {
        console.log('then', stream);
        this.mediaStream = stream;
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();
      })
      .catch((err) => {
        console.log('Error accessing the camera with id', err);
        setTimeout(() => {
          this.startVideo(deviceId);
        }, 300);
      });
  }

  private getLastThreeLines(text: string): string {
    if (!text) return '';
    // Split the text by line breaks (supporting both Windows and Unix line endings)
    let lines = text.split(/\r?\n/);
    lines = lines.filter((line) => line !== '' || line.length > 5);

    // Get the last 3 lines
    let lastThreeLines =
      this.documentType === 'id' ? lines.slice(-3) : lines.slice(-2);
    lastThreeLines = this.adjustLinesToClosestNumber(
      lastThreeLines,
      this.acceptedLengths
    );

    // Join the lines back with line breaks
    return lastThreeLines.join('\n');
  }

  private adjustLinesToClosestNumber(
    lines: string[],
    numbers: number[]
  ): string[] {
    const firstLine = lines[0];

    const closestNumber = this.documentType === 'id' ? 30 : 44;

    const adjustedLines = lines.map((line) => {
      const lineLength = line.length;
      const difference = closestNumber - lineLength;

      if (difference > 0) {
        return line.padEnd(closestNumber, '<');
      } else if (difference < 0) {
        return line.slice(0, closestNumber);
      } else {
        return line;
      }
    });

    return adjustedLines;
  }

  private async getVideoDevices(): Promise<MediaDeviceInfo[]> {
    return (this.videoDevices = await this.scannerService
      .getVideoDevices(this.video, this.mediaStream)
      .catch(() => {
        return [];
      }));
  }

  private stopVideoStream(): void {
    this.scannerService.stopVideoStream(this.video, this.mediaStream);
  }

  public switchIdType(): void {
    this.documentType = this.documentType === 'id' ? 'passport' : 'id';
    this.drawOverlay();
  }
}
