import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { BookingService } from './booking.service';
import { RoomService } from './room.service';
import { ServiceService } from './service.service';
import { CalendarService } from './calendar.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private roomService: RoomService,
    private serviceService: ServiceService,
    private calendarService: CalendarService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd) // Only listen for NavigationEnd
      )
      .subscribe(() => {
        const segments = this.router.url
          .split('?')[0] // Ignore query params part
          .split('/')
          .filter((part) => part !== '');
        this.mainMenu = segments[1] ?? ''; // First path segment
        this.subMenu = segments[2] ?? ''; // Second path segment
        this.tertiaryMenu = segments[3] ?? ''; // Third path segment

        for (const key of Object.keys(this.route.snapshot.queryParams)) {
          this.handleParamChange(
            key,
            this.queryParams[key],
            this.route.snapshot.queryParams
          );
        }
        for (const [param, subject] of Object.entries(this.paramsMap)) {
          const paramValue = this.queryParams[param];
          if (paramValue && paramValue !== subject.value) {
            subject.next(paramValue);
          }
        }

        this.queryParams = this.route.snapshot.queryParams;
        this.queryParams$.next(this.queryParams);
      });
  }

  public mainMenu: string;
  public subMenu: string;
  public tertiaryMenu: string;
  public queryParams: { [key: string]: string } = {};
  public queryParams$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public activeBooking$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public activeRoom$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public activeHotel$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public activeService$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private paramsMap = {
    hotel: this.activeHotel$,
    booking: this.activeBooking$,
    room: this.activeRoom$,
    service: this.activeService$,
  };

  public absoluteNavigation(path: string): void {
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }

  public absoluteNavigationNoParamPreserve(
    path: string,
    queryParams: { [key: string]: string | number } = null
  ): void {
    this.router.navigate([path], { queryParams: queryParams });
  }

  public relativeNavigation(
    subMenu: string,
    tertiaryMenu: string = null
  ): void {
    const route = ['hu', this.mainMenu, subMenu];
    if (tertiaryMenu) route.push(tertiaryMenu);
    this.router.navigate(route, {
      queryParamsHandling: 'preserve',
    });
  }

  public addQueryParams(queryParams: { [key: string]: string | number }): void {
    setTimeout(() => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParams, // Your new query parameters
        queryParamsHandling: 'merge', // Keeps existing query parameters
      });
    }, 10);
  }

  public removeQueryParam(paramsToRemove: string[]): void {
    const queryParams = { ...this.route.snapshot.queryParams };
    paramsToRemove.forEach((param) => {
      delete queryParams[param];
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  private handleParamChange(
    key: string,
    oldValue: string,
    newParams: { [key: string]: string }
  ): void {
    switch (key) {
      case 'hotel':
        if (oldValue === newParams[key]) break;

        this.calendarService.setCalendarDays();
        this.bookingService.getBookings(newParams[key]);
        this.roomService.getRooms(newParams[key]);
        this.roomService.getBookingOOO().catch(() => {});
        this.roomService.getBookingOOS().catch(() => {});
        this.serviceService.getServices(newParams[key]);

        break;
      case 'booking':
        if (!newParams[key]) {
          this.bookingService.selectedBooking = null;
          this.bookingService.resetSelectedRooms();
          break;
        }
        if (oldValue === newParams[key]) break;

        this.bookingService
          .getBooking(this.queryParams['hotel'], newParams[key])
          .then(() => {
            this.bookingService.selectBooking(
              newParams[key],
              newParams['room']
            );

            this.bookingService.setSelectedBookingsRooms();
          });
        break;
      case 'room':
        if (!newParams[key]) {
          this.roomService.selectedRoom = null;
          break;
        }

        if (oldValue === newParams[key]) break;
        this.roomService.selectRoom(newParams[key]);
        break;
      case 'service':
        if (!newParams[key]) {
          this.serviceService.selectedService = null;
          break;
        }
        if (oldValue === newParams[key]) break;
        this.serviceService.selectService(newParams[key]);
        break;
      default:
        console.log(`No function for key: ${key}`);
    }
  }
}
