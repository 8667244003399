<div class="rooms-ooo-wrapper">
  <div class="header">
    <h1>Üzemen kívül helyezés</h1>
    <p>Szobák amiket nem lehet kiadni</p>
  </div>
  <div class="hint" *ngIf="!roomService.selectedRoom">
    <span>Válassza ki a szobát</span>
  </div>

  <div class="date-list-wrapper" *ngIf="roomService.selectedRoom">
    <div class="date-card" *ngFor="let ooo of roomService.roomOOO">
      <div class="date-range">
        <span class="date">{{ ooo.start_date }}.</span
        ><span class="hyphen">-</span
        ><span class="date">{{ ooo.end_date }}.</span>
      </div>
      <button (click)="deleteOOO(ooo.ooo_id)">
        <i class="delete-icon"></i>
      </button>
    </div>
    <div *ngIf="!roomService.roomOOO.length" class="hint-wrapper">
      <span>Nincsen üzemen kívüli időszak</span>
    </div>
  </div>
  <div class="add-wrapper" *ngIf="roomService.selectedRoom">
    <div class="date-range">
      <date-input
        [(ngModel)]="startDate"
        name="inputStartDate"
        [_value]="startDate"
        label="Kezdő dátum"
        [disabled]="false"
      ></date-input>

      <date-input
        [(ngModel)]="endDate"
        name="inputendDate"
        [_value]="endDate"
        label="Vég dátum"
      ></date-input>
    </div>
    <button
      class="primary-button"
      (click)="addOOO()"
      [disabled]="
        !startDate || !endDate || endDate?.getTime() - startDate?.getTime() < 0
      "
    >
      + Hozzáad
    </button>
  </div>
</div>
