<div class="booking-rooms-depart-wrapper" (click)="keepWindowOpen($event)">
  <div class="selector-header clickable">
    <button class="secondary-button" (click)="selectAll()">
      Összes kiválasztása
    </button>
  </div>
  <div class="selector-wrapper clickable">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of bookingService.sidePanelRooms | keyvalue"
        class="room-panel"
        [ngClass]="{
          success: bookingService.sidePanelRooms[room.key].departed,
          selected: roomsToDepart.includes(room.key),
          disabled:
            (!bookingService.sidePanelRooms[room.key].paid ||
              !bookingService.sidePanelRooms[room.key].arrived) &&
            !bookingService.sidePanelRooms[room.key].departed,
          selectable:
            bookingService.sidePanelRooms[room.key].paid &&
            !bookingService.sidePanelRooms[room.key].departed &&
            bookingService.sidePanelRooms[room.key].arrived
        }"
        (click)="
          !bookingService.sidePanelRooms[room.key].departed &&
          bookingService.sidePanelRooms[room.key].paid
            ? selectToDepart(room.key)
            : null
        "
      >
        <div class="room-name">
          <span>{{ roomService.getRoomName(room.key) }}</span>
        </div>

        <div class="status">
          <span>{{
            bookingService.sidePanelRooms[room.key] | bookingStatus : "hu"
          }}</span>
        </div>

        <div class="tooltip">
          <span
            *ngIf="
              !bookingService.sidePanelRooms[room.key].arrived &&
              !bookingService.sidePanelRooms[room.key].departed
            "
            >Még nem<br />érkezett meg</span
          >
          <span
            *ngIf="
              bookingService.sidePanelRooms[room.key].arrived &&
              !bookingService.sidePanelRooms[room.key].paid &&
              !bookingService.sidePanelRooms[room.key].departed
            "
            >Még nem<br />fizetett</span
          >
        </div>

        <div
          *ngIf="!bookingService.sidePanelRooms[room.key].departed"
          class="date-wrapper"
        >
          <div class="date">
            <span>{{
              bookingService.sidePanelRooms[room.key].arrival_date
            }}</span>
          </div>
          <span class="num-of-nights"
            >{{
              calendarService.calculateDaysDifference(
                bookingService.sidePanelRooms[room.key].arrival_date,
                bookingService.sidePanelRooms[room.key].departure_date
              )
            }}
            éj</span
          >

          <div class="date">
            <span>{{
              bookingService.sidePanelRooms[room.key].departure_date
            }}</span>
          </div>
        </div>
        <span
          *ngIf="bookingService.sidePanelRooms[room.key].arrived"
          class="success-message"
          >Eltávozott</span
        >
        <div class="details">
          <div class="occupancy-wrapper">
            <div class="occupancy-block">
              <div class="occupancy">
                <span>
                  {{ bookingService.sidePanelRooms[room.key].adults }}
                </span>
              </div>
              <div>
                <i class="adult-icon"></i>
              </div>
            </div>
            <div class="occupancy-block">
              <div class="occupancy">
                <span>
                  {{ bookingService.sidePanelRooms[room.key].children }}
                </span>
              </div>
              <div>
                <i class="child-icon"></i>
              </div>
            </div>
          </div>
          <div
            *ngIf="!bookingService.sidePanelRooms[room.key].departed"
            class="amount-block"
          >
            <input
              disabled
              class="amount"
              type="number"
              [(ngModel)]="bookingService.sidePanelRooms[room.key].gross_price"
            />
            <input type="text" class="huf-label" value="HUF" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="button-wrapper clickable">
    <button
      class="primary-button"
      (click)="departSelectedRooms()"
      [disabled]="!roomsToDepart.length"
    >
      <span *ngIf="!isLoading">Távoztatás</span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
