<div class="server-selector-wrapper">
  <p>Válasszon szervert</p>
  <select-input
    type="dropdown"
    class="form-dropdown-select"
    name="serverSelector"
    [(ngModel)]="selectedServer"
    (ngModelChange)="setSelectedServer()"
    [label]="'Szerver'"
    [options]="serverOptions"
  />
</div>
