import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  invoiceTypeOptions,
  nationalityOptions,
  taxObjectsOptions,
  taxZoneOptions,
} from 'src/app/@consts';
import { environment } from 'src/app/@environments/environtment';
import { ChargeInterface, InvoiceInterface } from 'src/app/@Interfaces';
import {
  BookingService,
  ConfigService,
  FocusService,
  HotelService,
} from 'src/app/@services';

@Component({
  selector: 'invoice',
  templateUrl: './invoice.dialog.html',
  styleUrls: ['./invoice.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDialog implements OnInit {
  constructor(
    private matDialogRef: MatDialogRef<InvoiceDialog>,
    @Inject(MAT_DIALOG_DATA) public charges: ChargeInterface[],
    public focusService: FocusService,
    public bookingService: BookingService,
    private http: HttpClient,
    private hotelService: HotelService,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService
  ) {}

  protected invoice: InvoiceInterface = {
    charges: [],
    e_invoice: false,
    prepayment_invoice: false,
    invoice_language: null,
    buyer: {
      name: null,
      country: null,
      postcode: null,
      city: null,
      address: null,
      tax_number: null,
      tax_subject: null,
      tax_zone: null,
      post_name: null,
      post_postcode: null,
      post_city: null,
      post_address: null,
      comment: null,
      issuer_name: null,
    },
    seller: {
      bank_name: null,
      bank_account_number: null,
      email_address: null,
      subject: null,
      issuer_name: null,
    },
  };
  protected readonly nationalityOptions = nationalityOptions;
  protected readonly invoiceTypeOptions = invoiceTypeOptions;
  protected readonly taxObjectOptions = taxObjectsOptions;
  protected readonly taxZoneOptions = taxZoneOptions;

  protected selectableCharges: { [key: number]: ChargeInterface[] } = {};
  protected selectedCharges: { [key: number]: ChargeInterface[] } = {};
  protected totalPrice: number = 0;
  protected isWorkRelated: boolean;

  ngOnInit(): void {
    this.setSelectableCharges();
    this.isWorkRelated = this.bookingService.selectedBooking.work_related;
    if (this.isWorkRelated) {
      this.invoice.buyer.name =
        this.bookingService.selectedBooking.company_name;
      this.invoice.buyer.tax_number =
        this.bookingService.selectedBooking.tax_number;
      this.invoice.buyer.tax_zone =
        this.bookingService.selectedBooking.tax_zone;
    }
  }

  public closeDialog(): void {
    this.matDialogRef.close(false);
  }

  protected createInvoice(): void {
    this.setInvoiceCharges();
    if (!this.invoice.buyer.tax_subject)
      this.invoice.buyer.tax_subject = 'INDIVIDUAL';

    this.http
      .post(
        `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/invoice`,
        this.invoice
      )
      .subscribe({
        next: () => {
          this.closeDialog();
        },
        error: (err) => {
          console.log('posting invoice err ', err);
        },
      });
  }

  protected selectRoom(room_id: string): void {
    const roomId: number = Number(room_id);
    if (this.selectedCharges[roomId]) {
      this.selectedCharges[roomId] = [
        ...this.selectedCharges[roomId],
        ...this.selectableCharges[roomId],
      ];
      delete this.selectableCharges[roomId];
    } else {
      this.selectedCharges[roomId] = this.selectableCharges[roomId];
      delete this.selectableCharges[roomId];
    }
    this.setTotalPrice();
  }

  protected removeRoom(room_id: string): void {
    const roomId: number = Number(room_id);
    if (this.selectableCharges[roomId]) {
      this.selectableCharges[roomId] = [
        ...this.selectableCharges[roomId],
        ...this.selectedCharges[roomId],
      ];
      delete this.selectedCharges[roomId];
    } else {
      this.selectableCharges[roomId] = this.selectedCharges[roomId];
      delete this.selectedCharges[roomId];
    }
    this.setTotalPrice();
  }

  protected selectCharge(charge: ChargeInterface): void {
    if (this.selectedCharges[charge.room_id]) {
      this.selectedCharges[charge.room_id].push(charge);
    } else {
      this.selectedCharges[charge.room_id] = [charge];
    }
    if (this.selectableCharges[charge.room_id].length === 1) {
      delete this.selectableCharges[charge.room_id];
    } else {
      const index = this.selectableCharges[charge.room_id].indexOf(charge);
      if (index > -1) this.selectableCharges[charge.room_id].splice(index, 1);
    }
    this.setTotalPrice();
  }

  protected removeCharge(charge: ChargeInterface): void {
    if (this.selectableCharges[charge.room_id]) {
      this.selectableCharges[charge.room_id].push(charge);
    } else {
      this.selectableCharges[charge.room_id] = [charge];
    }
    if (this.selectedCharges[charge.room_id].length === 1) {
      delete this.selectedCharges[charge.room_id];
    } else {
      const index = this.selectedCharges[charge.room_id].indexOf(charge);
      if (index > -1) this.selectedCharges[charge.room_id].splice(index, 1);
    }
    this.setTotalPrice();
  }

  protected anyChargeSelected(): number {
    return Object.keys(this.selectedCharges).length;
  }

  protected cdrDetection(): void {
    this.cdr.detectChanges();
  }

  private setSelectableCharges(): void {
    this.charges.map((charge) => {
      const ref = this.isWorkRelated
        ? this.selectedCharges
        : this.bookingService.selectedBooking.room_id === charge.room_id
        ? this.selectedCharges
        : this.selectableCharges;
      if (!ref[charge.room_id]) {
        ref[charge.room_id] = [charge];
      } else {
        ref[charge.room_id].push(charge);
      }
    });
  }

  private setInvoiceCharges(): void {
    for (const room in this.selectedCharges) {
      for (const charge of this.selectedCharges[room]) {
        this.invoice.charges.push(charge.charge_id);
      }
    }
  }

  private setTotalPrice(): void {
    this.totalPrice = 0;
    for (const room in this.selectedCharges) {
      for (const charge of this.selectedCharges[room]) {
        this.totalPrice += charge.gross_price;
      }
    }
  }
}
