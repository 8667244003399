import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { BookingDetailsInterface } from 'src/app/@Interfaces/booking.interface';
import { BookingService } from 'src/app/@services/booking.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import {
  chanelOptions,
  communicationLangOptions,
  nationalityOptions,
  sourceOptions,
  taxObjectsOptions,
  taxZoneOptions,
} from '../../../@consts';
import { HotelService } from 'src/app/@services';

@Component({
  selector: 'booking-edit-details',
  templateUrl: './booking-edit-details.component.html',
  styleUrls: ['./booking-edit-details.component.scss'],
})
export class BookingEditDetailsComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public roomService: RoomService,
    private hotelService: HotelService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;

  public bookingDetails: BookingDetailsInterface = {
    booking_number: null,
    channel: null,
    contact_email: null,
    contact_name: null,
    contact_phone: null,
    group_booking: false,
    language: null,
    nationality: null,
    source: null,
    work_related: false,
    external_id: null,
    company_name: null,
    tax_number: null,
    tax_zone: null,
    uuid: null,
  };
  private originalDetails: BookingDetailsInterface;

  public readonly chanelOptions = chanelOptions;
  public readonly sourceOptions = sourceOptions;
  public readonly communicationLangOptions = communicationLangOptions;
  public readonly hotelLanguages = this.hotelService.selectedHotel.languages;
  public readonly hotelCountries = this.hotelService.selectedHotel.countries;
  public readonly nationalityOptions = nationalityOptions;
  protected readonly taxZoneOptions = taxZoneOptions;
  protected readonly taxObjectOptions = taxObjectsOptions;

  ngOnInit(): void {
    this.bookingDetails = {
      booking_number: this.bookingService.selectedBooking.booking_number,
      channel: this.bookingService.selectedBooking.channel,
      contact_email: this.bookingService.selectedBooking.contact_email,
      contact_name: this.bookingService.selectedBooking.contact_name,
      contact_phone: this.bookingService.selectedBooking.contact_phone,
      group_booking: this.bookingService.selectedBooking.group_booking,
      language: this.bookingService.selectedBooking.language,
      source: this.bookingService.selectedBooking.source,
      work_related: this.bookingService.selectedBooking.work_related,
      external_id: this.bookingService.selectedBooking.external_id,
      nationality: this.bookingService.selectedBooking.nationality,
      company_name: this.bookingService.selectedBooking.company_name,
      tax_number: this.bookingService.selectedBooking.tax_number,
      tax_zone: this.bookingService.selectedBooking.tax_zone,
      uuid: this.bookingService.selectedBooking.uuid,
    };
    this.originalDetails = structuredClone(this.bookingDetails);
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  protected resetCompanyDetails(isNeeded: boolean): void {
    if (isNeeded) return;
    this.bookingDetails.tax_number = null;
    this.bookingDetails.tax_zone = null;
    this.bookingDetails.company_name = null;
  }

  public editBookingDetails(): void {
    this.isLoading = true;
    this.bookingService
      .putBookingDetails(
        this.bookingService.selectedBooking.booking_id,
        this.bookingDetails
      )
      .then(() => {
        this.isLoading = false;
        this.bookingService.saveBookingData(this.bookingDetails);
        this.bookingService.getBookings().catch(() => {});
        /* this.bookingService.updateBookingsData(
          this.calendarService.selectedBooking.booking_id
        ); */
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public setCommunicationLang(val: any): void {
    if (this.hotelService.selectedHotel.languages.includes(val)) {
      this.bookingDetails.language = val;
    } else if (this.hotelService.selectedHotel.languages.includes('EN')) {
      this.bookingDetails.language = 'EN';
    } else {
      this.bookingDetails.language = 'HU';
    }
  }

  public setBackOldForm(): void {
    this.bookingDetails = structuredClone(this.originalDetails);
  }

  public areObjectsEqual(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if values for each key are the same
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    // If all checks pass, the objects are equal
    return true;
  }
}
