export enum SidePanelTypeEnum {
  arrival = 'arrival',
  booking = 'booking',
  closed = 'closed',
  departure = 'departure',
  payment = 'payment',
  room = 'room',
  modifyBooking = 'modify-booking',
  modifyRooms = 'modify-rooms',
}

export enum RoomMenu {
  Overview = '',
  Add = 'add',
  Edit = 'edit',
  OutOfOrder = 'ooo',
  OutOfService = 'oos',
}

export enum ServiceMenu {
  Overview = '',
  Add = 'add',
  Edit = 'edit',
}

export enum ChargeTypes {
  ifa = 'IFA',
  room = 'ROOM',
  prepayment = 'PREPAYMENT',
  food = 'FOOD',
  drink = 'DRINK',
  other = 'OTHER',
  wellness = 'WELLNESS',
}
