import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  freeHelpPriorityOptions,
  helpPriorityOptions,
  helpSubjectOptions,
} from 'src/app/@consts';
import { SupportFeedbackDialog } from 'src/app/@dialogs/support-feedback/support-feedback.dialog';
import { environment } from 'src/app/@environments/environtment';
import { ConfigService, HotelService, RouterService } from 'src/app/@services';

@Component({
  selector: 'support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent {
  constructor(
    public hotelService: HotelService,
    private http: HttpClient,
    private matDialog: MatDialog,
    private configService: ConfigService,
    public routerService: RouterService
  ) {}

  protected subject: string;
  protected priority: string;
  protected message: string;
  protected environment: any = environment;

  public readonly helpSubjectOptions = helpSubjectOptions;
  public readonly helpPriorityOptions = helpPriorityOptions;
  public readonly freeHelpPriorityOptions = freeHelpPriorityOptions;

  protected sendTicket(): void {
    if (!this.hotelService.selectedHotel.premium) this.priority = 'low';

    this.http
      .post(
        `${this.configService.server}api/hotels/${this.hotelService.selectedHotel.hotel_id}/support`,
        {
          priority: this.priority,
          subject: this.subject,
          message: this.subject,
        }
      )
      .subscribe({
        next: () => {
          this.priority = null;
          this.subject = null;
          this.subject = null;
          this.matDialog.open(SupportFeedbackDialog);
        },
        error: () => {},
      });
  }
}
