import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'languageFilter',
})
export class LanguageFilterPipe implements PipeTransform {
  transform(
    options: {
      label: string;
      value?: any;
      selected?: boolean;
      disabled?: boolean;
    }[],
    preferences: any[]
  ): any[] {
    return options.filter(
      (option) => !option.value || preferences.includes(option.value) // Include if value is missing or matches preferences
    );
  }
}
