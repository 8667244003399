import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotelService } from './hotel.service';
import { BookingService } from './booking.service';
import { GuestInterface } from '../@Interfaces/guest.interface';
import { environment } from '../@environments/environtment';
import { ConfigService } from './config.service';
import { RoomService } from './room.service';

@Injectable({
  providedIn: 'root',
})
export class GuestService {
  constructor(
    private http: HttpClient,
    private hotelService: HotelService,
    private bookingService: BookingService,
    private configService: ConfigService,
    private roomService: RoomService
  ) {}

  public readQrCode: any;
  public totalGuestsToScan: number;

  /* <-- API CALLS --> */

  public async getGuests(
    hotelId: string | number = null,
    bookingId: string | number = null
  ): Promise<GuestInterface[]> {
    const hotel_id = hotelId ?? this.hotelService.selectedHotelId;
    const booking_id =
      bookingId ?? this.bookingService?.selectedBooking?.booking_id;

    return new Promise((resolve, reject) => {
      this.http
        .get<GuestInterface[]>(
          `${this.configService.server}api/hotels/${hotel_id}/bookings/${booking_id}/guests`
        )
        .subscribe({
          next: (guests: GuestInterface[]) => {
            resolve(guests);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async addGuests(
    guests: GuestInterface[],
    roomId: number
  ): Promise<GuestInterface[]> {
    const modifiedGuests = guests.map((guest) => {
      delete guest.room_id;
      delete guest.guest_id;
      delete guest.guest_arrival_id;
      return guest;
    });
    const reqBody = {
      room_id: roomId,
      guests: modifiedGuests,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post<GuestInterface[]>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/guests`,
          reqBody
        )
        .subscribe({
          next: (guests: GuestInterface[]) => {
            resolve(guests);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async arrive(roomId: number): Promise<GuestInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post<GuestInterface[]>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/rooms/${roomId}/arrive`,
          {}
        )
        .subscribe({
          next: (guests: GuestInterface[]) => {
            resolve(guests);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async editGuest(guest: GuestInterface): Promise<GuestInterface> {
    const requestBody = structuredClone(guest);
    delete requestBody.birth_date_og;
    delete requestBody.gender_og;
    delete requestBody.last_name_og;
    delete requestBody.first_name_og;
    delete requestBody.nationality_og;
    delete requestBody.document_type_og;
    delete requestBody.document_number_og;
    delete requestBody.room_id;
    delete requestBody.guest_arrival_id;
    return new Promise((resolve, reject) => {
      this.http
        .put<GuestInterface>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/guests`,
          requestBody
        )
        .subscribe({
          next: (guests: GuestInterface) => {
            resolve(guests);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async sendScan(ids: any, scan: any): Promise<void> {
    console.log('posting scan ', this.configService.server, ids, scan);
    const bookingId =
      ids?.bookingId ?? this.bookingService.selectedBooking.booking_id;
    const hotelId = ids?.hotelId ?? this.hotelService.selectedHotelId;
    const roomId = ids?.roomId ?? this.roomService.selectedRoom.room_id;

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${this.configService.server}api/hotels/${hotelId}/bookings/${bookingId}/rooms/${roomId}/scan`,
          scan
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
  /* <-- API CALLS --> */
}
