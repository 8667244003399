import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HotelService } from './hotel.service';
import { environment } from '../@environments/environtment';
import { AuthService } from './auth.service';
import { SnackbarService } from './snackbar.service';
import { DateService } from './date.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  constructor(
    private http: HttpClient,
    private hotelService: HotelService,
    private authService: AuthService,
    private snackBarService: SnackbarService,
    private dateService: DateService,
    private configService: ConfigService
  ) {}

  private holidays: string[] = [];

  private defaultHolidays: string[] = [
    '2024-01-01',
    '2024-03-15',
    '2024-03-29',
    '2024-03-31',
    '2024-04-01',
    '2024-05-01',
    '2024-05-19',
    '2024-05-20',
    '2024-08-20',
    '2024-10-23',
    '2024-11-01',
    '2024-12-24',
    '2024-12-25',
    '2024-12-26',
    // 2025
    '2025-01-01',
    '2025-03-15',
    '2025-04-20',
    '2025-04-21',
    '2025-05-01',
    '2025-06-08',
    '2025-06-09',
    '2025-08-20',
    '2025-10-23',
    '2025-11-01',
    '2025-12-24',
    '2025-12-25',
    '2025-12-26',
    // 2026
    '2026-01-01',
    '2026-03-15',
    '2026-04-03',
    '2026-04-05',
    '2026-04-06',
    '2026-05-01',
    '2026-05-24',
    '2026-05-25',
    '2026-08-20',
    '2026-10-23',
    '2026-11-01',
    '2026-12-24',
    '2026-12-25',
    '2026-12-26',
    // 2027
    '2027-01-01',
    '2027-03-15',
    '2027-03-26',
    '2027-03-29',
    '2027-05-01',
    '2027-05-17',
    '2027-08-20',
    '2027-10-23',
    '2027-11-01',
    '2027-12-24',
    '2027-12-25',
    '2027-12-26',
  ];

  /* <-- API CALLS --> */

  public getHolidays(): void {
    this.http
      .get<{ holiday_dates: string[] }>(
        `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/custom-holiday`
      )
      .subscribe({
        next: (res: { holiday_dates: string[] }) => {
          this.holidays = [...res.holiday_dates, ...this.defaultHolidays];
        },
        error: () => {},
      });
  }

  public addHoliday(date: string): void {
    this.http
      .put(
        `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/custom-holiday/${date}`,
        {}
      )
      .subscribe({
        next: () => {
          this.getHolidays();
        },
        error: () => {},
      });
  }
  public removeHoliday(date: string): void {
    this.http
      .delete(
        `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/custom-holiday/${date}`,
        {}
      )
      .subscribe({
        next: () => {
          this.getHolidays();
        },
        error: () => {},
      });
  }

  /* <-- API CALLS --> */

  public toggleHoliday(date: Date): void {
    if (!this.hotelService.selectedHotel.premium) {
      this.snackBarService.openPremiumRequired();
      return;
    }
    const formatedDate = this.dateService.formatDate(date);
    console.log(formatedDate);

    if (this.defaultHolidays.includes(formatedDate)) {
      return;
    }
    if (!this.holidays.includes(formatedDate)) {
      this.addHoliday(formatedDate);
    } else {
      this.removeHoliday(formatedDate);
    }
  }

  public isHoliday(d: Date): boolean {
    const dateString = this.dateService.formatDate(d);
    return this.holidays.includes(dateString);
  }
}
