import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SidePanelTypeEnum } from 'src/app/enums';
import { BookingDeleteDialog } from 'src/app/@dialogs/booking-delete/booking-delete.dialog';
import { CloseDayDialog } from 'src/app/@dialogs/close-day/close-day.dialog';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { DateService } from 'src/app/@services/date.service';
import { RouterService } from 'src/app/@services';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  constructor(
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private matDialog: MatDialog,
    public hotelService: HotelService,
    private tableSErvice: TableService,
    public dateService: DateService,
    private cdr: ChangeDetectorRef,
    public routerService: RouterService
  ) {}

  public hotel: string;
  public userName: string;
  public daysToDisplay: number = 5;
  public sidePanelType: SidePanelTypeEnum = SidePanelTypeEnum.closed;
  public sidePanelEnum = SidePanelTypeEnum;
  public deletableBooking: boolean = true;

  ngOnInit(): void {
    this.getDateList();
    // TODO: set deletableBooking to false if any of the rooms have arrived
  }

  ngOnDestroy(): void {
    //this.bookingService.closeBooking();
    this.calendarService.removeAvailableDayClasses();
  }

  public onDateChange(): void {
    if (!this.hotelService.selectedHotelId) return;

    this.calendarService.setCalendarDays();
    this.bookingService
      .getBookings(this.hotelService.selectedHotelId.toString())
      .catch(() => {});
  }

  public openSidePanel(panel: SidePanelTypeEnum, event: Event): void {
    event.stopPropagation();
    this.sidePanelType = panel;
    this.routerService.relativeNavigation(panel);
  }

  public getDateList(): void {
    this.calendarService.setCalendarDays();
  }

  public closeLowerMenu(): void {
    this.routerService.absoluteNavigationNoParamPreserve('hu/calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
  }

  public deleteBooking(event: Event): void {
    event.stopPropagation();
    this.matDialog
      .open(BookingDeleteDialog)
      .afterClosed()
      .subscribe((confirmation) => {
        if (!confirmation) {
          return;
        }
        this.routerService.removeQueryParam(['booking', 'room']);
        this.closeLowerMenu();
        this.bookingService.selectedBooking = null;
        this.cdr.detectChanges();
      });
  }

  public openCloseDayDialog(): void {
    this.matDialog
      .open(CloseDayDialog)
      .afterClosed()
      .subscribe((confirmation) => {
        if (!confirmation) {
          return;
        }

        this.hotelService.concludeDay().catch(() => {});
      });
  }

  public closeSidePanel(): void {
    this.tableSErvice.clearRoomBookings();
    this.calendarService.closeAction();
    this.calendarService.removeAvailableDayClasses();
    this.bookingService.resetSelectedRooms();
    this.routerService.absoluteNavigationNoParamPreserve('hu/calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
  }

  public getTotalBookinData(bookingId: number): {
    adults: number;
    children: number;
    rooms: number;
  } {
    let adults: number = 0;
    let children: number = 0;
    let rooms: number = 0;

    for (const booking of this.bookingService.bookings) {
      if (bookingId === booking.booking_id) {
        (adults += booking.adults), (children += booking.children), rooms++;
      }
    }
    return {
      adults,
      children,
      rooms,
    };
  }

  public nextWeek(): void {
    const newDate = this.calendarService.inputStartDate.getDate() + 5;
    this.calendarService.inputStartDate = new Date(new Date().setDate(newDate));
    this.onDateChange();
    // TODO: update calendar picker displayed
  }

  public previousWeek(): void {
    const newDate = this.calendarService.inputStartDate.getDate() - 5;
    this.calendarService.inputStartDate = new Date(new Date().setDate(newDate));
    this.onDateChange();
    // TODO: update calendar picker displayed
  }
}
