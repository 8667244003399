<div class="room-add-wrapper">
  <div class="header">
    <h1>Hozzáadás</h1>
    <p>
      Még
      <span>{{
        hotelService.selectedHotel.room_limit - roomService.rooms.length
      }}</span>
      szoba hiányzik
    </p>
  </div>
  <div class="form-wrapper">
    <form #roomForm="ngForm">
      <text-input
        [type]="'text'"
        name="name"
        [required]="true"
        [(ngModel)]="room.name"
        [label]="'Szoba neve*'"
        [maxlength]="32"
      />
      <text-input
        [type]="'number'"
        name="room_number"
        [required]="true"
        [(ngModel)]="room.room_number"
        [label]="'Szobaszám*'"
      />
      <select-input
        id="type"
        name="type"
        [required]="true"
        [(ngModel)]="room.type"
        [options]="roomTypeOptions"
        [label]="'Szoba típusa*'"
      />
      <text-input
        [type]="'text'"
        name="building"
        [required]="true"
        [(ngModel)]="room.building"
        [label]="'Épület* (pl.: Főépület)'"
        [maxlength]="64"
      />
      <select-input
        id="floor"
        name="floor"
        [required]="true"
        [(ngModel)]="room.floor"
        [options]="floorOptions"
        [label]="'Emelet*'"
      />
      <text-input
        [type]="'number'"
        name="single_beds"
        [required]="true"
        [(ngModel)]="room.single_beds"
        [label]="'Szimpla ágyak száma*'"
      />
      <text-input
        [type]="'number'"
        name="double_beds"
        [required]="true"
        [(ngModel)]="room.double_beds"
        [label]="'Franciaágyak száma*'"
      />
      <text-input
        [type]="'number'"
        name="extra_beds"
        [required]="true"
        [(ngModel)]="room.extra_beds"
        [label]="'Pótágyak száma*'"
      />
      <text-input
        [type]="'number'"
        name="couch_beds"
        [required]="true"
        [(ngModel)]="room.couch_beds"
        [label]="'Fotelágyak száma*'"
      />
      <text-input
        [type]="'number'"
        name="max_adults"
        [required]="true"
        [(ngModel)]="room.max_adults"
        [label]="'Max felnőttek száma*'"
      />
      <text-input
        [type]="'number'"
        id="max_family_adults"
        name="max_family_adults"
        [required]="true"
        [(ngModel)]="room.max_family_adults"
        [label]="'Max családos felnőttek száma*'"
      />
      <text-input
        [type]="'number'"
        name="max_family_children"
        [required]="true"
        [(ngModel)]="room.max_family_children"
        [label]="'Max családos gyerekek száma*'"
      />
      <div class="row-wrapper">
        <text-input
          [type]="'number'"
          name="default_price_huf"
          [required]="true"
          [(ngModel)]="room.default_price_huf"
          [label]="'Szobaár HUF*'"
        />
        <checkbox-input
          id="ifa_included"
          name="ifa_included"
          [name]="'ifa_included'"
          [label]="'tartalmazza az IFÁ-t'"
          [(ngModel)]="room.ifa_included"
        />
      </div>
      <checkbox-input
        id="air_conditioning"
        name="air_conditioning"
        [name]="'air_conditioning'"
        [label]="'Légkondícionált'"
        [(ngModel)]="room.air_conditioning"
      />
      <checkbox-input
        id="wheelchair_accessible"
        name="wheelchair_accessible"
        [name]="'wheelchair_accessible'"
        [label]="'Mozgássérült barát'"
        [(ngModel)]="room.wheelchair_accessible"
      />
    </form>
  </div>
  <div class="button-wrapper">
    <button
      class="primary-button"
      type="submit"
      [disabled]="roomForm.invalid"
      (click)="newRoom()"
    >
      + Hozzáadás
    </button>
  </div>
</div>
