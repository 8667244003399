import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/@services/auth.service';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Capacitor } from '@capacitor/core';
import { RouterService } from 'src/app/@services';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private hotelService: HotelService,
    private bookingService: BookingService,
    private calendarService: CalendarService,
    private roomService: RoomService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    if (this.authService.IsAuthenticated) {
      this.routerService.absoluteNavigation('hu/calendar');
    }
    if (Capacitor.isNativePlatform()) this.loadCredentials();
  }

  public loginGroup = {
    username: '',
    password: '',
  };
  public rememberMe: boolean;
  public isLoading: boolean;

  public signIn(): void {
    this.isLoading = true;
    this.authService
      .signIn(this.loginGroup)
      .then(() => {
        this.isLoading = false;
        this.authService.IsAuthenticated = true;
        if (Capacitor.isNativePlatform() && this.rememberMe) {
          this.saveCredentials(
            this.loginGroup.username,
            this.loginGroup.password
          );
        }

        this.hotelService
          .getHotels()
          .then(() => {
            this.hotelService.selectHotel(this.hotelService.hotels[0]);
          })
          .catch(() => {});
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  private saveCredentials(email: string, pw: string): void {
    SecureStoragePlugin.set({ key: 'email', value: email });
    SecureStoragePlugin.set({ key: 'password', value: pw });
  }

  private async loadCredentials(): Promise<void> {
    this.loginGroup.username = (
      await SecureStoragePlugin.get({ key: 'email' }).catch(() => {
        return null;
      })
    ).value;
    this.loginGroup.password = (
      await SecureStoragePlugin.get({ key: 'password' }).catch(() => {
        return null;
      })
    ).value;
  }
}
