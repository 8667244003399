<div class="booking-details-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-name clickable">
    <h1>Foglalás adatai</h1>
  </div>
  <div class="form-wrapper clickable">
    <form #bookingForm="ngForm">
      <div class="input-wrapper">
        <text-input
          name="booking_number"
          [(ngModel)]="bookingDetails.booking_number"
          [required]="true"
          [label]="'Foglalás száma'"
          [disabled]="true"
        />
      </div>
      <div class="input-wrapper">
        <text-input
          name="booking_uuid"
          [(ngModel)]="bookingDetails.uuid"
          [required]="true"
          [label]="'Check-in azonosító'"
          [disabled]="true"
        />
      </div>
      <div class="input-wrapper">
        <text-input
          name="contact_name"
          [(ngModel)]="bookingDetails.contact_name"
          [required]="true"
          [label]="'Név*'"
          [maxlength]="32"
        />
      </div>
      <div class="input-wrapper">
        <select-input
          id="nationality"
          name="nationality"
          [required]="true"
          [(ngModel)]="bookingDetails.nationality"
          [options]="nationalityOptions | countryFilter : hotelCountries"
          (ngModelChange)="setCommunicationLang($event)"
          [label]="'Nemzetiség*'"
        />
      </div>

      <div class="input-wrapper">
        <select-input
          id="language"
          name="language"
          [required]="true"
          [(ngModel)]="bookingDetails.language"
          [options]="communicationLangOptions | languageFilter : hotelLanguages"
          [label]="'Kommunikációs nyelv*'"
        />
      </div>

      <div class="input-wrapper">
        <select-input
          id="channel"
          name="channel"
          [required]="true"
          [(ngModel)]="bookingDetails.channel"
          [options]="chanelOptions"
          [label]="'Csatorna*'"
        />
      </div>

      <div class="input-wrapper">
        <select-input
          *ngIf="bookingChanel === 'KOZVETITO_ONLINE'"
          id="source"
          name="source"
          [(ngModel)]="bookingDetails.source"
          [options]="sourceOptions"
          [label]="'Platform'"
        />
      </div>

      <div class="input-wrapper">
        <text-input
          *ngIf="bookingChanel === 'KOZVETITO_ONLINE'"
          name="external_id"
          [(ngModel)]="bookingDetails.external_id"
          [type]="'text'"
          [label]="'id'"
          [maxlength]="16"
        />
      </div>

      <div class="input-wrapper">
        <text-input
          name="contact_email"
          [(ngModel)]="bookingDetails.contact_email"
          [type]="'email'"
          [label]="'Email'"
          [maxlength]="64"
        />
      </div>

      <div class="input-wrapper">
        <text-input
          name="contact_phone"
          [(ngModel)]="bookingDetails.contact_phone"
          [type]="'text'"
          [label]="'Telefon'"
          [maxlength]="12"
        />
      </div>

      <div class="input-wrapper">
        <div class="checkbox-wrapper">
          <checkbox-input
            id="business"
            name="work_related"
            [name]="'work_related'"
            [label]="'Üzleti'"
            [(ngModel)]="bookingDetails.work_related"
            (ngModelChange)="resetCompanyDetails($event)"
          />
          <checkbox-input
            id="group"
            name="group_booking"
            [name]="'group_booking'"
            [label]="'Csoportos'"
            [(ngModel)]="bookingDetails.group_booking"
          />
        </div>
      </div>

      <div class="input-wrapper">
        <text-input
          *ngIf="bookingDetails.work_related === true"
          name="company_name"
          [(ngModel)]="bookingDetails.company_name"
          [type]="'text'"
          [label]="'cég neve'"
          [maxlength]="32"
        />
      </div>

      <div class="input-wrapper">
        <text-input
          *ngIf="bookingDetails.work_related === true"
          name="tax_number"
          [(ngModel)]="bookingDetails.tax_number"
          [type]="'text'"
          [label]="'cég adószáma'"
          [maxlength]="12"
        />
      </div>
      <div class="input-wrapper">
        <select-input
          name="country"
          [(ngModel)]="bookingDetails.tax_zone"
          [options]="taxZoneOptions"
          [label]="'Adózási zóna'"
          *ngIf="bookingDetails.work_related"
        ></select-input>
      </div>
    </form>
  </div>
  <div class="button-wrapper clickable">
    <button class="secondary-button" (click)="setBackOldForm()" type="button">
      Mégse
    </button>

    <button
      class="primary-button"
      type="submit"
      [disabled]="
        bookingForm.invalid ||
        areObjectsEqual(bookingForm.value, bookingService.bookingData)
      "
      (click)="editBookingDetails()"
    >
      <span *ngIf="!isLoading">Módosítás </span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
