import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { RoomInterface } from 'src/app/@Interfaces';
import { RouterService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { RoomMenu } from 'src/app/enums';

@Component({
  selector: 'rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
})
export class RoomsComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    private formBuilder: FormBuilder,
    public routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.createEmptyForm();
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  public form: FormGroup;
  public roomLimit: number;
  public dynamicThe: string = 'a(z)';
  public roomGroup: any;
  public floors = [-1, 0, 1, 2];
  public roomMenu = RoomMenu;
  public selectedRoom: RoomInterface;
  public startDate: Date;
  public endDate: Date;

  public selectRoom(room: RoomInterface): void {
    this.selectedRoom = room;
  }

  public deselectRoom(): void {
    this.selectedRoom = null;
  }

  public cancel(): void {
    this.createEmptyForm();
  }

  public selectMenu(menu: RoomMenu): void {
    this.roomService.roomOOO = [];
    this.roomService.roomOOS = [];
    this.startDate = null;
    this.endDate = null;
    this.selectedRoom = null;
    this.routerService.relativeNavigation('rooms', menu);
    setTimeout(() => {
      if (menu === '') this.routerService.removeQueryParam(['room']);
    }, 10);
  }

  private createEmptyForm(): void {
    this.roomGroup = this.formBuilder.group({
      floor: null,
      room_id: new FormControl(),
      name: new FormControl(),
      type: new FormControl(),
      building: new FormControl(),

      single_beds: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      double_beds: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      extra_beds: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      couch_beds: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      air_conditioning: new FormControl(false),
      wheelchair_accessible: new FormControl(false),
      max_adults: [
        null,
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
      max_family_adults: [
        null,
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
      max_family_children: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
    });
  }
}
