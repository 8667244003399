import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotelService } from 'src/app/@services/hotel.service';

@Component({
  selector: 'close-day',
  templateUrl: './close-day.dialog.html',
  styleUrls: ['./close-day.dialog.scss'],
})
export class CloseDayDialog {
  constructor(
    private matDialogRef: MatDialogRef<CloseDayDialog>,
    public hotelService: HotelService
  ) {}

  public continue(): void {
    this.matDialogRef.close(true);
  }

  public cancel(): void {
    this.matDialogRef.close(false);
  }
}
