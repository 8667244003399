<div class="selection-wrapper">
  <div class="header">
    <h1>Szolgáltatások</h1>
    <p *ngIf="hotelService.selectedHotel">
      Itt kezelheti {{ dynamicThe }} {{ hotelService.selectedHotel.name }} által
      nyújtott szolgáltatásokat.
    </p>
  </div>
  <div class="services-wrapper">
    <div class="service-list" *ngIf="serviceService.services">
      <div
        class="service-card"
        *ngFor="let service of serviceService.services"
        [ngClass]="[
          routerService.tertiaryMenu !== serviceMenu.Add &&
          routerService.tertiaryMenu !== serviceMenu.Overview
            ? 'selectable'
            : '',
          service === serviceService.selectedService ? 'active' : ''
        ]"
        (click)="selectService(service)"
      >
        <div class="service-category">
          <i *ngIf="service.category === 'FOOD'" class="food-icon"></i>
          <i *ngIf="service.category === 'DRINK'" class="drink-icon"></i>
          <i *ngIf="service.category === 'WELLNESS'" class="wellness-icon"></i>
          <i
            *ngIf="service.category === 'OTHER'"
            class="other-service-icon"
          ></i>
        </div>
        <div class="service-name">
          <span>{{ service.name }}</span>
        </div>
        <div class="service-price">
          <span>{{ service.price_huf }} Ft</span>
        </div>
        <div class="vat-rate">
          <span>ÁFA {{ service.vat_rate }}%</span>
        </div>
        <div class="delete">
          <button type="button" (click)="deleteService(service)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
