import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CheckInService, RouterService } from 'src/app/@services';

@Component({
  selector: 'check-in-code',
  templateUrl: './check-in-code.dialog.html',
  styleUrls: ['./check-in-code.dialog.scss'],
})
export class CheckInCodeDialog {
  constructor(
    private routerService: RouterService,
    private matDialogRef: MatDialogRef<CheckInCodeDialog>,
    private checkInService: CheckInService
  ) {}

  protected code: string = 'f2a7fb2c-e17b-46ed-9ae0-a11b79aaca10';
  protected error: boolean;

  protected checkCode(): void {
    this.matDialogRef.close();
    this.checkInService
      .getCheckInDetails(this.code)
      .then(() => {
        this.checkInService.checkInId = this.code;
        this.routerService.absoluteNavigation('hu/self-check-in');
      })
      .catch(() => {
        this.error = true;
        console.log('no check in found');
      });
  }
}
