<div class="booking-rooms-payment-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-header">
    <button
      class="secondary-button"
      type="button"
      (click)="openInvoiceDialog()"
    >
      + Új számla
    </button>
  </div>

  <div class="action-panel">
    <div class="panel-wrapper">
      <div class="action-panel-header">
        <div class="paid-cost">
          <span class="description">Fizetve</span
          ><span class="cost">{{ paidAmount }} Ft</span>
        </div>
        <div class="room" *ngIf="!areAllRoomsSelected && selectedRoom">
          {{ roomService.getRoomName(selectedRoom) }}
        </div>
        <div class="room" *ngIf="areAllRoomsSelected">Összes szoba</div>
        <div class="remaining-cost">
          <span class="description">Fennmaradó</span
          ><span class="cost">{{ remainingAmount }} Ft</span>
        </div>
        <div class="total-cost">
          <span class="description">Össz </span
          ><span class="cost">{{ totalPrice }} Ft</span>
        </div>
        <div class="group-action-panel">
          <span>Tömeges műveletek</span>
          <form
            class="group-actions"
            #groupForm="ngForm"
            (ngSubmit)="payAllCharges(groupForm.value)"
          >
            <select-input
              id="group_payment_method"
              name="group_payment_method"
              [required]="true"
              [(ngModel)]="groupForm.value.group_payment_method"
              [options]="paymentOptions"
              (ngModelChange)="setAllPaymentMethod($event)"
            ></select-input>
            <button
              class="secondary-button"
              [disabled]="groupForm.invalid"
              type="submit"
            >
              Összes kifizetése
            </button>
            <div class="form-textbox">
              <date-input
                [(ngModel)]="groupForm.value.group_payment_date"
                name="group_payment_date"
                [required]="true"
                [label]="'Fizetés napja*'"
                [isDateString]="true"
                (ngModelChange)="setAllPaymentDate($event)"
              ></date-input>
            </div>
          </form>
        </div>
      </div>

      <div class="charges-wrapper">
        <ng-container *ngFor="let charge of charges">
          <charge-card
            *ngIf="!charge.charge_id || charge.room_id === selectedRoom"
            [charge]="charge"
            (refreshData)="refreshData()"
          ></charge-card>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="action-footer">
    <button class="secondary-button" (click)="addCharge()">+ Új tétel</button>
  </div>

  <div class="selector-header">
    <button
      class="secondary-button"
      (click)="selectAllRooms()"
      *ngIf="!areAllRoomsSelected"
    >
      Összes kiválasztása
    </button>
    <button
      class="secondary-button"
      (click)="deselectAllRooms()"
      *ngIf="areAllRoomsSelected"
    >
      Mégse
    </button>
  </div>

  <div class="selector-wrapper">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of bookingService.sidePanelRooms | keyvalue"
        class="room-panel"
        [ngClass]="{
          success: bookingService.sidePanelRooms[room.key].paid,
          selected: room.key === selectedRoom?.toString() || areAllRoomsSelected,
        }"
        (click)="selectRoom(room.key)"
      >
        <div class="room-name">
          <span>{{ roomService.getRoomName(room.key) }}</span>
        </div>

        <div class="details">
          <span
            class="tooltip"
            *ngIf="bookingService.sidePanelRooms[room.key].paid"
            >Fizetve</span
          >
          <span
            class="tooltip"
            *ngIf="!bookingService.sidePanelRooms[room.key].paid"
            >Fennmaradó összeg</span
          >
          <div
            class="amount-block"
            *ngIf="!bookingService.sidePanelRooms[room.key].paid"
          >
            <span>{{
              bookingService.sidePanelRooms[room.key].remainingAmount
            }}</span
            ><span> Ft</span>
          </div>
        </div>
        <div class="occupancy-wrapper">
          <div class="occupancy-block">
            <div class="occupancy">
              <span>
                {{ bookingService.sidePanelRooms[room.key].adults }}
              </span>
            </div>
            <div>
              <i class="adult-icon"></i>
            </div>
          </div>
          <div class="occupancy-block">
            <div class="occupancy">
              <span>
                {{ bookingService.sidePanelRooms[room.key].children }}
              </span>
            </div>
            <div>
              <i class="child-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="selector-footer"></div>
</div>
