import { Component, OnInit } from '@angular/core';
import { nationalityOptions } from 'src/app/@consts';
import { FocusService, HotelService } from 'src/app/@services';

@Component({
  selector: 'admin-hotel',
  templateUrl: './admin-hotel.component.html',
  styleUrls: ['./admin-hotel.component.scss'],
})
export class AdminHotelComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public focusService: FocusService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
      this.name = this.hotelService.selectedHotel.name;
    }
  }

  public dynamicThe: string = 'a(z)';
  public name: string = 'Hotel';
  protected communicationLanguages: any[] = [
    { label: 'angol', value: 'EN' },
    { label: 'francia', value: 'FR' },
    { label: 'kínai', value: 'CN' },
    { label: 'lengyel', value: 'PL' },
    { label: 'magyar', value: 'HU' },
    { label: 'német', value: 'DE' },
    { label: 'orosz', value: 'RU' },
    { label: 'spanyol', value: 'ES' },
  ];

  protected countries: any[] = [
    { value: 'HU', label: 'Magyarország' },
    // Europe
    { value: 'AL', label: 'Albánia' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AT', label: 'Ausztria' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BA', label: 'Bosznia-Hercegovina' },
    { value: 'BG', label: 'Bulgária' },
    { value: 'CY', label: 'Ciprus' },
    { value: 'CZ', label: 'Csehország' },
    { value: 'DK', label: 'Dánia' },
    { value: 'GB', label: 'Egyesült Királyság' },
    { value: 'EE', label: 'Észtország' },
    { value: 'BY', label: 'Fehéroroszország' },
    { value: 'FI', label: 'Finnország' },
    { value: 'FR', label: 'Franciaország' },
    { value: 'GR', label: 'Görögország' },
    { value: 'GL', label: 'Grönland' },
    { value: 'NL', label: 'Hollandia' },
    { value: 'HR', label: 'Horvátország' },
    { value: 'IE', label: 'Írország' },
    { value: 'IS', label: 'Izland' },
    { value: 'PL', label: 'Lengyelország' },
    { value: 'LV', label: 'Lettország' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Litvánia' },
    { value: 'LU', label: 'Luxemburg' },
    { value: 'MK', label: 'Macedónia' },
    { value: 'MT', label: 'Málta' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'DE', label: 'Németország' },
    { value: 'NO', label: 'Norvégia' },
    { value: 'IT', label: 'Olaszország' },
    { value: 'RU', label: 'Oroszország' },
    { value: 'AM', label: 'Örményország' },
    { value: 'PT', label: 'Portugália' },
    { value: 'RO', label: 'Románia' },
    { value: 'ES', label: 'Spanyolország' },
    { value: 'CH', label: 'Svájc' },
    { value: 'SE', label: 'Svédország' },
    { value: 'SK', label: 'Szlovákia' },
    { value: 'SI', label: 'Szlovénia' },
    { value: 'UA', label: 'Ukrajna' },
    { value: 'VA', label: 'Vatikán' },

    // Rest
    { value: 'AF', label: 'Afganisztán' },
    { value: 'AX', label: 'Aland' },
    { value: 'DZ', label: 'Algéria' },
    { value: 'UM', label: 'USA Amerikai Csendes-óceáni szigetek' },
    { value: 'US', label: 'Amerikai Egyesült Államok' },
    { value: 'AS', label: 'Amerikai Szamoa' },
    { value: 'VI', label: 'Amerikai Virgin-szigetek' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarktisz' },
    { value: 'AG', label: 'Antigua és Barbuda' },
    { value: 'AR', label: 'Argentína' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Ausztrália' },
    { value: 'AZ', label: 'Azerbajdzsán' },
    { value: 'BS', label: 'Bahama-szigetek' },
    { value: 'BH', label: 'Bahrein' },
    { value: 'BD', label: 'Banglades' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhután' },
    { value: 'GW', label: 'Bissau-Guinea' },
    { value: 'BO', label: 'Bolívia' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Norvégia Bouvet-sziget' },
    { value: 'BR', label: 'Brazília' },
    { value: 'IO', label: 'Brit Indiai-óceáni Terület' },
    { value: 'VG', label: 'Brit Virgin-szigetek' },
    { value: 'BN', label: 'Brunei' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'CL', label: 'Chile' },
    { value: 'KM', label: 'Comore-szigetek' },
    { value: 'CK', label: 'Cook-szigetek' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'TD', label: 'Csád' },
    { value: 'ZA', label: 'Dél-afrikai Köztársaság' },
    { value: 'GS', label: 'Déli-Georgia és Déli-Sandwich-szigetek' },
    { value: 'SS', label: 'Dél-Szudán' },
    { value: 'KR', label: 'Dél-Korea (Koreai Köztársaság)' },
    { value: 'DM', label: 'Dominikai Közösség' },
    { value: 'DO', label: 'Dominikai Köztársaság' },
    { value: 'DJ', label: 'Dzsibuti' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'GQ', label: 'Egyenlítői-Guinea' },
    { value: 'AE', label: 'Egyesült Arab Emírségek' },
    { value: 'EG', label: 'Egyiptom' },
    { value: 'CI', label: 'Elefántcsontpart' },
    { value: 'SV', label: 'Salvador' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'MP', label: 'Északi-Mariana-szigetek' },
    { value: 'KP', label: 'Észak-Korea (Koreai NDK)' },
    { value: 'ET', label: 'Etiópia' },
    { value: 'FK', label: 'Falkland-szigetek' },
    { value: 'FO', label: 'Feröer' },
    { value: 'FJ', label: 'Fidzsi' },
    { value: 'TF', label: 'Francia déli és antarktiszi területek' },
    { value: 'GF', label: 'Francia Guyana' },
    { value: 'PF', label: 'Francia Polinézia' },
    { value: 'PH', label: 'Fülöp-szigetek' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GH', label: 'Ghána' },
    { value: 'GI', label: 'Gibraltár' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GE', label: 'Grúzia' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey Bailiffség' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard-sziget és McDonald-szigetek' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hongkong' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonézia' },
    { value: 'IQ', label: 'Irak' },
    { value: 'IR', label: 'Irán' },
    { value: 'IL', label: 'Izrael' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japán' },
    { value: 'YE', label: 'Jemen' },
    { value: 'JE', label: 'Jersey Bailiffség' },
    { value: 'JO', label: 'Jordánia' },
    { value: 'KY', label: 'Kajmán-szigetek' },
    { value: 'KH', label: 'Kambodzsa' },
    { value: 'CM', label: 'Kamerun' },
    { value: 'CA', label: 'Kanada' },
    { value: 'CX', label: 'Karácsony-sziget' },
    { value: 'BQ', label: 'Karibi Hollandia (Bonaire, Saba, Sint Eustatius)' },
    { value: 'QA', label: 'Katar' },
    { value: 'KZ', label: 'Kazahsztán' },
    { value: 'TL', label: 'Kelet-Timor' },
    { value: 'KE', label: 'Kenya' },
    { value: 'CN', label: 'Kína' },
    { value: 'KG', label: 'Kirgizisztán' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'CC', label: 'Kókusz (Keeling)-szigetek' },
    { value: 'CO', label: 'Kolumbia' },
    { value: 'CD', label: 'Kongói Demokratikus Köztársaság (Zaire)' },
    { value: 'CG', label: 'Kongói Köztársaság (Kongó)' },
    { value: 'XK', label: 'Koszovó' },
    { value: 'CF', label: 'Közép-Afrika' },
    { value: 'CU', label: 'Kuba' },
    { value: 'KW', label: 'Kuvait' },
    { value: 'LA', label: 'Laosz' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LB', label: 'Libanon' },
    { value: 'LR', label: 'Libéria' },
    { value: 'LY', label: 'Líbia' },
    { value: 'MG', label: 'Madagaszkár' },
    { value: 'MO', label: 'Makaó' },
    { value: 'MY', label: 'Malajzia' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MV', label: 'Maldív-szigetek' },
    { value: 'ML', label: 'Mali' },
    { value: 'IM', label: 'Man' },
    { value: 'MA', label: 'Marokkó' },
    { value: 'MH', label: 'Marshall-szigetek' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritánia' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexikó' },
    { value: 'MM', label: 'Mianmar' },
    { value: 'FM', label: 'Mikronézia' },
    { value: 'MN', label: 'Mongólia' },
    { value: 'ME', label: 'Montenegró' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MZ', label: 'Mozambik' },
    { value: 'NA', label: 'Namíbia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepál' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigéria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk-sziget' },
    { value: 'EH', label: 'Nyugat-Szahara' },
    { value: 'OM', label: 'Omán' },
    { value: 'PK', label: 'Pakisztán' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palesztina' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Pápua Új-Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PN', label: 'Pitcairn-szigetek' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RE', label: 'Réunion' },
    { value: 'RW', label: 'Ruanda' },
    { value: 'BL', label: 'Saint-Barthélemy' },
    { value: 'KN', label: 'Saint Kitts és Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint-Martin' },
    { value: 'PM', label: 'Saint-Pierre és Miquelon' },
    { value: 'VC', label: 'Saint Vincent és a Grenadine-szigetek' },
    { value: 'SB', label: 'Salamon-szigetek' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tomé és Príncipe' },
    { value: 'SC', label: 'Seychelle-szigetek' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SX', label: 'Sint Maarten' },
    { value: 'SJ', label: 'Norvégia Spitzbergák és Jan Mayen-sziget' },
    { value: 'LK', label: 'Srí Lanka' },
    { value: 'SR', label: 'Suriname' },
    { value: 'WS', label: 'Szamoa' },
    { value: 'SA', label: 'Szaúd-Arábia' },
    { value: 'SN', label: 'Szenegál' },
    { value: 'SH', label: 'Szent Ilona' },
    { value: 'RS', label: 'Szerbia' },
    { value: 'SG', label: 'Szingapúr' },
    { value: 'SY', label: 'Szíria' },
    { value: 'SO', label: 'Szomália' },
    { value: 'SD', label: 'Szudán' },
    { value: 'SZ', label: 'Szváziföld' },
    { value: 'TJ', label: 'Tádzsikisztán' },
    { value: 'TW', label: 'Tajvan' },
    { value: 'TZ', label: 'Tanzánia' },
    { value: 'TH', label: 'Thaiföld' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau-szigetek' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TR', label: 'Törökország' },
    { value: 'TT', label: 'Trinidad és Tobago' },
    { value: 'TN', label: 'Tunézia' },
    { value: 'TC', label: 'Turks- és Caicos-szigetek' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'TM', label: 'Türkmenisztán' },
    { value: 'UG', label: 'Uganda' },
    { value: 'NC', label: 'Új-Kaledónia' },
    { value: 'NZ', label: 'Új-Zéland' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Üzbegisztán' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnám' },
    { value: 'WF', label: 'Wallis és Futuna' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
    { value: 'CV', label: 'Zöld-foki Köztársaság' },
  ];

  public autoConcludeChanged(status: boolean): void {
    this.hotelService.autoConclude(status);
  }
  public autoArriveChanged(status: boolean): void {
    this.hotelService.autoArrive(status);
  }
  public autoDepartChanged(status: boolean): void {
    this.hotelService.autoDepart(status);
  }

  public toggleCommunicationLang(state: boolean, lang: string): void {
    if (state) {
      this.hotelService.addCommunicationLanguage(lang);
    } else {
      this.hotelService.removeCommunicationLanguage(lang);
    }
  }

  public toggleCountry(state: boolean, country: string): void {
    if (state) {
      this.hotelService.addCountry(country);
    } else {
      this.hotelService.removeCountry(country);
    }
  }
}
