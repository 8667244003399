import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionToText',
})
export class OptionToTextPipe implements PipeTransform {
  transform(value: string): string {
    const options: any = {
      APARTMAN: 'Apartman',
      ECONOMY: 'Economy szoba',
      STANDARD: 'Standard szoba',
      SUPERIOR: 'Superior szoba',
      JUNIOR_SUITE: 'Junior Suite (Kislakosztály)',
      SUITE: 'Suite (Lakosztály)',
      MOBILHAZ: 'Mobilház',
      UDULOHAZ: 'Üdülőház',
      PARCELLA: 'Parcella',
      SATORHELY_KEMPINGHELY: 'Sátorhely/ Kempinghely',
      EGYEDI: 'Egyedi (pl: jurta)',
      PRIVAT_SZOBA_KOZOS_FURDOVEL:
        'Privát szoba közös fürdővel (ifjúsági szálláshely)',
      PRIVAT_SZOBA_SAJAT_FURDOVEL:
        'Privát szoba saját fürdővel (ifjúsági szálláshely)',
      HALOTERMI_AGY: 'Hálótermi ágy',
      EGYEB: 'Egyéb',
    };

    return options[value] || value;
  }
}
