import { Component, EventEmitter, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { BookingDetailsInterface } from 'src/app/@Interfaces/booking.interface';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import {
  chanelOptions,
  communicationLangOptions,
  nationalityOptions,
  sourceOptions,
} from '../../../@consts';
import { HotelService, RouterService } from 'src/app/@services';

@Component({
  selector: 'booking-new-details',
  templateUrl: './booking-new-details.component.html',
  styleUrls: ['./booking-new-details.component.scss'],
})
export class BookingNewDetailsComponent {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    private calendarService: CalendarService,
    public roomService: RoomService,
    private routerService: RouterService,
    private hotelService: HotelService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public selectedLanguage: string;
  public bookingDetails: BookingDetailsInterface = {
    channel: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    group_booking: false,
    language: '',
    nationality: '',
    source: '',
    work_related: false,
    external_id: '',
  };

  public readonly chanelOptions = chanelOptions;
  public readonly sourceOptions = sourceOptions;
  public readonly communicationLangOptions = communicationLangOptions;
  public readonly nationalityOptions = nationalityOptions;

  public languageOptions = [{}];

  // TODO: insert languages based on hotel response
  private spokenLanguages: string[] = ['HU', 'EN', 'DE'];

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public nextStep(event?: Event): void {
    event.stopPropagation();
    this.bookingService.saveBookingData(this.bookingDetails);
    this.calendarService.setAvailableDayClasses();
    this.routerService.absoluteNavigation('hu/calendar/room');
  }

  public closeBooking(): void {
    this.bookingChanel = '';
    this.routerService.absoluteNavigationNoParamPreserve('hu/calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
    this.calendarService.removeAvailableDayClasses();
    this.selectedLanguage = '';
  }

  public setCommunicationLang(countryCode: any): void {
    switch (countryCode) {
      case 'HU':
        if (this.spokenLanguages.includes('HU')) {
          this.bookingDetails.language = 'HU';
        }
        break;
      case 'AT':
      case 'DE':
        if (this.spokenLanguages.includes('DE')) {
          this.bookingDetails.language = 'DE';
        } else {
          this.setDefaultCommunicationLanguage();
        }
        break;
      case 'PL':
        if (this.spokenLanguages.includes('PL')) {
          this.bookingDetails.language = 'PL';
        } else {
          this.setDefaultCommunicationLanguage();
        }
        break;
      case 'FR':
        if (this.spokenLanguages.includes('FR')) {
          this.bookingDetails.language = 'FR';
        } else {
          this.setDefaultCommunicationLanguage();
        }
        break;
      case 'ES':
        if (this.spokenLanguages.includes('ES')) {
          this.bookingDetails.language = 'ES';
        } else {
          this.setDefaultCommunicationLanguage();
        }
        break;
      case 'RU':
        if (this.spokenLanguages.includes('RU')) {
          this.bookingDetails.language = 'RU';
        } else {
          this.setDefaultCommunicationLanguage();
        }
        break;
      case 'CN':
        if (this.spokenLanguages.includes('CN')) {
          this.bookingDetails.language = 'CN';
        } else {
          this.setDefaultCommunicationLanguage();
        }
        break;

      default:
        this.setDefaultCommunicationLanguage();
        break;
    }
  }

  private setDefaultCommunicationLanguage() {
    if (this.spokenLanguages.includes('EN')) {
      this.bookingDetails.language = 'EN';
    } else {
      this.bookingDetails.language = this.spokenLanguages[0];
    }
  }
}
