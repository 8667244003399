<div class="booking-rooms-payment-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-header"></div>

  <div class="action-panel">
    <div class="panel-wrapper">
      <div class="action-panel-header">
        <div class="paid-cost">
          <span class="description">Fizetve</span
          ><span class="cost">{{ paidAmount }} Ft</span>
        </div>
        <div class="room" *ngIf="!areAllRoomsSelected && selectedRoom">
          {{ roomService.getRoomName(selectedRoom) }}
        </div>
        <div class="room" *ngIf="areAllRoomsSelected">Összes szoba</div>
        <div class="remaining-cost">
          <span class="description">Fennmaradó</span
          ><span class="cost">{{ remainingAmount }} Ft</span>
        </div>
        <div class="total-cost">
          <span class="description">Össz </span
          ><span class="cost">{{ totalPrice }} Ft</span>
        </div>
        <div class="group-action-panel">
          <span>Tömeges műveletek</span>
          <form
            class="group-actions"
            #groupForm="ngForm"
            (ngSubmit)="payAllCharges(groupForm.value)"
          >
            <select-input
              id="group_payment_method"
              name="group_payment_method"
              [required]="true"
              [(ngModel)]="groupForm.value.group_payment_method"
              [options]="paymentOptions"
              (ngModelChange)="setAllPaymentMethod($event)"
            ></select-input>
            <button
              class="secondary-button"
              [disabled]="groupForm.invalid"
              type="submit"
            >
              Összes kifizetése
            </button>
            <div class="form-textbox">
              <date-input
                [(ngModel)]="groupForm.value.group_payment_date"
                name="group_payment_date"
                [required]="true"
                [label]="'Fizetés napja*'"
                [isDateString]="true"
                (ngModelChange)="setAllPaymentDate($event)"
              ></date-input>
            </div>
          </form>
        </div>
      </div>

      <div class="charges-wrapper">
        <ng-container *ngFor="let charge of charges; let i = index">
          <form
            *ngIf="
              !charge.charge_id ||
              charge.room_id === selectedRoom ||
              areAllRoomsSelected
            "
            #chargeForms="ngForm"
            class="charge-wrapper"
            [ngClass]="{ success: charge.paid }"
            (ngSubmit)="
              charge.charge_id ? payCharge(charge) : postCharge(charge)
            "
          >
            <div class="header-wrapper">
              <div class="charge-type">
                <i
                  class="double-bed-icon"
                  *ngIf="charge.charge_type === 'ROOM'"
                ></i>
                <i class="tax-icon" *ngIf="charge.charge_type === 'IFA'"></i>
                <i *ngIf="charge.charge_type === 'FOOD'" class="food-icon"></i>
                <i
                  *ngIf="charge.charge_type === 'DRINK'"
                  class="drink-icon"
                ></i>
                <i
                  *ngIf="charge.charge_type === 'WELLNESS'"
                  class="wellness-icon"
                ></i>
                <i
                  *ngIf="charge.charge_type === 'OTHER'"
                  class="other-service-icon"
                ></i>
              </div>
              <div class="name-wrapper">
                <input
                  type="text"
                  name="name"
                  [(ngModel)]="charge.name"
                  required
                  [disabled]="
                    charge.charge_type === chargeTypes.ifa ||
                    charge.charge_type === chargeTypes.room ||
                    charge.paid
                  "
                />
              </div>
            </div>
            <div
              class="delete-wrapper"
              *ngIf="
                charge.charge_id &&
                !charge.paid &&
                charge.charge_type !== chargeTypes.ifa &&
                charge.charge_type !== chargeTypes.room
              "
            >
              <button type="button" (click)="deleteCharge(charge)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>

            <div class="cost-wrapper">
              <text-input
                class="qty"
                name="qty"
                [type]="'number'"
                [label]="'Mennyiség*'"
                [required]="true"
                [(ngModel)]="charge.qty"
                [disabled]="charge.paid"
              />
              <text-input
                class="unit"
                name="unit"
                [type]="'string'"
                [label]="'Egység*'"
                [required]="true"
                [(ngModel)]="charge.unit"
                [disabled]="charge.paid"
              />
              <select-input
                id="vat_rate"
                name="vat_rate"
                class="vat-rate"
                [required]="true"
                [(ngModel)]="charge.vat_rate"
                [options]="vatRateOptions"
                [label]="'ÁFA*'"
                [disabled]="charge.paid"
              />
              <text-input
                class="price"
                name="gross_price"
                [type]="'number'"
                [label]="'Bruttó ár'"
                [required]="true"
                [(ngModel)]="charge.gross_price"
                [disabled]="charge.paid"
              />
            </div>
            <div class="total-wrapper">
              <text-input
                class="total-price"
                name="totalPrice"
                [type]="'number'"
                [label]="'Összes ár'"
                [required]="true"
                [disabled]="true"
                [ngModel]="charge.gross_price * charge.qty"
              />
            </div>
            <div class="comment-wrapper">
              <div
                class="form-textbox comment"
                [ngClass]="{
                  focused: true
                }"
              >
                <textarea
                  type="textarea"
                  name="comment"
                  [(ngModel)]="charge.comment"
                  [disabled]="charge.paid"
                ></textarea>
                <span class="form-textbox-label">Megjegyzés</span>
              </div>
            </div>

            <div class="payment-method-wrapper">
              <select-input
                id="payment_method"
                name="payment_method"
                class="payment-method"
                [required]="true"
                [(ngModel)]="charge.payment_method"
                [disabled]="charge.paid"
                [options]="paymentOptions"
                [label]="'Fizetési mód*'"
              >
              </select-input>
            </div>

            <div class="payment-date-wrapper">
              <date-input
                *ngIf="charge.charge_id"
                [disabled]="charge.paid"
                [isDateString]="true"
                [(ngModel)]="charge.payment_date"
                name="payment_date"
                [required]="true"
                [label]="'Fizetés napja*'"
              ></date-input>
            </div>

            <div class="modification-button-wrapper">
              <button
                class="secondary-button"
                *ngIf="!charge.charge_id"
                [disabled]="chargeForms.invalid"
              >
                Felvesz
              </button>
              <div
                class="button-wrapper"
                *ngIf="
                  chargeForms.dirty &&
                  charge.charge_id &&
                  !charge.payment_method &&
                  !charge.payment_date
                "
              >
                <button
                  class="secondary-button cancel"
                  (click)="resetChargeForm(i)"
                  type="button"
                >
                  Mégse
                </button>
                <button
                  class="secondary-button modify"
                  (click)="editCharge(charge)"
                  type="button"
                >
                  Módosít
                </button>
              </div>
            </div>
            <div class="payment-button-wrapper">
              <button
                class="primary-button"
                *ngIf="
                  charge.charge_id &&
                  !charge.paid &&
                  (!chargeForms.dirty ||
                    charge.payment_date ||
                    charge.payment_method)
                "
                type="submit"
                [disabled]="chargeForms.invalid"
              >
                Fizet
              </button>
              <button
                class="primary-button success"
                *ngIf="charge.charge_id && charge.paid"
                type="submit"
                [disabled]="true"
              >
                Kifizetve
              </button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngFor="let charge of newCharges; let i = index">
          <form
            *ngIf="
              !charge.charge_id ||
              charge.room_id === selectedRoom ||
              areAllRoomsSelected
            "
            #chargeForms="ngForm"
            class="charge-wrapper"
            [ngClass]="{ success: charge.paid }"
            (ngSubmit)="
              charge.charge_id ? payCharge(charge) : postCharge(charge)
            "
          >
            <div class="header-wrapper">
              <div class="charge-type">
                <i
                  class="double-bed-icon"
                  *ngIf="charge.charge_type === 'ROOM'"
                ></i>
                <i class="tax-icon" *ngIf="charge.charge_type === 'IFA'"></i>
                <i *ngIf="charge.charge_type === 'FOOD'" class="food-icon"></i>
                <i
                  *ngIf="charge.charge_type === 'DRINK'"
                  class="drink-icon"
                ></i>
                <i
                  *ngIf="charge.charge_type === 'WELLNESS'"
                  class="wellness-icon"
                ></i>
                <i
                  *ngIf="charge.charge_type === 'OTHER'"
                  class="other-service-icon"
                ></i>
              </div>
              <div class="name-wrapper">
                <input
                  type="text"
                  name="name"
                  [(ngModel)]="charge.name"
                  required
                  [disabled]="
                    charge.charge_type === chargeTypes.ifa ||
                    charge.charge_type === chargeTypes.room ||
                    charge.paid
                  "
                />
              </div>
            </div>
            <div
              class="delete-wrapper"
              *ngIf="
                charge.charge_id &&
                !charge.paid &&
                charge.charge_type !== chargeTypes.ifa &&
                charge.charge_type !== chargeTypes.room
              "
            >
              <button type="button" (click)="deleteCharge(charge)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>

            <div class="cost-wrapper">
              <text-input
                class="qty"
                name="qty"
                [type]="'number'"
                [label]="'Mennyiség*'"
                [required]="true"
                [(ngModel)]="charge.qty"
                [disabled]="charge.paid"
              />
              <text-input
                class="unit"
                name="unit"
                [type]="'string'"
                [label]="'Egység*'"
                [required]="true"
                [(ngModel)]="charge.unit"
                [disabled]="charge.paid"
              />
              <select-input
                id="vat_rate"
                name="vat_rate"
                class="vat-rate"
                [required]="true"
                [(ngModel)]="charge.vat_rate"
                [options]="vatRateOptions"
                [label]="'ÁFA*'"
              />
              <text-input
                class="price"
                name="gross_price"
                [type]="'number'"
                [label]="'Bruttó ár'"
                [required]="true"
                [(ngModel)]="charge.gross_price"
                [disabled]="charge.paid"
              />
            </div>
            <div class="total-wrapper">
              <text-input
                class="total-price"
                name="totalPrice"
                [type]="'number'"
                [label]="'Összes ár'"
                [required]="true"
                [disabled]="true"
                [ngModel]="charge.gross_price * charge.qty"
              />
            </div>
            <div class="comment-wrapper">
              <div
                class="form-textbox comment"
                [ngClass]="{
                  focused: true
                }"
              >
                <textarea
                  type="textarea"
                  name="comment"
                  [(ngModel)]="charge.comment"
                  [disabled]="charge.paid"
                ></textarea>
                <span class="form-textbox-label">Megjegyzés</span>
              </div>
            </div>

            <div class="payment-date-wrapper">
              <date-input
                *ngIf="charge.charge_id"
                [disabled]="charge.paid"
                [isDateString]="true"
                [(ngModel)]="charge.payment_date"
                name="payment_date"
                [required]="true"
                [label]="'Fizetés napja*'"
              ></date-input>
            </div>

            <div class="modification-button-wrapper">
              <button
                class="secondary-button"
                *ngIf="!charge.charge_id"
                [disabled]="chargeForms.invalid"
              >
                Felvesz
              </button>
              <div
                class="button-wrapper"
                *ngIf="
                  chargeForms.dirty &&
                  charge.charge_id &&
                  !charge.payment_method &&
                  !charge.payment_date
                "
              >
                <button
                  class="secondary-button cancel"
                  (click)="resetChargeForm(i)"
                  type="button"
                >
                  Mégse
                </button>
                <button
                  class="secondary-button modify"
                  (click)="editCharge(charge)"
                  type="button"
                >
                  Módosít
                </button>
              </div>
            </div>
            <div class="payment-button-wrapper">
              <button
                class="primary-button"
                *ngIf="
                  charge.charge_id &&
                  !charge.paid &&
                  (!chargeForms.dirty ||
                    charge.payment_date ||
                    charge.payment_method)
                "
                type="submit"
                [disabled]="chargeForms.invalid"
              >
                Fizet
              </button>
              <button
                class="primary-button success"
                *ngIf="charge.charge_id && charge.paid"
                type="submit"
                [disabled]="true"
              >
                Kifizetve
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="action-footer">
    <button class="secondary-button" (click)="addCharge()">+ Új tétel</button>
  </div>

  <div class="selector-header">
    <button
      class="secondary-button"
      (click)="selectAllRooms()"
      *ngIf="!areAllRoomsSelected"
    >
      Összes kiválasztása
    </button>
    <button
      class="secondary-button"
      (click)="deselectAllRooms()"
      *ngIf="areAllRoomsSelected"
    >
      Mégse
    </button>
  </div>

  <div class="selector-wrapper">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of bookingService.sidePanelRooms | keyvalue"
        class="room-panel"
        [ngClass]="{
          success: bookingService.sidePanelRooms[room.key].paid,
          selected: room.key === selectedRoom?.toString() || areAllRoomsSelected,
        }"
        (click)="selectRoom(room.key)"
      >
        <div class="room-name">
          <span>{{ roomService.getRoomName(room.key) }}</span>
        </div>

        <div class="details">
          <span
            class="tooltip"
            *ngIf="bookingService.sidePanelRooms[room.key].paid"
            >Fizetve</span
          >
          <span
            class="tooltip"
            *ngIf="!bookingService.sidePanelRooms[room.key].paid"
            >Fennmaradó összeg</span
          >
          <div
            class="amount-block"
            *ngIf="!bookingService.sidePanelRooms[room.key].paid"
          >
            <span>{{
              bookingService.sidePanelRooms[room.key].remainingAmount
            }}</span
            ><span> Ft</span>
          </div>
        </div>
        <div class="occupancy-wrapper">
          <div class="occupancy-block">
            <div class="occupancy">
              <span>
                {{ bookingService.sidePanelRooms[room.key].adults }}
              </span>
            </div>
            <div>
              <i class="adult-icon"></i>
            </div>
          </div>
          <div class="occupancy-block">
            <div class="occupancy">
              <span>
                {{ bookingService.sidePanelRooms[room.key].children }}
              </span>
            </div>
            <div>
              <i class="child-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="selector-footer"></div>
</div>
