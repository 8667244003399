import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { SidePanelTypeEnum } from 'src/app/enums';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { DateService } from 'src/app/@services/date.service';
import { BookingInterface } from 'src/app/@Interfaces';
import { HotelService, RouterService } from 'src/app/@services';

@Component({
  selector: 'booking-new-rooms',
  templateUrl: './booking-new-rooms.component.html',
  styleUrls: ['./booking-new-rooms.component.scss'],
})
export class BookingNewRoomsComponent {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    public tableService: TableService,
    public dateService: DateService,
    private routerService: RouterService,
    private hotelService: HotelService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  public selectedLanguage: string;
  public bookingChanel: string;
  public _sidePanelType: SidePanelTypeEnum;
  public _isEditing: boolean;
  public isLoading: boolean;
  private counter: number = 0;

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public bookingGroup: any = new FormGroup({
    channel: new FormControl('', Validators.required),
    contact_email: new FormControl(),
    contact_name: new FormControl(),
    contact_phone: new FormControl(),
    external_id: new FormControl(),
    group_booking: new FormControl(false),
    language: new FormControl(),
    nationality: new FormControl(),
    source: new FormControl(),
    work_related: new FormControl(false),
  });

  public inputStartDate: any;
  public inputEndDate: any;
  public contactName: string;
  public people: number;

  public closeBooking(): void {
    this.routerService.absoluteNavigationNoParamPreserve('hu/calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
    //timeout for animation to finish
    setTimeout(() => {
      this.bookingChanel = '';

      this.calendarService.removeAvailableDayClasses();
      this.selectedLanguage = '';
      this.bookingGroup = new FormGroup({
        channel: new FormControl('', Validators.required),
        contact_email: new FormControl(),
        contact_name: new FormControl(),
        contact_phone: new FormControl(),
        external_id: new FormControl(),
        group_booking: new FormControl(false),
        language: new FormControl(),
        nationality: new FormControl(),
        source: new FormControl(),
        work_related: new FormControl(false),
      });
    }, 1000);
  }

  public postBooking(event: Event): void {
    this.isLoading = true;

    this.bookingService
      .postNewBooking(this.bookingService.sidePanelRooms)
      .then((bookingId: number) => {
        this.calendarService.removeAvailableDayClasses();
        this.bookingService
          .getBookings()
          .then(() => {
            const booking: BookingInterface = this.bookingService.bookings.find(
              (booking) => booking.booking_id === bookingId
            );
            //this.calendarService.selectBooking(bookingId, booking.room_id);
            this.routerService.addQueryParams({
              booking: bookingId,
              room: booking.room_id,
            });
            this.isLoading = false;
            this.routerService.absoluteNavigation('hu/calendar/payment');
          })
          .catch(() => {});
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public addRoom(): void {
    this.tableService.addRoom(this.counter);
    this.counter++;
  }

  public getSelectableRoomIds(roomId: string): string[] {
    const roomIds = this.roomService.rooms.map((room) =>
      room.room_id.toString()
    );
    const bookedRoomIds = Object.keys(this.bookingService.sidePanelRooms);
    const roomIdsToLoop = roomIds.filter(
      (roomId) => !bookedRoomIds.includes(roomId.toString())
    );
    if (roomId !== '') {
      roomIdsToLoop.push(roomId);
    }
    return roomIdsToLoop;
  }
}
