import { Injectable } from '@angular/core';
import { environment } from '../@environments/environtment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor() {}

  public server: string = environment.url;
}
