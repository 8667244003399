import { Component } from '@angular/core';
import { ConfigService } from 'src/app/@services';

@Component({
  selector: 'server-selector',
  templateUrl: './server-select.dialog.html',
  styleUrls: ['./server-select.dialog.scss'],
})
export class ServerSelectorDialog {
  constructor(private configService: ConfigService) {}

  protected readonly serverOptions = [
    { value: '/', label: 'local' },
    { value: 'https://dev.app.kisportas.hu/', label: 'dev' },
    { value: 'https://app.kisportas.hu/', label: 'prod' },
  ];

  protected selectedServer: string;

  protected setSelectedServer(): void {
    this.configService.server = this.selectedServer;
  }
}
