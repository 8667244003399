import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RoomInterface } from 'src/app/@Interfaces';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { DateService } from 'src/app/@services/date.service';
import { RouterService } from 'src/app/@services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rooms-oos',
  templateUrl: './rooms-oos.component.html',
  styleUrls: ['./rooms-oos.component.scss'],
})
export class RoomsOosComponent implements OnInit, OnDestroy {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    private dateService: DateService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
    this.roomSub = this.routerService.activeRoom$.subscribe((roomId) => {
      if (!roomId) return;

      this.roomService.roomOOO = [];
      this.roomService.roomOOS = [];
      this.startDate = null;
      this.endDate = null;

      this.roomService.getRoomOOS(Number(roomId)).catch(() => {});
    });
  }
  public dynamicThe: string = 'a(z)';
  public floors = [-1, 0, 1, 2];
  public startDate: Date;
  public endDate: Date;

  private roomSub: Subscription;

  public deselectRoom(): void {
    this.roomService.selectedRoom = null;
  }

  public cancel(): void {}

  public deleteOOS(oosId: number): void {
    this.roomService.deleteRoomOOS(oosId);
  }

  public addOOS(): void {
    const startDate = this.dateService.formatDate(this.startDate);
    const endDate = this.dateService.formatDate(this.endDate);
    const requestBody = {
      room_id: this.roomService.selectedRoom.room_id,
      start_date: startDate,
      end_date: endDate,
    };
    this.roomService.createRoomOOS(requestBody).catch(() => {});
  }

  ngOnDestroy(): void {
    this.roomSub.unsubscribe();
  }
}
