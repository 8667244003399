import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessfulSnackBar } from '../@snackbars/successful/successful.snackbar';
import { UnsuccessfulSnackBar } from '../@snackbars/unsuccessful/unsuccessful.snackbar';
import { PremiumRequiredSnackBar } from '../@snackbars/premium-required/premium-required.snackbar.';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public openOkSnackBar(): void {
    this.snackBar.openFromComponent(SuccessfulSnackBar, {
      duration: 5000,
      panelClass: 'my-snackbar',
    });
  }

  public openFailedSnackBar(): void {
    this.snackBar.openFromComponent(UnsuccessfulSnackBar, {
      duration: 5000,
      panelClass: 'my-snackbar',
    });
  }

  public openPremiumRequired(): void {
    this.snackBar.openFromComponent(PremiumRequiredSnackBar, {
      duration: 5000,
      panelClass: 'my-snackbar',
    });
  }
}
