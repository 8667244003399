<div class="nav-bar-wrapper">
  <div class="left-col">
    <!-- <div class="nav-button" (click)="navigate('home')">
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'home'
        }"
        >Főoldal</a
      >
    </div> -->
    <div class="nav-button" (click)="navigate('calendar')">
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'calendar'
        }"
        >Naptár</a
      >
    </div>
    <div class="nav-button" (click)="navigate('admin')">
      <a
        [ngClass]="{
          active: focusService.selectedMenu === 'admin'
        }"
        >Admin</a
      >
    </div>
  </div>
  <div class="middle-col">
    <div class="hotel-select">
      <select #hotelSelect class="dropdown-select">
        <option
          *ngFor="let hotel of hotelService.hotels"
          [value]="hotel"
          (click)="hotelSelected(hotel)"
        >
          {{ hotel.name }}
        </option>
      </select>
      <span class="dropdown-chevron">&#8744;</span>
    </div>
  </div>
  <div class="right-col">
    <div class="nav-button" (click)="signOut()">
      <a>Kijelentkezés</a>
    </div>
    <div class="nav-button" (click)="navigate('profil')">
      <a>Profil</a>
    </div>
  </div>
</div>
