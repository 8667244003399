import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './@components/@pages/home/home.component';
import { LoginComponent } from './@components/@pages/login/login.component';
import { AdminComponent } from './@components/@pages/admin/admin.component';
import { RoomsComponent } from './@components/rooms/rooms.component';
import { CalendarComponent } from './@components/@pages/calendar/calendar.component';
import { authGuard } from './@guards/auth.guard';
import { QrScannerComponent } from './@components/qr-scanner/qr-scanner.component';
import { ScannerComponent } from './@components/scan/scanner.component';
import {
  AdminHotelComponent,
  BookingArrivalComponent,
  BookingEditDetailsComponent,
  BookingEditRoomsComponent,
  BookingNewDetailsComponent,
  BookingNewRoomsComponent,
  BookingRoomsDepartComponent,
  BookingRoomsPaymentComponent,
  RoomsAddComponent,
  RoomsEditComponent,
  RoomsOooComponent,
  RoomsOosComponent,
  SelfCheckInComponent,
  ServicesAddComponent,
  ServicesComponent,
  ServicesEditComponent,
} from './@components';
import { ProfileComponent } from './@components/@pages/profile/profile.component';
import { RegisterComponent } from './@components/@pages/register/register.component';
import { SupportComponent } from './@components/@pages/support/support.component';
import { PageNotFoundComponent } from './@components/@pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: ':lang',
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [authGuard],
      },
      {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [authGuard],
        children: [
          {
            path: 'arrival',
            component: BookingArrivalComponent,
          },
          {
            path: 'booking',
            component: BookingNewDetailsComponent,
          },
          {
            path: 'departure',
            component: BookingRoomsDepartComponent,
          },
          {
            path: 'room',
            component: BookingNewRoomsComponent,
          },
          {
            path: 'modify-booking',
            component: BookingEditDetailsComponent,
          },
          {
            path: 'modify-rooms',
            component: BookingEditRoomsComponent,
          },
          {
            path: 'payment',
            component: BookingRoomsPaymentComponent,
          },
        ],
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [authGuard],
        children: [
          {
            path: 'rooms',
            component: RoomsComponent,
            children: [
              {
                path: 'add',
                component: RoomsAddComponent,
              },
              {
                path: 'edit',
                component: RoomsEditComponent,
              },
              {
                path: 'ooo',
                component: RoomsOooComponent,
              },
              {
                path: 'oos',
                component: RoomsOosComponent,
              },
            ],
          },
          {
            path: 'services',
            component: ServicesComponent,
            children: [
              {
                path: 'add',
                component: ServicesAddComponent,
              },
              {
                path: 'edit',
                component: ServicesEditComponent,
              },
            ],
          },
          { path: 'hotel', component: AdminHotelComponent },
          { path: '**', redirectTo: 'rooms', pathMatch: 'full' },
        ],
      },
      {
        path: 'qr-scanner',
        component: QrScannerComponent,
        canActivate: [authGuard],
      },
      {
        path: 'scanner',
        component: ScannerComponent,
        canActivate: [authGuard],
      },
      { path: 'profil', component: ProfileComponent, canActivate: [authGuard] },
      {
        path: 'support',
        component: SupportComponent,
        canActivate: [authGuard],
      },
      {
        path: 'self-check-in',
        component: SelfCheckInComponent,
      },
      {
        path: 'not-found',
        component: PageNotFoundComponent,
      },
      {
        path: '**',
        redirectTo: 'not-found',
      },
    ],
  },
  { path: '', redirectTo: '/hu/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/hu/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled', // Enables scrolling to the anchor
      scrollPositionRestoration: 'enabled', // Restores scroll position when navigating back
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
