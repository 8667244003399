import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerOptions,
  CapacitorBarcodeScannerTypeHint,
  CapacitorBarcodeScannerCameraDirection,
  CapacitorBarcodeScannerScanResult,
} from '@capacitor/barcode-scanner';
import { GuestService, RouterService } from 'src/app/@services';
@Component({
  selector: 'qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit {
  constructor(
    private guestService: GuestService,
    private RouterService: RouterService
  ) {}

  private readonly barcodeScannerOptions: CapacitorBarcodeScannerOptions = {
    hint: CapacitorBarcodeScannerTypeHint.QR_CODE,
    cameraDirection: CapacitorBarcodeScannerCameraDirection.BACK,
  };

  ngOnInit(): void {
    this.openBarcodeScanner();
  }

  public openBarcodeScanner(): void {
    if (!Capacitor.isNativePlatform()) return;

    CapacitorBarcodeScanner.scanBarcode(this.barcodeScannerOptions).then(
      (barcodeRes: CapacitorBarcodeScannerScanResult) => {
        this.guestService.readQrCode = JSON.parse(barcodeRes.ScanResult);
        this.RouterService.absoluteNavigation('hu/scanner');
      }
    );
  }
}
