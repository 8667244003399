<div class="admin-hotel-wrapper">
  <div class="main-content">
    <h1>Hotel</h1>
    <p *ngIf="hotelService.selectedHotel">
      Itt érheti el, és módosíthatja {{ dynamicThe }}
      {{ hotelService.selectedHotel.name }} adatait
    </p>
    <form #hotelAdminForm="ngForm">
      <ng-container>
        <text-input
          [type]="'text'"
          id="name"
          name="name"
          [required]="true"
          [(ngModel)]="name"
          [label]="'Szálláshely neve'"
        />
        <fieldset class="automations">
          <legend>Automatizációk</legend>
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoArrive"
            (userInput)="autoArriveChanged($event)"
            name="auto_arrive"
            [label]="'Automata check-in'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoDepart"
            (userInput)="autoDepartChanged($event)"
            name="auto_depart"
            [label]="'Automata check-out'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="auto_conclude"
            [label]="'Automata napi zárás'"
          />
        </fieldset>
        <fieldset class="communication-languages">
          <legend>Kommunikációs nyelvek</legend>
          <ng-container *ngFor="let commLang of communicationLanguages">
            <checkbox-input
              *ngIf="hotelService.selectedHotel?.languages"
              type="checkbox"
              [_value]="
                hotelService.selectedHotel.languages.includes(commLang.value)
              "
              (userInput)="toggleCommunicationLang($event, commLang.value)"
              name="commLang.value"
              [label]="commLang.label"
            />
          </ng-container>
        </fieldset>
        <fieldset class="countries">
          <legend>Országok</legend>
          <ng-container *ngFor="let country of countries">
            <checkbox-input
              *ngIf="hotelService.selectedHotel?.countries"
              [_value]="
                hotelService.selectedHotel.countries.includes(country.value)
              "
              (userInput)="toggleCountry($event, country.value)"
              [name]="country.value"
              [label]="country.label"
            />
          </ng-container>
        </fieldset>
      </ng-container>
    </form>
  </div>
</div>
