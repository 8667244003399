import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'unsuccessful',
  templateUrl: 'unsuccessful.snackbar.html',
  styleUrls: ['unsuccessful.snackbar.scss'],
})
export class UnsuccessfulSnackBar {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    public snackBarRef: MatSnackBarRef<UnsuccessfulSnackBar>
  ) {}

  public closeSnackBar(): void {
    this.snackBarRef.dismiss();
  }
}
