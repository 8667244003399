<div class="admin-wrapper">
  <div class="sub-menu-wrapper">
    <div class="sub-menu">
      <a
        (click)="selectSubPage('hotel')"
        [ngClass]="{
          active: routerService.subMenu === 'hotel'
        }"
        >Hotel</a
      >
      <a
        (click)="selectSubPage('rooms')"
        [ngClass]="{
          active: routerService.subMenu === 'rooms'
        }"
        >Szobák</a
      >
      <a
        (click)="selectSubPage('services')"
        [ngClass]="{
          active: routerService.subMenu === 'services'
        }"
        >Szolgáltatások</a
      >
      <!--<a
        (click)="selectSubPage('users')"
        [ngClass]="{
          active: routerService.subMenu  === 'users'
        }"
        >Felhasználók</a
      > -->
    </div>
  </div>
  <!-- <div [ngSwitch]="routerService.subMenu" class="admin-content">
    <div *ngSwitchCase="'hotels'">
      <p>Hotel adatok</p>
    </div>
    <rooms *ngSwitchCase="'rooms'"></rooms>
    <services *ngSwitchCase="'services'"></services>
    <div *ngSwitchCase="'users'">
      <p>Felhasználók</p>
      <div class="users" *ngFor="let user of users">
        <p>{{ user.name }} {{ user.role }}</p>
      </div>
    </div>
  </div> -->
  <router-outlet></router-outlet>
</div>
