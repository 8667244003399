import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { RoomMenu } from 'src/app/enums';
import { roomTypeOptions, floorOptions } from 'src/app/@consts';
@Component({
  selector: 'rooms-edit',
  templateUrl: './rooms-edit.component.html',
  styleUrls: ['./rooms-edit.component.scss'],
})
export class RoomsEditComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService
  ) {}

  ngOnInit(): void {
    if (this.hotelService?.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  public form: FormGroup;
  public dynamicThe: string = 'a(z)';

  public readonly roomTypeOptions = roomTypeOptions;
  public readonly floorOptions = floorOptions;

  public floors = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public selectedMenu: string = RoomMenu.Overview;
  public roomMenu = RoomMenu;
  public startDate: Date;
  public endDate: Date;

  public deselectRoom(): void {
    this.roomService.selectedRoom = null;
  }

  public editRoom(): void {
    this.roomService.selectedRoom.floor = Number(
      this.roomService.selectedRoom.floor
    );

    const roomId = this.roomService.selectedRoom.room_id;
    delete this.roomService.selectedRoom.room_id;
    if (!this.roomService.selectedRoom.default_price_huf) {
      delete this.roomService.selectedRoom.default_price_huf;
    }
    if (!this.roomService.selectedRoom.default_price_eur) {
      delete this.roomService.selectedRoom.default_price_eur;
    }
    this.roomService
      .editRoom(this.roomService.selectedRoom, roomId.toString())
      .then(() => {
        this.roomService.selectedRoom = {
          room_id: null,
          name: null,
          type: null,
          floor: null,
          building: null,
          single_beds: null,
          double_beds: null,
          extra_beds: null,
          couch_beds: null,
          max_adults: null,
          max_family_adults: null,
          max_family_children: null,
          air_conditioning: false,
          wheelchair_accessible: false,
          default_price_huf: null,
          default_price_eur: null,
          room_number: null,
          ifa_included: null,
        };
        this.deselectRoom();
      })
      .catch(() => {});
  }
}
