import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FocusService {
  public selectedMenu: string;
  public selectedSubMenu: string;

  public focusButton(event: any): void {
    event.srcElement.parentElement.classList.add('focused');
  }

  public focusOutButton(event: any): void {
    if (!event.srcElement.value) {
      event.srcElement.parentElement.classList.remove('focused');
    }
  }
}
