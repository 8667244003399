<div class="login-wrapper">
  <div class="content-wrapper">
    <h1 (click)="openServerSelector()">kis portás</h1>
    <div class="form-wrapper">
      <form (submit)="signIn()" #loginForm="ngForm">
        <text-input
          [type]="'email'"
          id="username"
          name="username"
          label="Felhasználónév"
          [required]="true"
          [(ngModel)]="loginGroup.username"
        ></text-input>

        <br />
        <text-input
          [type]="'password'"
          id="password"
          name="password"
          [required]="true"
          [(ngModel)]="loginGroup.password"
          label="Jelszó"
        ></text-input>

        <br />
        <checkbox-input
          type="checkbox"
          [(ngModel)]="rememberMe"
          name="rememberMe"
          label="Bejelentkezve maradok"
        ></checkbox-input>

        <br />
        <button
          class="primary-button"
          type="submit"
          [disabled]="loginForm.invalid"
        >
          <span *ngIf="!isLoading">Belépés</span>
          <app-loading-spinner
            [loading]="isLoading"
            *ngIf="isLoading"
          ></app-loading-spinner>
        </button>
      </form>
    </div>
    <div class="guest-checkin">
      <a *ngIf="isMobile" (click)="openCheckInCodeDialog()">Vendég check-in</a>
    </div>
  </div>
  <div class="background"></div>
</div>
