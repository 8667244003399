<div class="admin-hotel-wrapper">
  <div class="main-content">
    <h1>Hotel</h1>
    <p *ngIf="hotelService.selectedHotel">
      Itt érheti el, és módosíthatja {{ dynamicThe }}
      {{ hotelService.selectedHotel.name }} adatait
    </p>
    <form #hotelAdminForm="ngForm">
      <ng-container>
        <text-input
          [type]="'text'"
          id="name"
          name="name"
          [required]="true"
          [(ngModel)]="name"
          [label]="'Szálláshely neve'"
        />
        <checkbox-input
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="hotelService.isAutoConclude"
          (userInput)="autoConcludeChanged($event)"
          name="auto_conclude"
          [label]="'Automata napi zárás'"
        />
        <fieldset class="communication-languages">
          <legend>Kommunikációs nyelvek</legend>
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="EN"
            [label]="'angol'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="FR"
            [label]="'francia'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="CN"
            [label]="'kínai'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="PL"
            [label]="'lengyel'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="HU"
            [label]="'magyar'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="DE"
            [label]="'német'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="RU"
            [label]="'orosz'"
          />
          <checkbox-input
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [(ngModel)]="hotelService.isAutoConclude"
            (userInput)="autoConcludeChanged($event)"
            name="ES"
            [label]="'spanyol'"
          />
        </fieldset>
      </ng-container>
    </form>
  </div>
</div>
