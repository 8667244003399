import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  BookingService,
  CalendarService,
  CheckInService,
  DateService,
  FocusService,
  GuestService,
  RoomService,
  RouterService,
} from 'src/app/@services';
import { ArrivalCardComponent } from '../../@cards/arrival-card/arrival-card.component';
import { CheckInRoom, GuestInterface } from 'src/app/@Interfaces';

@Component({
  selector: 'self-check-in',
  templateUrl: './self-check-in.component.html',
  styleUrls: ['./self-check-in.component.scss'],
})
export class SelfCheckInComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private guestService: GuestService,
    private dateService: DateService,
    public checkInService: CheckInService,
    private routerService: RouterService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren('guestForms') guestForms: QueryList<ArrivalCardComponent>;
  public _isEditing: boolean;
  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public roomsToDepart: number[] = [];
  public selectedLanguage: string;
  public selectedRoom: CheckInRoom;
  protected rooms: CheckInRoom[] = [];
  protected isScannerOpen: boolean = false;

  public guests: GuestInterface[] = [];
  public areFormsValid: boolean;

  ngOnInit(): void {
    if (!this.checkInService.checkInId) {
      this.routerService.absoluteNavigation('hu/login');
      return;
    }
    this.rooms = this.checkInService.checkInDetail.rooms;
    console.log('guests ', this.checkInService.checkInDetail.guests);

    this.selectRoom(this.rooms[0]);
  }

  public addSingleGuest(guest: GuestInterface): void {
    this.checkInService
      .saveCheckInGuest(
        this.checkInService.checkInId,
        guest,
        this.selectedRoom.room_id
      )
      .then(() => {
        guest.guest_id = 'arrived';
        this.checkInService.getCheckInDetails(this.checkInService.checkInId);
      });
  }

  public checkValidations(): void {
    this.areFormsValid = this.guestForms.toArray().every((guestForm) => {
      return guestForm.guestForm.valid;
    });
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public selectRoom(room: CheckInRoom): void {
    this.selectedRoom = room;

    this.createCards();
    this.fillCards(this.checkInService.checkInDetail.guests);
  }

  protected openScanner(): void {
    this.isScannerOpen = true;
  }

  protected closeScanner(): void {
    this.isScannerOpen = false;
    this.checkInService
      .getCheckInDetails(this.checkInService.checkInId)
      .then(() => {
        this.selectRoom(this.rooms[0]);
      });
  }

  private createCards(): void {
    const neededGuests = this.selectedRoom.adults + this.selectedRoom.children;

    this.guests = [];

    for (let i = 0; i < neededGuests; i++) {
      this.guests.push({
        guest_id: null,
        birth_place: null,
        birth_date: null,
        tax_exempt: false,
        tax_exemption_reason: null,
        room_id: this.selectedRoom.room_id,
        gender: null,
        country_of_residence: null,
        postcode_of_residence: null,
        nationality: null,
        birth_first_name: null,
        birth_last_name: null,
        mothers_first_name: null,
        mothers_last_name: null,
        visa_arrival_date: null,
        visa_arrival_place: null,
        visa_number: null,
        guest_arrival_id: null,
      });
    }
  }

  private fillCards(guests: GuestInterface[]): void {
    let i = 0;
    let otherGuests: number = 0;
    guests.forEach((guest) => {
      if (guest.room_id === this.selectedRoom.room_id) {
        if (guest.birth_date)
          guest.tax_exempt = !this.dateService.isOver18(guest.birth_date);
        this.guests[i] = guest;
        i++;
      } else {
        otherGuests++;
      }
    });
    this.guestService.totalGuestsToScan =
      this.guests.length - (guests.length - otherGuests);
  }

  ngOnDestroy(): void {
    this.roomsToDepart = [];
  }
}
