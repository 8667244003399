import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'successful',
  templateUrl: 'successful.snackbar.html',
  styleUrls: ['successful.snackbar.scss'],
})
export class SuccessfulSnackBar {
  constructor(public snackBarRef: MatSnackBarRef<SuccessfulSnackBar>) {}

  public closeSnackBar(): void {
    this.snackBarRef.dismiss();
  }
}
