<div class="service-add-wrapper">
  <div class="header">
    <h1>Hozzáadás</h1>
  </div>
  <div class="form-wrapper">
    <form #serviceForm="ngForm">
      <text-input
        [type]="'text'"
        id="name"
        name="name"
        [required]="true"
        [(ngModel)]="service.name"
        [label]="'Szolgáltatás neve*'"
      />
      <text-input
        [type]="'number'"
        id="price_huf"
        name="price_huf"
        [required]="true"
        [(ngModel)]="service.price_huf"
        [label]="'Bruttó ár HUF*'"
      />
      <select-input
        id="category"
        name="category"
        [required]="true"
        [(ngModel)]="service.category"
        [label]="'Kategória*'"
        [options]="serviceCategoryOptions"
      />
      <select-input
        id="vat_rate"
        name="vat_rate"
        [required]="true"
        [(ngModel)]="service.vat_rate"
        [label]="'ÁFA*'"
        [options]="vatRateOptions"
      />
    </form>
  </div>
  <div class="button-wrapper">
    <button class="primary-button" type="submit" (click)="newService()">
      + Hozzáadás
    </button>
  </div>
</div>
