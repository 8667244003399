<div class="booking-rooms-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-header clickable">
    <button class="secondary-button" (click)="addRoom()">+ Szoba</button>
  </div>
  <div class="action-wrapper clickable">
    <div class="room-panel-wrapper">
      <booking-room-panel
        *ngFor="let room of bookingService.sidePanelRooms | keyvalue"
        [room]="room"
      ></booking-room-panel>
    </div>
  </div>

  <div class="action-footer clickable">
    <button class="secondary-button" (click)="closeBooking()">Mégse</button>
    <button
      class="primary-button"
      (click)="postBooking($event)"
      [disabled]="!tableService.validateBookings()"
    >
      <span *ngIf="!isLoading">Foglalás rögzítése</span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
