import { Component } from '@angular/core';
import { AuthService } from 'src/app/@services';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  constructor(private authService: AuthService) {}

  public signOut(): void {
    this.authService.signOut();
  }
}
