import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  constructor() {}

  public stopVideoStream(
    video: ElementRef<HTMLVideoElement>,
    stream: MediaStream
  ): void {
    video.nativeElement.pause();
    video.nativeElement.srcObject = null;
    video.nativeElement.src = null;

    if (!stream) return;

    stream.getTracks().forEach((track: MediaStreamTrack) => {
      track.stop();
      stream.removeTrack(track);
    });
    return;
  }

  public async getVideoDevices(
    video: ElementRef<HTMLVideoElement>,
    stream: MediaStream
  ): Promise<MediaDeviceInfo[]> {
    this.stopVideoStream(video, stream);
    return new Promise<MediaDeviceInfo[]>((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((newStream) => {
          this.stopVideoStream(video, newStream);
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices: MediaDeviceInfo[]) => {
              const videoDevices: MediaDeviceInfo[] = [];

              devices.forEach((device: MediaDeviceInfo) => {
                if (
                  device.kind === 'videoinput' &&
                  device.label.split(' ').includes('back')
                )
                  videoDevices.push(device);
              });
              console.log('devices ', videoDevices);

              resolve(videoDevices);
            })
            .catch((error) => {
              console.log('ERROR, get video devices', error);
              reject();
            });
        })
        .catch((error) => {
          console.log('ERROR, get user media', error);
          reject(error);
        });
    });
  }
}
