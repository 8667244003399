<div
  class="calendar-wrapper"
  (click)="closeSidePanel()"
  [ngClass]="{ 'active-footer': routerService.subMenu }"
>
  <div
    class="overlay"
    [ngClass]="{
      active: routerService.subMenu === 'booking'
    }"
  ></div>
  <div class="calendar-header">
    <div
      class="headers-wrapper"
      [ngClass]="{ triggered: routerService.subMenu }"
    >
      <div class="default-header">
        <div class="date-wrapper">
          <span (click)="previousWeek()"><</span>
          <date-input
            [(ngModel)]="calendarService.inputStartDate"
            (ngModelChange)="onDateChange()"
          ></date-input>
          <span (click)="nextWeek()">></span>
        </div>
        <button
          class="primary-button"
          (click)="openSidePanel(sidePanelEnum.booking, $event)"
        >
          + Új Foglalás
        </button>

        <button
          class="secondary-button"
          (click)="openCloseDayDialog()"
          *ngIf="hotelService.selectedHotel"
          [disabled]="
            dateService.isDateFuture(
              dateService.templateNewDate(
                hotelService.selectedHotel.last_concluded_day
              )
            )
          "
        >
          <span class="description">Napi zárás</span><br /><span class="date"
            >{{ hotelService.selectedHotel.last_concluded_day }}.</span
          >
        </button>
      </div>
      <div class="status-header">
        <div class="booking-overview" *ngIf="bookingService.selectedBooking">
          <div class="room">
            <i class="room-icon"></i
            ><span>{{
              getTotalBookinData(bookingService.selectedBooking.booking_id)
                .rooms
            }}</span>
          </div>
          <div class="adult">
            <i class="adult-icon"></i
            ><span>{{
              getTotalBookinData(bookingService.selectedBooking.booking_id)
                .adults
            }}</span>
          </div>
          <div class="child">
            <i class="child-icon"></i
            ><span>{{
              getTotalBookinData(bookingService.selectedBooking.booking_id)
                .children
            }}</span>
          </div>
        </div>
        <div class="contact" *ngIf="bookingService.selectedBooking">
          <div>
            <i class="user-icon"></i>
            <span>{{ bookingService.selectedBooking.contact_name }}</span>
          </div>
          <div>
            <i class="email-icon"></i>
            <span *ngIf="bookingService.selectedBooking.contact_email">{{
              bookingService.selectedBooking.contact_email
            }}</span>
            <span *ngIf="!bookingService.selectedBooking.contact_email">
              -</span
            >
          </div>
          <div>
            <i class="phone-icon"></i>
            <span *ngIf="bookingService.selectedBooking.contact_phone">{{
              bookingService.selectedBooking.contact_phone
            }}</span>
            <span *ngIf="!bookingService.selectedBooking.contact_phone">
              -</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <p (click)="getDateList()">test</p> -->
  </div>
  <div class="calendar-main">
    <calendar-table
      [sidePanelType]="sidePanelType"
      *ngIf="calendarService.dateList.length > 0"
      (openEditRooms)="openSidePanel(sidePanelEnum.modifyRooms, $event)"
    ></calendar-table>
  </div>

  <div
    class="slide-in"
    [ngClass]="{
      active: routerService.subMenu,
      'no-footer':
        routerService.subMenu === 'room' || routerService.subMenu === 'booking'
    }"
    [ngStyle]="{
      'pointer-events':
        routerService.subMenu === 'payment' ||
        routerService.subMenu === 'arrival'
          ? 'all'
          : 'none'
    }"
  >
    <router-outlet></router-outlet>
    <!--   

    <booking-rooms-payment
      
      [ngStyle]="{ 'pointer-events': 'all' }"
    ></booking-rooms-payment>

    <booking-arrival
      [ngStyle]="{ 'pointer-events': 'all' }"
    ></booking-arrival> -->
  </div>

  <!--room-panels -->
  <div
    class="footer"
    [ngClass]="{
      active:
        routerService.subMenu &&
        routerService.subMenu !== 'room' &&
        routerService.subMenu !== 'booking'
    }"
    (click)="$event.stopPropagation()"
  >
    <div class="button-wrapper" *ngIf="bookingService.selectedBooking">
      <button class="secondary-button" (click)="closeLowerMenu()">
        Vissza
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.modifyBooking, $event)"
        [ngClass]="{ active: routerService.subMenu === 'modify-booking' }"
      >
        Adatok
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.modifyRooms, $event)"
        [ngClass]="{ active: routerService.subMenu === 'modify-rooms' }"
      >
        Szobák
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.arrival, $event)"
        [ngClass]="{ active: routerService.subMenu === 'arrival' }"
      >
        Érkeztetés
      </button>

      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.payment, $event)"
        [ngClass]="{ active: routerService.subMenu === 'payment' }"
      >
        Fizettetés
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.departure, $event)"
        [ngClass]="{ active: routerService.subMenu === 'departure' }"
      >
        Távoztatás
      </button>
      <button
        [disabled]="
          !bookingService.canBookingBeDeleted(
            bookingService.selectedBooking?.booking_id
          )
        "
        class="delete-secondary-button"
        (click)="deleteBooking($event)"
      >
        Foglalás törlése
      </button>
    </div>
  </div>
</div>
