import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChargeInterface, ServiceInterface } from 'src/app/@Interfaces';
import { BookingService } from 'src/app/@services';

@Component({
  selector: 'service-select',
  templateUrl: './service-select.html',
  styleUrls: ['./service-select.scss'],
})
export class ServiceSelectDialog {
  constructor(
    private matDialogRef: MatDialogRef<ServiceSelectDialog>,
    private bookingService: BookingService,
    @Inject(MAT_DIALOG_DATA) public services: ServiceInterface[]
  ) {}

  protected service: ServiceInterface;
  protected charge: ChargeInterface;

  public chooseService(service: ServiceInterface): void {
    this.charge = {
      booking_id: this.bookingService.selectedBooking.booking_id,
      room_id: this.bookingService.selectedBooking.room_id,
      charge_type: service.category,
      gross_price: service.price_huf,
      qty: 1,
      unit: 'db',
      totalPrice: service.price_huf,
      vat_rate: service.vat_rate,
      payment_date: null,
      payment_method: null,
      paid: false,
      paid_by: null,
      company_payment: 0,
      name: service.name,
      comment: null,
      currency: null,
      created_date: null,
      intermediary: null,
    };
    this.service = service;
  }

  protected closeAndRefresh(): void {
    this.matDialogRef.close(this.service);
  }

  public cancel(): void {
    this.matDialogRef.close();
  }
}
